import { NetworkType } from "../../apollo/graphql.generated";
export const chainIdToNetworkType = (chainId) => {
    switch (chainId) {
        case 1:
            return NetworkType.ETHEREUM;
        case 5:
            return NetworkType.GOERLI;
        case 11155111:
            return NetworkType.SEPOLIA;
        case 137:
            return NetworkType.POLYGON;
        case 80001:
            return NetworkType.MUMBAI;
        case 42161:
            return NetworkType.ARBITRUM;
        case 421613:
            return NetworkType.ARBITRUM_GOERLI;
        case 10:
            return NetworkType.OPTIMISM;
        case 420:
            return NetworkType.OPTIMISM_GOERLI;
        case 8453:
            return NetworkType.BASE;
        case 84531:
            return NetworkType.BASE_GOERLI;
        case 84532:
            return NetworkType.BASE_SEPOLIA;
        case 7777777:
            return NetworkType.ZORA;
        case 999:
            return NetworkType.ZORA_GOERLI;
        case 984122:
            return NetworkType.FORMA;
        case 5000:
            return NetworkType.MANTLE;
        case 534352:
            return NetworkType.SCROLL;
        default:
            return NetworkType.ETHEREUM;
    }
};
