export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptTermsInput = {
  family: Scalars["String"];
  version: Scalars["Int"];
};

export type Account = {
  createdAt: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  evmAddresses: Array<EvmAddress>;
  id: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type AccountSettings = {
  accountId: Scalars["String"];
  avatarUrl?: Maybe<Scalars["String"]>;
  colorScheme?: Maybe<ColorSchemeType>;
  displayAvatar?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  imported?: Maybe<Scalars["Boolean"]>;
  marketingPreferences: MarketingPreferences;
  nftAvatar?: Maybe<NftAvatar>;
  socials: LinkedSocials;
  twitterConnectState?: Maybe<TwitterConnectState>;
  useNftAvatar?: Maybe<Scalars["String"]>;
};

export type AccountSettingsEmailInput = {
  address: Scalars["String"];
  redirectUrl?: Maybe<Scalars["String"]>;
};

export type AccountWallet = {
  address: Scalars["String"];
  ensName?: Maybe<Scalars["String"]>;
};

export type AllowedMethods = {
  allowedMethods: Array<Scalars["String"]>;
};

export type AnalyticsInput = {
  eventDetails: Scalars["String"];
  eventType: EventType;
};

export type Anonymous = {
  flagVariations: UserFlagVariations;
};

/** App Features */
export enum AppFeature {
  TokenGate = "TokenGate",
  TokenMint = "TokenMint",
}

export type ArtistItem = {
  account: PublicAccountSettings;
  mintsCount: Scalars["Int"];
};

export type Attachment = {
  id: Scalars["String"];
  metadata?: Maybe<AttachmentMetadata>;
  type: AttachmentType;
};

export type AttachmentInput = {
  id: Scalars["String"];
  metadata?: Maybe<MediaMetadataInput>;
  type: AttachmentType;
};

export type AttachmentMetadata = MediaMetadata | TokenMetadata;

/** Type of Post Attachment. */
export enum AttachmentType {
  MEDIA = "MEDIA",
  TOKEN = "TOKEN",
}

export type Attribute = {
  key: Scalars["String"];
  value: Array<Scalars["String"]>;
};

export type AttributeInput = {
  key: Scalars["String"];
  value: Array<Scalars["String"]>;
};

export type AttributeSummary = {
  name: Scalars["String"];
  variants: Array<AttributeVariant>;
};

export type AttributeVariant = {
  count: Scalars["Int"];
  value: Scalars["String"];
};

export type Auction = {
  chainId?: Maybe<Scalars["Float"]>;
  collectionId: Scalars["String"];
  createdAt: Scalars["String"];
  currency: Scalars["String"];
  customBufferMinutes?: Maybe<Scalars["Float"]>;
  end: Scalars["String"];
  gateId?: Maybe<Scalars["String"]>;
  history?: Maybe<Array<BidEvent>>;
  id?: Maybe<Scalars["String"]>;
  paused?: Maybe<Scalars["Boolean"]>;
  price: Scalars["String"];
  start: Scalars["String"];
  stats?: Maybe<AuctionStats>;
  tokenId: Scalars["String"];
  userGateAccess?: Maybe<UnlockGateResult>;
};

export type AuctionUserGateAccessArgs = {
  fullResult: Scalars["Boolean"];
};

export type AuctionBid = {
  amount: Scalars["Float"];
  auctionId: Scalars["String"];
  claimer: Scalars["String"];
  id: Scalars["String"];
  recipient: Scalars["String"];
};

export type AuctionBidInput = {
  amount: Scalars["String"];
};

export type AuctionBidOutput = {
  bid: AuctionBid;
  contract: TransactionArgs;
};

export type AuctionStats = {
  currentBid: Scalars["Float"];
  highestBidder: Scalars["String"];
  status: AuctionStatus;
  totalBids: Scalars["Float"];
};

/** Status of the Auction */
export enum AuctionStatus {
  Ended = "Ended",
  Fulfilled = "Fulfilled",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
}

export type AuctionUpdateInput = {
  auctionId: Scalars["String"];
  end?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
};

export type AuctionUpdateOutput = {
  chainId?: Maybe<Scalars["Float"]>;
  collectionId: Scalars["String"];
  contract?: Maybe<TransactionArgs>;
  createdAt: Scalars["String"];
  currency: Scalars["String"];
  customBufferMinutes?: Maybe<Scalars["Float"]>;
  end: Scalars["String"];
  gateId?: Maybe<Scalars["String"]>;
  history?: Maybe<Array<BidEvent>>;
  id?: Maybe<Scalars["String"]>;
  paused?: Maybe<Scalars["Boolean"]>;
  price: Scalars["String"];
  start: Scalars["String"];
  stats?: Maybe<AuctionStats>;
  tokenId: Scalars["String"];
  userGateAccess?: Maybe<UnlockGateResult>;
};

export type AuctionUpdateOutputUserGateAccessArgs = {
  fullResult: Scalars["Boolean"];
};

export type Audience = {
  downloadLink: Scalars["String"];
  members: Array<AudienceMember>;
  totalCount: Scalars["String"];
};

export type AudienceByCollection = {
  members: Array<AudienceMemberByCollection>;
  totalCount: Scalars["Float"];
};

export type AudienceCollection = {
  chainId: Scalars["Float"];
  collection: AudienceTokenCollection;
  contractAddress: Scalars["String"];
  networkType: NetworkType;
  tokens: Array<AudienceCollectionToken>;
};

export type AudienceCollectionToken = {
  balance: Scalars["Float"];
  imageUrl: Scalars["String"];
  name: Scalars["String"];
  tokenId?: Maybe<Scalars["Float"]>;
};

export type AudienceCollectionV2 = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  count: Scalars["Float"];
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  tokenId: Scalars["Float"];
};

export type AudienceMember = {
  collections: Array<AudienceCollection>;
  ensName?: Maybe<Scalars["String"]>;
  highlightAccountId?: Maybe<Scalars["String"]>;
  socials?: Maybe<PublicLinkedSocials>;
  walletAddress: Scalars["String"];
};

export type AudienceMemberByCollection = {
  ensName?: Maybe<Scalars["String"]>;
  highlightAccountId?: Maybe<Scalars["String"]>;
  socials?: Maybe<PublicLinkedSocials>;
  tokensCount: Scalars["Float"];
  walletAddress: Scalars["String"];
};

export type AudienceMemberTokenV2 = {
  address: Scalars["String"];
  balance: Scalars["Float"];
  chainId: Scalars["Float"];
  imageUrl?: Maybe<Scalars["String"]>;
  imported?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  standard?: Maybe<Scalars["String"]>;
  tokenId: Scalars["Float"];
};

export type AudienceMemberTokensV2 = {
  ensName?: Maybe<Scalars["String"]>;
  highlightAccountId?: Maybe<Scalars["String"]>;
  limit: Scalars["Float"];
  owner: Scalars["String"];
  page: Scalars["Float"];
  tokens: Array<AudienceMemberTokenV2>;
  totalCount: Scalars["Float"];
};

export type AudienceMemberV2 = {
  collections: Array<AudienceCollectionV2>;
  ensName?: Maybe<Scalars["String"]>;
  highlightAccountId?: Maybe<Scalars["String"]>;
  owner: Scalars["String"];
  socials?: Maybe<PublicLinkedSocials>;
};

export type AudienceTokenCollection = {
  id: Scalars["String"];
  name: Scalars["String"];
  symbol: Scalars["String"];
  type: CollectionContractType;
};

export type AudienceV2 = {
  limit: Scalars["Float"];
  members: Array<AudienceMemberV2>;
  page: Scalars["Float"];
  totalCount: Scalars["Float"];
};

export type Auth = {
  jwt: Scalars["String"];
  user: User;
};

export type AvatarCollection = {
  generativeDetails?: Maybe<AvatarCollectionDetails>;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type AvatarCollectionDetails = {
  logoUrl?: Maybe<Scalars["String"]>;
};

/** Theme background type */
export enum BackgroundType {
  Gradient = "Gradient",
  Solid = "Solid",
}

export type BasePostReaction = {
  count?: Maybe<Scalars["Float"]>;
  reaction?: Maybe<Scalars["String"]>;
  userHasReacted?: Maybe<Scalars["Boolean"]>;
};

export type Benefit = {
  /** If the User has access or not to that benefit */
  hasAccess: Scalars["Boolean"];
  /** If the Community has enabled a given benefit */
  hasBenefit: Scalars["Boolean"];
  /** Tokens that were minted for the Community and can unlock this benefit */
  unlockableBy?: Maybe<Array<Token>>;
  /** Tokens that the User owns, and are currently unlocking this benefit */
  unlockedBy?: Maybe<Array<UserToken>>;
};

/** Type of Benefit for the Token. */
export enum BenefitType {
  CONTENT_FEED = "CONTENT_FEED",
  CUSTOM = "CUSTOM",
  DISCORD = "DISCORD",
  RELEASE_PARTY = "RELEASE_PARTY",
  SHOPIFY = "SHOPIFY",
}

export type BidEvent = {
  bidder: Scalars["String"];
  bidderEns?: Maybe<Scalars["String"]>;
  time: Scalars["String"];
  value: Scalars["String"];
};

export type BidderProfile = {
  displayName?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
};

export type BlockchainEntities = {
  collections: Array<Collection>;
  tokens: Array<ImportedToken>;
};

export type BlockchainTaskRef = {
  taskId: Scalars["String"];
};

export type BlockchainTaskRefNullable = {
  taskId?: Maybe<Scalars["String"]>;
};

export type BlockchainTaskToken = {
  animationUri?: Maybe<Scalars["String"]>;
  benefits?: Maybe<Array<TokenBenefit>>;
  communityAddress: Scalars["String"];
  /** Number of tokens available for sale or distribution */
  creatorBalance?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
  mintedAsNonTransferable: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  /** Total number of tokens minted */
  supply?: Maybe<Scalars["Float"]>;
  taskId: Scalars["String"];
  tokenId?: Maybe<Scalars["ID"]>;
  tokenStatus: TokenStatus;
  tokenType: TokenType;
  uri?: Maybe<Scalars["String"]>;
};

/** Border type options for the community theme */
export enum BorderType {
  Pill = "Pill",
  Rounded = "Rounded",
  Straight = "Straight",
}

export type BurnRedeemCustomProjectData = {
  burn1155Address: Scalars["String"];
  burnTokenId: Scalars["Float"];
  crosschain?: Maybe<CrosschainBurnRedeemConfig>;
  mechanicAddress: Scalars["String"];
  numToBurnPerToken: Scalars["Float"];
};

export type BuyButton = {
  id: Scalars["String"];
  script: Scalars["String"];
};

/** Type of Marketplace to Buy on */
export enum BuyMarketplace {
  OpenSea = "OpenSea",
}

export type CaptureGenerativeSeriesPreviewOutput = {
  statusUrl: Scalars["String"];
  taskId: Scalars["String"];
};

/** Trigger Type for CapturePreview */
export enum CapturePreviewTriggerType {
  Delay = "Delay",
  Function = "Function",
}

export type Category = {
  collectionTypes: Array<_CollectionType>;
  id: Scalars["String"];
  name: Scalars["String"];
  selectable: Scalars["Boolean"];
  slug: Scalars["String"];
  type: Scalars["String"];
};

export type Chain = {
  chainId: Scalars["Float"];
  contracts: Array<SystemContract>;
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  mintFee: Scalars["String"];
  name: Scalars["String"];
  network: NetworkType;
  networkUrl: Scalars["String"];
  supportedFeatures: Array<AppFeature>;
  supportedPaymentCurrencies: Array<Currency>;
  testnet: Scalars["Boolean"];
};

export type Challenge = {
  messageToSign: Scalars["String"];
};

export type CheckoutInitiateResponse = {
  level?: Maybe<Scalars["String"]>;
  stripe: CheckoutStripe;
};

export type CheckoutItemsInput = {
  id: Scalars["String"];
};

export type CheckoutStripe = {
  paymentIntent?: Maybe<Scalars["String"]>;
};

export type Claim = {
  address: Scalars["String"];
  claimExpiryTimestamp: Scalars["String"];
  claimer: Scalars["String"];
  currency: Scalars["String"];
  id: Scalars["String"];
  maxClaimablePerUser: Scalars["Float"];
  maxClaimableViaVector: Scalars["Float"];
  maxPerTxn?: Maybe<Scalars["Float"]>;
  numTokensToMint?: Maybe<Scalars["Float"]>;
  paymentRecipient: Scalars["String"];
  pricePerToken: Scalars["String"];
  vectorId: Scalars["String"];
};

export type ClaimObject = {
  claim: Claim;
  contract: TransactionArgs;
  creditCard: CreditCardClaimConfig;
  vector: MintVector;
};

export type ClaimToken = {
  contestId: Scalars["String"];
};

export type ClaimTokenInput = {
  claimableTokens: Scalars["Float"];
  name: Scalars["String"];
};

export type Collection = {
  accountId?: Maybe<Scalars["String"]>;
  address: Scalars["String"];
  archivedAt?: Maybe<Scalars["String"]>;
  auctions: Array<Auction>;
  baseUri?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Scalars["String"]>>;
  chainId: Scalars["Float"];
  collectionImage?: Maybe<Scalars["String"]>;
  collectionType: _CollectionType;
  collectorsChoiceTokens?: Maybe<SeriesTokensPaginatedNew>;
  contract?: Maybe<Contract2>;
  contractId: Scalars["String"];
  contractUri?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  creatorAccountSettings?: Maybe<PublicAccountSettings>;
  creatorAddresses?: Maybe<Array<EvmAddress>>;
  creatorEns?: Maybe<Scalars["String"]>;
  creditCardEnabled?: Maybe<Scalars["Boolean"]>;
  crossmint?: Maybe<CrossmintEnv>;
  description?: Maybe<Scalars["String"]>;
  edition?: Maybe<EditionToken>;
  editionId?: Maybe<Scalars["String"]>;
  editions?: Maybe<Array<EditionToken>>;
  entityId?: Maybe<Scalars["String"]>;
  flagVariations: CollectionFlagVariations;
  genSeriesHashes?: Maybe<GenSeriesHashesPaginated>;
  generativeDetails?: Maybe<GenerativeDetails>;
  hasDetails: Scalars["Boolean"];
  hidden?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  importData?: Maybe<ImportData>;
  logoUrl?: Maybe<Scalars["String"]>;
  marketplaceId?: Maybe<Scalars["String"]>;
  mintVectors: Array<MintVector>;
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  onChainBaseUri?: Maybe<Scalars["String"]>;
  onChainMetadata?: Maybe<Scalars["String"]>;
  onchainId: Scalars["String"];
  openSeaCollectionUrl?: Maybe<Scalars["String"]>;
  reveal?: Maybe<Scalars["Boolean"]>;
  revealClaimedIds?: Maybe<Array<Scalars["Float"]>>;
  royalty?: Maybe<V1Royalty>;
  seriesAttributes: Array<AttributeSummary>;
  seriesDetails?: Maybe<SeriesDetails>;
  seriesImages?: Maybe<SeriesImages>;
  seriesToken?: Maybe<SeriesToken>;
  seriesTokenNormalized?: Maybe<SeriesToken>;
  seriesTokens?: Maybe<SeriesTokensPaginated>;
  size?: Maybe<Scalars["Float"]>;
  status: CollectionStatus;
  supply?: Maybe<Scalars["Float"]>;
  symbol?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  tokens?: Maybe<Array<GeneralToken>>;
  tokensNormalized?: Maybe<Array<SeriesToken>>;
  type: CollectionContractType;
  userCategories?: Maybe<Array<Category>>;
  userTags?: Maybe<Array<Tag>>;
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

export type CollectionCollectorsChoiceTokensArgs = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<MarketplaceCollectionAttributesFilter>>;
  first?: Maybe<Scalars["Float"]>;
  minted?: Maybe<Scalars["Boolean"]>;
  sortBy?: Maybe<TokenSortableField>;
  sortDirection?: Maybe<SortDirection>;
  tokenNameOrId?: Maybe<Scalars["String"]>;
};

export type CollectionCreatorAccountSettingsArgs = {
  withEns?: Maybe<Scalars["Boolean"]>;
};

export type CollectionEditionArgs = {
  withDetails: Scalars["Boolean"];
  withProperties: Scalars["Boolean"];
};

export type CollectionGenSeriesHashesArgs = {
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
};

export type CollectionSeriesAttributesArgs = {
  minted?: Maybe<Scalars["Boolean"]>;
};

export type CollectionSeriesTokenArgs = {
  tokenId: Scalars["String"];
};

export type CollectionSeriesTokenNormalizedArgs = {
  fromS3?: Maybe<Scalars["Boolean"]>;
  tokenId: Scalars["String"];
};

export type CollectionSeriesTokensArgs = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<NftAttribute>>;
  first?: Maybe<Scalars["Float"]>;
  minted?: Maybe<Scalars["Boolean"]>;
};

export type CollectionTokensNormalizedArgs = {
  fromS3?: Maybe<Scalars["Boolean"]>;
  isImportedCollection?: Maybe<Scalars["Boolean"]>;
  tokenIds: Array<Scalars["String"]>;
};

export type CollectionAttributes = {
  attributes: Array<Attribute>;
  slug: Scalars["String"];
};

export type CollectionBuyButton = {
  collectionComponentDivId: Scalars["String"];
  collectionId: Scalars["String"];
  domain: Scalars["String"];
  storefrontAccessToken: Scalars["String"];
};

export type CollectionByAddress = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CollectionByOnChainId = {
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CollectionCombined = {
  address: Scalars["String"];
  chainId: Scalars["Int"];
  collectionImage?: Maybe<Scalars["String"]>;
  collectionType: _CollectionType;
  creatorAccountSettings?: Maybe<PublicAccountSettings>;
  creatorAddress: EvmAddress;
  currency?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isPrimarySaleActive?: Maybe<Scalars["Boolean"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  onchainId: Scalars["String"];
  paymentCurrency?: Maybe<Currency>;
  price?: Maybe<Scalars["String"]>;
  priceType?: Maybe<PriceType>;
  startDate?: Maybe<Scalars["String"]>;
};

export type CollectionCombinedCreatorAccountSettingsArgs = {
  withEns?: Maybe<Scalars["Boolean"]>;
};

export type CollectionCombinedCreatorAddressArgs = {
  withEns?: Maybe<Scalars["Boolean"]>;
};

/** Contract type to deploy */
export enum CollectionContractType {
  Editions = "Editions",
  EditionsSingle = "EditionsSingle",
  General = "General",
  Imported = "Imported",
}

export type CollectionDeleteResult = {
  deletedCount: Scalars["Int"];
};

export type CollectionDetails = {
  chain?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  slug: Scalars["String"];
  standard?: Maybe<CollectionStandardType>;
};

export type CollectionDetailsDraft = {
  categories?: Maybe<Array<Scalars["String"]>>;
  description?: Maybe<Scalars["String"]>;
  generativeDetails?: Maybe<GenerativeDetailsDraft>;
  name?: Maybe<Scalars["String"]>;
  seriesDetails?: Maybe<SeriesDetailsDraft>;
  size?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<TagDraft>>;
};

export type CollectionDetailsDraftInput = {
  categories?: Maybe<Array<Scalars["String"]>>;
  description?: Maybe<Scalars["String"]>;
  generativeDetails?: Maybe<GenerativeDetailsInput>;
  name?: Maybe<Scalars["String"]>;
  seriesDetails?: Maybe<SeriesDetailsInput>;
  size?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<TagDraftInput>>;
};

export type CollectionDraft = {
  accountId: Scalars["String"];
  archivedAt?: Maybe<Scalars["String"]>;
  collectionDraft?: Maybe<CollectionDetailsDraft>;
  collectionType: _CollectionType;
  contractDraft?: Maybe<ContractDraft>;
  contractId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  distributionDraft?: Maybe<DistributionDraft>;
  distributionVectorsDraft?: Maybe<Array<DistributionVectorDraft>>;
  furthestStep: DraftProgress;
  gateId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  metadata?: Maybe<CollectionMetadata>;
  moduleSettings: ModuleSettingsDraft;
  seriesTokens?: Maybe<SeriesTokensPaginated>;
  updatedAt: Scalars["String"];
  version?: Maybe<Scalars["Float"]>;
};

export type CollectionDraftSeriesTokensArgs = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<NftAttribute>>;
  first?: Maybe<Scalars["Float"]>;
  minted?: Maybe<Scalars["Boolean"]>;
};

export type CollectionFilters = {
  blockchains?: Maybe<Array<SupportedMainnets>>;
  collectionTypes?: Maybe<Array<_CollectionType>>;
  contractId?: Maybe<Scalars["String"]>;
  status?: Maybe<CollectionStatusFilter>;
  testnet?: Maybe<Scalars["Boolean"]>;
};

export type CollectionFlagVariations = {
  allowIframeSameOrigin: Scalars["Boolean"];
  apolloTabbedDetails: Scalars["Boolean"];
  enableCrossChainMinting: Scalars["Boolean"];
  enableIframeRestrictiveFlags: Scalars["Boolean"];
  enableMintGenSeriesControls: Scalars["Boolean"];
  externalMintUrl: Scalars["String"];
  increasedSidebarWidth: Scalars["Boolean"];
  mintPageLimitMintsPerTransaction: Scalars["Float"];
  multipleMintVectorsEnableManagement: Scalars["Boolean"];
  multipleMintVectorsEnableMintPage: Scalars["Boolean"];
  multipleMintVectorsEnableMultipleDA: Scalars["Boolean"];
  sequentialDistributionSeries: Scalars["Boolean"];
  shloms404UI: Scalars["Boolean"];
  strabismusUI: Scalars["Boolean"];
  upscaleMintPageImage: Scalars["Boolean"];
};

export type CollectionInfo = {
  address: Scalars["String"];
  chainId: Scalars["Int"];
  size?: Maybe<Scalars["Int"]>;
  standard: Scalars["String"];
  tokenId?: Maybe<Scalars["String"]>;
};

export type CollectionInfoInput = {
  address: Scalars["String"];
  chainId: Scalars["Int"];
  size?: Maybe<Scalars["Int"]>;
  standard: Scalars["String"];
  tokenId?: Maybe<Scalars["String"]>;
};

export type CollectionMarketplaceStats = {
  chainId: Scalars["Float"];
  collectionMarketplaceId: Scalars["String"];
  contractAddress: Scalars["String"];
  floorListing?: Maybe<MarketplaceListing>;
  listedCount: Scalars["Float"];
};

export type CollectionMarketplaceStatsV2 = {
  collectionMarketplaceId: Scalars["String"];
  floorListing?: Maybe<MarketplaceListing>;
  listedCount: Scalars["Float"];
};

export type CollectionMetadata = {
  coverMediaUrl?: Maybe<Scalars["String"]>;
  generateNameTrait?: Maybe<Scalars["Boolean"]>;
  mediaFiles?: Maybe<Array<Scalars["String"]>>;
  mediaTypeStr?: Maybe<Scalars["String"]>;
  mediaUrl?: Maybe<Scalars["String"]>;
  reveal?: Maybe<Scalars["Boolean"]>;
  seriesDetails?: Maybe<SeriesDetailsDraft>;
  seriesS3Unzipped?: Maybe<Scalars["Boolean"]>;
  traits?: Maybe<Array<TraitAttribute>>;
};

export type CollectionMetadataInput = {
  coverMediaUrl?: Maybe<Scalars["String"]>;
  generateNameTrait?: Maybe<Scalars["Boolean"]>;
  mediaFiles?: Maybe<Array<Scalars["String"]>>;
  mediaTypeStr?: Maybe<Scalars["String"]>;
  mediaUrl?: Maybe<Scalars["String"]>;
  reveal?: Maybe<Scalars["Boolean"]>;
  seriesDetails?: Maybe<SeriesDetailsInput>;
  seriesS3Unzipped?: Maybe<Scalars["Boolean"]>;
  traits?: Maybe<Array<TraitAttributeInput>>;
};

export type CollectionMetadataUpdate = {
  animationUrl?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<TokenMetadataAttributeInput>>;
  description?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  seriesDiffUrl?: Maybe<Scalars["String"]>;
};

export type CollectionNft = {
  balance: Scalars["String"];
  contract: Scalars["String"];
  id: Scalars["String"];
  imageOriginalUrl?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  standard?: Maybe<NftStandardType>;
};

export type CollectionNftsPaginated = {
  nfts: Array<CollectionNft>;
  pageInfo: CursorPaginatedInfo;
};

/** Sorting direction of the data */
export enum CollectionSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/** Sortable fields of a collection */
export enum CollectionSortableField {
  ACTIVE = "ACTIVE",
  CREATED_DATE = "CREATED_DATE",
  NAME = "NAME",
}

/** Collection Standard Type */
export enum CollectionStandardType {
  OPENSEA = "OPENSEA",
}

/** Status of the collection's deployment */
export enum CollectionStatus {
  DRAFT = "DRAFT",
  IN_PROGRESS = "IN_PROGRESS",
  LIVE = "LIVE",
}

/** Options for filtering collections by status. */
export enum CollectionStatusFilter {
  ARCHIVED = "ARCHIVED",
  DEPLOYED = "DEPLOYED",
  DRAFT = "DRAFT",
  INACTIVE = "INACTIVE",
  LIVE = "LIVE",
}

export type CollectionUpdateInput = {
  creditCardEnabled?: Maybe<Scalars["Boolean"]>;
  crossmint?: Maybe<CrossmintEnvInput>;
  description?: Maybe<Scalars["String"]>;
  featuredTokenId?: Maybe<Scalars["String"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  seriesDetails?: Maybe<SeriesDetailsUpdateInput>;
};

export type CollectionWithDraft = MergedCollection | MergedDraft;

export type CollectionsDashboard = {
  chainId: Scalars["Float"];
  collectionAddress: Scalars["String"];
  collectionId: Scalars["String"];
  editionId: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  logo: Scalars["String"];
  name: Scalars["String"];
  startDate: Scalars["String"];
  status: Scalars["String"];
  type: _CollectionType;
};

export type CollectionsDashboardPaginated = {
  collections: Array<CollectionsDashboard>;
  pageInfo: PagePaginatedInfoV3;
};

export type CollectionsPaginated = {
  collections: Array<Collection>;
  pageInfo: CursorPaginatedInfo;
};

/** Color scheme type. */
export enum ColorSchemeType {
  dark = "dark",
  light = "light",
  system = "system",
}

export type Comment = {
  communityId: Scalars["String"];
  entity: EntityId;
  id: Scalars["String"];
  profileImageUrl?: Maybe<Scalars["String"]>;
  reactions?: Maybe<Array<BasePostReaction>>;
  text?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type Community = {
  bannerImageUrl?: Maybe<Scalars["String"]>;
  benefitTokens?: Maybe<Array<Token>>;
  blockchainTaskToken: BlockchainTaskToken;
  claimPages: Array<Contest>;
  contactEmail?: Maybe<Scalars["String"]>;
  contentFeed: ContentFeed;
  contests: Array<Contest>;
  contract: Contract;
  creatorAvatar: CreatorAvatar;
  creatorId: Scalars["String"];
  creatorStripeBalance?: Maybe<StripeBalance>;
  creatorWethBalance?: Maybe<CryptoCurrency>;
  description?: Maybe<Scalars["String"]>;
  discord: DiscordData;
  discordServer?: Maybe<DiscordServer>;
  discordStatistics?: Maybe<DiscordStatistics>;
  flagVariations: CommunityFlagVariations;
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  /** @deprecated The field 'tokens' should be used instead, as now multiple membership tokens are supported.  */
  membershipToken?: Maybe<Token>;
  membershipTokens?: Maybe<Array<Token>>;
  name: Scalars["String"];
  offers: Array<Offer>;
  owners?: Maybe<Array<Scalars["String"]>>;
  polygonContract?: Maybe<Contract>;
  royalties: Array<Royalty>;
  royaltySplitAddress?: Maybe<Scalars["String"]>;
  secondaryEarnings: Array<CryptoCurrency>;
  shop: ShopifyData;
  slug: Scalars["String"];
  status?: Maybe<CommunityStatus>;
  token: Token;
  tokens: Array<Token>;
  websiteTheme: WebsiteTheme;
};

export type CommunityBlockchainTaskTokenArgs = {
  taskId: Scalars["String"];
};

export type CommunityClaimPagesArgs = {
  tokenId: Scalars["Int"];
};

export type CommunityContestsArgs = {
  active?: Maybe<Scalars["Boolean"]>;
};

export type CommunityMembershipTokenArgs = {
  tokenId: Scalars["Int"];
  useChain: Scalars["Boolean"];
};

export type CommunityOffersArgs = {
  active?: Maybe<Scalars["Boolean"]>;
  onAboutPage?: Maybe<Scalars["Boolean"]>;
};

export type CommunityTokenArgs = {
  tokenId: Scalars["Int"];
  useChain?: Maybe<Scalars["Boolean"]>;
};

export type CommunityTokensArgs = {
  type: Scalars["String"];
  useChain?: Maybe<Scalars["Boolean"]>;
};

export type CommunityFlagVariations = {
  hideMembershipTokenReferences: Scalars["Boolean"];
};

export type CommunityIdentifier = {
  creatorAvatar: CreatorAvatar;
  id: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type CommunityNotification = {
  communityId: Scalars["String"];
  status: NotificationStatus;
  type: CommunityNotificationType;
};

/** Community Notification Type */
export enum CommunityNotificationType {
  CONTRACT = "CONTRACT",
}

/** The status of the community. */
export enum CommunityStatus {
  FROZEN = "FROZEN",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING_FIX = "PENDING_FIX",
  PREVIEW = "PREVIEW",
  PUBLIC = "PUBLIC",
}

export type Condition = {
  amount?: Maybe<Scalars["Float"]>;
  autoBuyButton?: Maybe<BuyMarketplace>;
  buyButtonUrl?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  data?: Maybe<GenericConditionData>;
  followFID?: Maybe<Scalars["String"]>;
  required?: Maybe<Scalars["Boolean"]>;
  selector?: Maybe<Scalars["String"]>;
  type?: Maybe<ConditionType>;
};

export type ConditionInput = {
  amount?: Maybe<Scalars["Float"]>;
  autoBuyButton?: Maybe<BuyMarketplace>;
  buyButtonUrl?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  data?: Maybe<GenericConditionDataInput>;
  followFID?: Maybe<Scalars["String"]>;
  required?: Maybe<Scalars["Boolean"]>;
  selector?: Maybe<Scalars["String"]>;
  type?: Maybe<ConditionType>;
};

export type ConditionResult = {
  amount?: Maybe<Scalars["Float"]>;
  autoBuyButton?: Maybe<BuyMarketplace>;
  buyButtonUrl?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  data?: Maybe<GenericConditionData>;
  failureReason?: Maybe<FailureReason>;
  followFID?: Maybe<Scalars["String"]>;
  passed: Scalars["Boolean"];
  required?: Maybe<Scalars["Boolean"]>;
  selector?: Maybe<Scalars["String"]>;
  type?: Maybe<ConditionType>;
  userAmount?: Maybe<Scalars["Float"]>;
};

/** Gate Condition Types */
export enum ConditionType {
  ALLOWLIST = "ALLOWLIST",
  ATTRIBUTE = "ATTRIBUTE",
  COLLECTION = "COLLECTION",
  CURRENCY = "CURRENCY",
  FARCASTER = "FARCASTER",
  MARKETING_ACCESS = "MARKETING_ACCESS",
  NFT = "NFT",
}

/** Type of consumer mint vector type */
export enum ConsumerMintVectorType {
  CUSTOM = "CUSTOM",
  FARCASTER_FRAMES = "FARCASTER_FRAMES",
  HL_GASLESS = "HL_GASLESS",
  VERISART = "VERISART",
}

export type ContentFeed = {
  benefit: Benefit;
  communityId: Scalars["String"];
  creatorAvatar: CreatorAvatar;
  posts?: Maybe<Array<Post>>;
};

export type Contest = {
  active: Scalars["Boolean"];
  autoWin?: Maybe<Scalars["Boolean"]>;
  communityId?: Maybe<Scalars["String"]>;
  contestPage: ContestPage;
  currentUserContestEntry?: Maybe<ContestEntry>;
  endAt?: Maybe<Scalars["String"]>;
  hasUserApplied?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  isPrivate?: Maybe<Scalars["Boolean"]>;
  showQuestion?: Maybe<Scalars["Boolean"]>;
  startAt: Scalars["String"];
  tokenIds: Array<Scalars["Float"]>;
  tokens: Array<Token>;
  tokensMetadata: TokensMetadata;
};

export type ContestEntry = {
  blockchainTransferId?: Maybe<Scalars["String"]>;
  contestId: Scalars["String"];
  decision?: Maybe<Decision>;
  id: Scalars["String"];
  user: ContestEntryUser;
  userSubmission: UserSubmission;
};

export type ContestEntryBody = {
  formFieldAnswer?: Maybe<Scalars["String"]>;
};

export type ContestEntryUser = {
  email: Scalars["String"];
  id: Scalars["String"];
};

export type ContestPage = {
  description: Scalars["String"];
  formFieldLabel?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type Contract = {
  contractAddress?: Maybe<Scalars["String"]>;
  contractType?: Maybe<ContractType>;
};

export type Contract2 = {
  address?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  contractType: ContractEntityType;
  contractUri?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  symbol: Scalars["String"];
};

export type ContractAttributes = {
  attributes: Array<Attribute>;
  contract: Scalars["String"];
};

export type ContractDetails = {
  contract: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  network: Scalars["String"];
  openSeaCollectionUrl?: Maybe<Scalars["String"]>;
  standard?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
};

export type ContractDraft = {
  asciiArtSignature?: Maybe<Scalars["String"]>;
  chainId?: Maybe<Scalars["Float"]>;
  collectionLogoImage?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

export type ContractDraftInput = {
  asciiArtSignature?: Maybe<Scalars["String"]>;
  chainId?: Maybe<Scalars["Float"]>;
  collectionLogoImage?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

/** Contract type - shared enum */
export enum ContractEntityType {
  GenerativeSeries = "GenerativeSeries",
  MultipleEditions = "MultipleEditions",
  MultipleEditionsDFS = "MultipleEditionsDFS",
  Series = "Series",
  SingleEdition = "SingleEdition",
  SingleEditionDFS = "SingleEditionDFS",
}

export type ContractNft = {
  attributes?: Maybe<Array<Attribute>>;
  backgroundColor?: Maybe<Scalars["String"]>;
  balance: Scalars["String"];
  contract: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  externalUrl?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  standard?: Maybe<Scalars["String"]>;
  uri?: Maybe<Scalars["String"]>;
  videoUrl?: Maybe<Scalars["String"]>;
};

export type ContractNftsPaginated = {
  nfts: Array<ContractNft>;
  pageInfo: CursorPaginatedInfo;
};

/** The type of contract. */
export enum ContractType {
  Community = "Community",
  TokenManager = "TokenManager",
}

export type ContractsDashboard = {
  chainId: Scalars["Float"];
  contractAddress: Scalars["String"];
  contractId: Scalars["String"];
  createdAt: Scalars["String"];
  name: Scalars["String"];
  symbol: Scalars["String"];
  type: Scalars["String"];
};

export type ContractsDashboardPaginated = {
  contracts: Array<ContractsDashboard>;
  pageInfo: PagePaginatedInfoV3;
};

/** Theme Corners shape */
export enum CornersShape {
  Rounded = "Rounded",
  Square = "Square",
}

export type CreateCollectionContract = {
  chainId: Scalars["Float"];
  contractAddress: Scalars["String"];
  contractId: Scalars["String"];
  createdAt: Scalars["String"];
  name: Scalars["String"];
  symbol: Scalars["String"];
  type: Scalars["String"];
};

export type CreateCollectionContractsPaginated = {
  contracts: Array<CreateCollectionContract>;
  pageInfo: PagePaginatedInfoV3;
};

export type CreateCollectionDraftInput = {
  collectionType: _CollectionType;
  version?: Maybe<Scalars["Float"]>;
};

export type CreateCollectionDraftOutput = {
  draft: CollectionDraft;
  seriesUploadUrl?: Maybe<Scalars["String"]>;
};

export type CreateCommunityInput = {
  creatorName: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type CreateContractInput = {
  asciiArt?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  description?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  symbol: Scalars["String"];
  type: ContractEntityType;
};

export type CreateEvm721AuctionTransactionInput = {
  auctionId: Scalars["String"];
  collectionId: Scalars["String"];
  crossChainId?: Maybe<Scalars["String"]>;
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
};

export type CreateEvm721BidTransactionInput = {
  auctionId: Scalars["String"];
  bidId: Scalars["String"];
  collectionId: Scalars["String"];
  crossChainId?: Maybe<Scalars["String"]>;
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
  tokenId: Scalars["Int"];
};

export type CreateEvm721MintTransactionInput = {
  claimId: Scalars["String"];
  collectionId: Scalars["String"];
  crossChainId?: Maybe<Scalars["String"]>;
  editionId: Scalars["Int"];
  from: Scalars["String"];
  hash: Scalars["String"];
  mintVectorId: Scalars["String"];
  nftContractAddress: Scalars["String"];
  numTokens: Scalars["Int"];
  relayerId?: Maybe<Scalars["String"]>;
  serializedTx: Scalars["String"];
};

export type CreateEvm721RankedAuctionBidTransactionInput = {
  auctionId: Scalars["String"];
  bidId?: Maybe<Scalars["String"]>;
  collectionId: Scalars["String"];
  crossChainId?: Maybe<Scalars["String"]>;
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
};

export type CreateEvmApproveTransferTransactionInput = {
  collectionId: Scalars["String"];
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
  tokenId?: Maybe<Scalars["String"]>;
};

export type CreateEvmApproveTransferWrappedTransactionInput = {
  from: Scalars["String"];
  hash: Scalars["String"];
  serializedTx: Scalars["String"];
  symbol: Scalars["String"];
};

export type CreateEvmCrosschainBurnTransactionInput = {
  burnId: Scalars["String"];
  collectionId: Scalars["String"];
  from: Scalars["String"];
  hash: Scalars["String"];
  serializedTx: Scalars["String"];
};

export type CreateEvmCurrencyAllowanceTransactionInput = {
  chainId: Scalars["String"];
  currency: Scalars["String"];
  from: Scalars["String"];
  hash: Scalars["String"];
  serializedTx: Scalars["String"];
};

export type CreateEvmDeployTransactionInput = {
  collectionId: Scalars["String"];
  collectionType?: Maybe<Scalars["String"]>;
  contractId: Scalars["String"];
  from: Scalars["String"];
  hash: Scalars["String"];
  serializedTx: Scalars["String"];
  type: Scalars["String"];
};

export type CreateEvmDepositFundsTransactionInput = {
  amount: Scalars["String"];
  from: Scalars["String"];
  hash: Scalars["String"];
  serializedTx: Scalars["String"];
  symbol: Scalars["String"];
};

export type CreateEvmMintCreatorReservesTransactionInput = {
  collectionId: Scalars["String"];
  editionId: Scalars["Int"];
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  numTokens: Scalars["Int"];
  serializedTx: Scalars["String"];
};

export type CreateEvmSaleTransactionInput = {
  collectionId?: Maybe<Scalars["String"]>;
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  reservoirOrderId: Scalars["String"];
  saleKind: EvmTxSaleKind;
  serializedTx: Scalars["String"];
  tokenId: Scalars["String"];
};

export type CreateEvmSetBaseUriTransactionInput = {
  collectionId: Scalars["String"];
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
};

export type CreateEvmSponsorMintTransactionInput = {
  collectionId?: Maybe<Scalars["String"]>;
  from: Scalars["String"];
  hash: Scalars["String"];
  serializedTx: Scalars["String"];
  sponsoredVectorId: Scalars["String"];
};

export type CreateEvmTransferTransactionInput = {
  collectionId: Scalars["String"];
  from: Scalars["String"];
  fromAddress: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
  toAddress: Scalars["String"];
  tokenId: Scalars["String"];
};

export type CreateEvmUpdateCollectionTransactionInput = {
  collectionId: Scalars["String"];
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
  txType: TransactionType;
};

export type CreateEvmUpdateRoyaltyTransactionInput = {
  collectionId: Scalars["String"];
  from: Scalars["String"];
  hash: Scalars["String"];
  nftContractAddress: Scalars["String"];
  serializedTx: Scalars["String"];
};

export type CreateImportedCollectionInput = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  description?: Maybe<Scalars["String"]>;
  mediaUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  standard?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
};

export type CreateImportedCollectionOutput = {
  collection: Collection;
};

export type CreateImportedTokenInput = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  contractUri?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  media?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  standard?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
  type?: Maybe<ImportedTokenType>;
};

export type CreateMintVectorInput = {
  collectionId?: Maybe<Scalars["String"]>;
  collectorMessage?: Maybe<Scalars["String"]>;
  currency: Scalars["String"];
  editionId?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  paymentRecipient?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  priceDropInterval?: Maybe<Array<PriceDropTimePeriodInput>>;
  priceType?: Maybe<PriceType>;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  start: Scalars["String"];
};

export type CreateNewPostInput = {
  attachments?: Maybe<Array<AttachmentInput>>;
  communitySlug: Scalars["String"];
  notifyMembers: Scalars["Boolean"];
  pinned: Scalars["Boolean"];
  text?: Maybe<Scalars["String"]>;
};

export type CreateOfferInput = {
  communityId: Scalars["String"];
  currency: Scalars["String"];
  name: Scalars["String"];
  onAboutPage: Scalars["Boolean"];
  onPrivatePage: Scalars["Boolean"];
  price: Scalars["String"];
  tokenIds: Array<Scalars["Float"]>;
};

export type CreatePortfolioInput = {
  artistName?: Maybe<Scalars["String"]>;
  artistTwitter?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  curator?: Maybe<Scalars["String"]>;
  curatorSlug?: Maybe<Scalars["String"]>;
  ensName?: Maybe<Scalars["String"]>;
  ownerAddress?: Maybe<Scalars["String"]>;
  status: PortfolioStatus;
  title?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  unclaimed?: Maybe<Scalars["Boolean"]>;
};

export type CreatePortfolioItemInput = {
  button?: Maybe<PortfolioItemButtonInput>;
  collectionId?: Maybe<Scalars["String"]>;
  collectionInfo: CollectionInfoInput;
  collectionName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  openSeaLink: Scalars["Boolean"];
  videoUrl?: Maybe<Scalars["String"]>;
};

export type CreatePostInput = {
  attachment?: Maybe<AttachmentInput>;
  communitySlug: Scalars["String"];
  pinned: Scalars["Boolean"];
  text?: Maybe<Scalars["String"]>;
};

export type CreateReleasePartyCommentInput = {
  communityId: Scalars["String"];
  entityId: Scalars["String"];
  text: Scalars["String"];
};

export type CreateTokenInput = {
  animationUrl?: Maybe<Scalars["String"]>;
  baseUri?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  contractUri?: Maybe<Scalars["String"]>;
  currency: Scalars["String"];
  defaultTokenManager: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  distribution: TokenDistributionType;
  end?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  paymentRecipient?: Maybe<Scalars["String"]>;
  price: Scalars["String"];
  royaltyPercentageBPS: Scalars["String"];
  royaltyRecipientAddress: Scalars["String"];
  size?: Maybe<Scalars["Float"]>;
  start: Scalars["String"];
  symbol: Scalars["String"];
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

export type CreateTokenOutput = {
  auction?: Maybe<Auction>;
  collection: Collection;
  contract: TransactionArgs;
  mintVector?: Maybe<MintVector>;
};

export type CreatorAvatar = {
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type CreatorCommunityMember = {
  blockchainAddress?: Maybe<Scalars["String"]>;
  dateJoined?: Maybe<Scalars["Float"]>;
  discordUserName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  tokensHeldNames?: Maybe<Array<Scalars["String"]>>;
};

export type CreatorCommunityMembers = {
  amount: Scalars["Float"];
  currentMonthAmount: Scalars["Float"];
  members: Array<CreatorCommunityMember>;
  resultsCount: Scalars["Float"];
};

/** Sort value for Creator Community Members. */
export enum CreatorCommunityMembersSort {
  BY_DATE = "BY_DATE",
  BY_DATE_REVERSE = "BY_DATE_REVERSE",
  BY_TOKENS_AMOUNT = "BY_TOKENS_AMOUNT",
  BY_TOKENS_AMOUNT_REVERSE = "BY_TOKENS_AMOUNT_REVERSE",
}

export type CreatorDetails = {
  email: Scalars["Boolean"];
  ensName?: Maybe<Scalars["String"]>;
  grantedAccess: Scalars["String"];
  id: Scalars["String"];
  walletAddress: Scalars["String"];
};

export type CreatorReservesInput = {
  numTokensToMint: Scalars["Float"];
  tokenIdsToMint?: Maybe<Array<Scalars["Int"]>>;
};

export type CreatorRewardCollection = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  collectionId: Scalars["String"];
  currency: Scalars["String"];
  editionId: Scalars["String"];
  image: Scalars["String"];
  mintManager?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  paymentCurrency?: Maybe<Currency>;
  total: Scalars["String"];
  vectorId: Scalars["String"];
};

export type CreatorRewardsByCollection = {
  collections: Array<CreatorRewardCollection>;
  pageInfo: CursorPaginatedInfo;
};

export type CreditCardClaimConfig = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  config?: Maybe<Scalars["String"]>;
  method: Scalars["String"];
  value: Scalars["String"];
};

export type CrosschainBurnRedeemConfig = {
  burnChainId: Scalars["Float"];
};

export type CrossmintClaimInput = {
  orderId: Scalars["String"];
  tokenIds: Array<Scalars["Int"]>;
  txId?: Maybe<Scalars["String"]>;
};

export type CrossmintCollectionStatus = {
  status: Scalars["String"];
};

export type CrossmintEnv = {
  collectionId: Scalars["String"];
  environment: Scalars["String"];
  projectId?: Maybe<Scalars["String"]>;
};

export type CrossmintEnvInput = {
  collectionId: Scalars["String"];
  environment: Scalars["String"];
  projectId?: Maybe<Scalars["String"]>;
};

export type CrossmintSellerStatus = {
  status: Scalars["String"];
};

export type CrossmintVerificationStatus = {
  collection: CrossmintCollectionStatus;
  id: Scalars["String"];
  seller: CrossmintSellerStatus;
};

export type CryptoCurrency = {
  coin: Scalars["String"];
  inUSD?: Maybe<Scalars["Float"]>;
  value: Scalars["String"];
};

export type CryptoOrderDraft = {
  amount: Scalars["String"];
  currency: Scalars["String"];
  currencyContract: Scalars["String"];
  offerId: Scalars["String"];
  transferToCreatorData: Scalars["String"];
  transferToPlatformData: Scalars["String"];
};

export type CryptoOrderInput = {
  nonce: Scalars["String"];
  offerId: Scalars["String"];
  purchaseToCreatorMetaTxPacket: MetaTxPacket;
  purchaseToPlatformMetaTxPacket: MetaTxPacket;
  recipientAddress: Scalars["String"];
};

export type Currency = {
  address: Scalars["String"];
  decimals: Scalars["Float"];
  mintFee: Scalars["String"];
  symbol: CurrencySymbol;
  type: CurrencyType;
};

export type CurrencyConversion = {
  value: Scalars["String"];
};

export type CurrencyDetails = {
  contract: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  standard?: Maybe<CurrencyStandardType>;
  symbol?: Maybe<Scalars["String"]>;
};

/** Currency Standard Type */
export enum CurrencyStandardType {
  ERC20 = "ERC20",
  NATIVE = "NATIVE",
}

/** Currency symbol */
export enum CurrencySymbol {
  DEGEN = "DEGEN",
  ENJOY = "ENJOY",
  ETH = "ETH",
  GRND = "GRND",
  HIGHER = "HIGHER",
  MATIC = "MATIC",
  MNT = "MNT",
  TIA = "TIA",
}

/** Currency type */
export enum CurrencyType {
  ERC20 = "ERC20",
  NATIVE = "NATIVE",
}

export type CursorPaginatedInfo = {
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  totalCount: Scalars["Int"];
};

export type CustomPaymentRecipientSettings = {
  ensName?: Maybe<Scalars["String"]>;
  eoa?: Maybe<Scalars["String"]>;
};

export type CustomPaymentRecipientSettingsInput = {
  ensName?: Maybe<Scalars["String"]>;
  eoa?: Maybe<Scalars["String"]>;
};

export type CustomProjectData = {
  burnRedeem?: Maybe<BurnRedeemCustomProjectData>;
};

export type DashboardLink = {
  created: Scalars["Float"];
  object: Scalars["String"];
  url: Scalars["String"];
};

export type Decision = {
  decidedAt: Scalars["String"];
  result: Scalars["Float"];
};

export type DeleteCollectionBuyButtonResult = {
  communityId: Scalars["String"];
  success: Scalars["Boolean"];
};

export type DeployDraftOutput = {
  auction?: Maybe<Auction>;
  collection: Collection;
  contract: Contract2;
  mintVector?: Maybe<MintVector>;
  transactionArgs: TransactionArgs;
};

export type DiscordData = {
  benefit: Benefit;
};

export type DiscordLinkedAccountData = {
  user?: Maybe<DiscordLinkedAccountUserInfo>;
};

export type DiscordLinkedAccountUserInfo = {
  avatar?: Maybe<Scalars["String"]>;
  discriminator?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type DiscordServer = {
  clientId?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["String"]>;
  serverId?: Maybe<Scalars["String"]>;
};

export type DiscordStatistics = {
  activeCount: Scalars["Float"];
  members: Scalars["Float"];
};

export type DistributionDraft = {
  currency?: Maybe<Scalars["String"]>;
  isDirectMint?: Maybe<Scalars["Boolean"]>;
  minBid?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  priceDropInterval?: Maybe<Array<PriceDropTimePeriod>>;
  priceType?: Maybe<PriceType>;
  rebateWinningBids?: Maybe<Scalars["Boolean"]>;
  saleDateFrom?: Maybe<Scalars["String"]>;
  saleDateTo?: Maybe<Scalars["String"]>;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  sponsoredMintsEnabled?: Maybe<Scalars["Boolean"]>;
  type: TokenDistributionType;
};

export type DistributionDraftInput = {
  currency?: Maybe<Scalars["String"]>;
  isDirectMint?: Maybe<Scalars["Boolean"]>;
  minBid?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  priceDropInterval?: Maybe<Array<PriceDropTimePeriodInput>>;
  priceType?: Maybe<PriceType>;
  rebateWinningBids?: Maybe<Scalars["Boolean"]>;
  saleDateFrom?: Maybe<Scalars["String"]>;
  saleDateTo?: Maybe<Scalars["String"]>;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  sponsoredMintsEnabled?: Maybe<Scalars["Boolean"]>;
  type: TokenDistributionType;
};

export type DistributionVectorDraft = {
  currency?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  isDirectMint?: Maybe<Scalars["Boolean"]>;
  limitedMints?: Maybe<LimitedMintsSettings>;
  noteToCollectors?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  priceDropInterval?: Maybe<Array<PriceDropTimePeriod>>;
  saleDateFrom?: Maybe<Scalars["String"]>;
  saleDateTo?: Maybe<Scalars["String"]>;
  saleName?: Maybe<Scalars["String"]>;
  saleSize?: Maybe<Scalars["Float"]>;
  saleType: SaleType;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
};

export type DistributionVectorDraftInput = {
  currency?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  isDirectMint?: Maybe<Scalars["Boolean"]>;
  limitedMints?: Maybe<LimitedMintsSettingsInput>;
  noteToCollectors?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  priceDropInterval?: Maybe<Array<PriceDropTimePeriodInput>>;
  saleDateFrom?: Maybe<Scalars["String"]>;
  saleDateTo?: Maybe<Scalars["String"]>;
  saleName?: Maybe<Scalars["String"]>;
  saleSize?: Maybe<Scalars["Float"]>;
  saleType: SaleType;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
};

export type DraftDeletedOutput = {
  deletedCount: Scalars["Float"];
};

/** How much of the collection draft has been filled out */
export enum DraftProgress {
  ADDED_DETAILS = "ADDED_DETAILS",
  ADDED_DISTRIBUTION = "ADDED_DISTRIBUTION",
  ADDED_GEN_PREVIEW = "ADDED_GEN_PREVIEW",
  ADDED_GEN_SIZE = "ADDED_GEN_SIZE",
  ADDED_METADATA = "ADDED_METADATA",
  AWAITING_SIGNATURE = "AWAITING_SIGNATURE",
  DEPLOY_FAILED = "DEPLOY_FAILED",
  FINISHED = "FINISHED",
  INITIATED_DEPLOY = "INITIATED_DEPLOY",
  SELECTED_TYPE = "SELECTED_TYPE",
  TESTED_GEN_SCRIPT = "TESTED_GEN_SCRIPT",
}

export type DuplicateCollectionInput = {
  duplicateZip: Scalars["Boolean"];
  newName: Scalars["String"];
};

export type Eoa = {
  blockchain?: Maybe<Scalars["String"]>;
  publicAddress: Scalars["String"];
};

export type EditionToken = {
  animation?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<TokenMetadataAttribute>>;
  description: Scalars["String"];
  image: Scalars["String"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  onChainAnimation?: Maybe<Scalars["String"]>;
  onChainImage: Scalars["String"];
  onChainMetadata?: Maybe<Scalars["String"]>;
  remainingSupply?: Maybe<Scalars["Float"]>;
  royalty?: Maybe<V1Royalty>;
  size: Scalars["Float"];
};

export type EditorContent = {
  state: Scalars["String"];
};

export type EditorContentInput = {
  state: Scalars["String"];
};

export type EmailPreference = {
  community: Scalars["Boolean"];
  marketing: Scalars["Boolean"];
};

export type EmitResult = {
  ok: Scalars["Boolean"];
};

export type EntityId = {
  id: Scalars["String"];
  type: EntityType;
};

/** Type of entity or object the associated entity-id uniquely identifies. */
export enum EntityType {
  POST = "POST",
  RELEASE = "RELEASE",
}

/** Event Type */
export enum EventType {
  CompleteSignedMessage = "CompleteSignedMessage",
  ConnectWallet = "ConnectWallet",
  CreateGateEditor_EditContent = "CreateGateEditor_EditContent",
  CreateGateEditor_EditTheme = "CreateGateEditor_EditTheme",
  CreateGateEditor_ImportToken = "CreateGateEditor_ImportToken",
  CreateGateEditor_PublishClick = "CreateGateEditor_PublishClick",
  CreateGateEditor_SetConditions = "CreateGateEditor_SetConditions",
  CreatedGate = "CreatedGate",
  CreatedGate_HasAudioContentType = "CreatedGate_HasAudioContentType",
  CreatedGate_HasEmbedContentType = "CreatedGate_HasEmbedContentType",
  CreatedGate_HasHTMLContentType = "CreatedGate_HasHTMLContentType",
  CreatedGate_HasImageContentType = "CreatedGate_HasImageContentType",
  CreatedGate_HasTextContentType = "CreatedGate_HasTextContentType",
  CreatedGate_HasVideoContentType = "CreatedGate_HasVideoContentType",
  DeployCollection_ArweaveUploadComplete = "DeployCollection_ArweaveUploadComplete",
  DeployCollection_ArweaveUploadFailed = "DeployCollection_ArweaveUploadFailed",
  DeployCollection_CompletedSuccessfully = "DeployCollection_CompletedSuccessfully",
  DeployCollection_Deploy_Button_Clicked = "DeployCollection_Deploy_Button_Clicked",
  DeployCollection_MediaSelectedAudioType = "DeployCollection_MediaSelectedAudioType",
  DeployCollection_MediaSelectedImageType = "DeployCollection_MediaSelectedImageType",
  DeployCollection_MediaSelectedVideoType = "DeployCollection_MediaSelectedVideoType",
  DeployCollection_TransactionSigned = "DeployCollection_TransactionSigned",
  DeployCollection_Viewed = "DeployCollection_Viewed",
  DeployCollection_Web2ObjectsCreatedSuccessfully = "DeployCollection_Web2ObjectsCreatedSuccessfully",
  DeployCollection_Web2ObjectsFailedtoCreate = "DeployCollection_Web2ObjectsFailedtoCreate",
  EditToken_ChangeGateModalOpened = "EditToken_ChangeGateModalOpened",
  EditToken_ChangeGateSaved = "EditToken_ChangeGateSaved",
  EditToken_EditSaleModalOpened = "EditToken_EditSaleModalOpened",
  EditToken_EditSaleSaved = "EditToken_EditSaleSaved",
  EditToken_PauseSale = "EditToken_PauseSale",
  EditToken_UnpauseSale = "EditToken_UnpauseSale",
  EditToken_Viewed = "EditToken_Viewed",
  ExistingGateEditor = "ExistingGateEditor",
  TokenSalePage_MintButtonClicked = "TokenSalePage_MintButtonClicked",
  TokenSalePage_TransactionFailed = "TokenSalePage_TransactionFailed",
  TokenSalePage_TransactionSigned = "TokenSalePage_TransactionSigned",
  TokenSalePage_TransactionSucceeded = "TokenSalePage_TransactionSucceeded",
  TokenSalePage_Viewed = "TokenSalePage_Viewed",
  ViewGatedPage_Locked = "ViewGatedPage_Locked",
  ViewGatedPage_Unlocked = "ViewGatedPage_Unlocked",
}

export type Evm721AuctionMetadata = {
  auctionId?: Maybe<Scalars["String"]>;
  collectionId?: Maybe<Scalars["String"]>;
  nftContractAddress: Scalars["String"];
};

export type Evm721BidMetadata = {
  auctionId?: Maybe<Scalars["String"]>;
  bidId?: Maybe<Scalars["String"]>;
  collectionId?: Maybe<Scalars["String"]>;
  nftContractAddress: Scalars["String"];
  tokenId?: Maybe<Scalars["Int"]>;
};

export type Evm721BidMetadataInput = {
  auctionId?: Maybe<Scalars["String"]>;
  bidId?: Maybe<Scalars["String"]>;
  collectionId?: Maybe<Scalars["String"]>;
  nftContractAddress: Scalars["String"];
  tokenId?: Maybe<Scalars["Int"]>;
};

export type Evm721MintMetadata = {
  claimId?: Maybe<Scalars["String"]>;
  collectionId?: Maybe<Scalars["String"]>;
  editionId?: Maybe<Scalars["Int"]>;
  mintVectorId?: Maybe<Scalars["String"]>;
  mintedTokenIds?: Maybe<Array<Scalars["String"]>>;
  nftContractAddress: Scalars["String"];
  numTokens?: Maybe<Scalars["Int"]>;
};

export type EvmAddress = {
  address: Scalars["String"];
  ensName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nonce: Scalars["Float"];
};

export type EvmApproveTransferMetadata = {
  collectionId: Scalars["String"];
  nftContractAddress: Scalars["String"];
  tokenId?: Maybe<Scalars["String"]>;
};

export type EvmApproveTransferWrappedMetadata = {
  symbol: Scalars["String"];
};

export type EvmCrosschainBurnMetadata = {
  burnId: Scalars["String"];
  collectionId: Scalars["String"];
  redeemRelayerTaskId?: Maybe<Scalars["String"]>;
};

export type EvmCurrencyAllowanceMetadata = {
  chainId: Scalars["String"];
  currency: Scalars["String"];
};

export type EvmDeployCollectionMetadata = {
  collectionId: Scalars["String"];
  collectionType: Scalars["String"];
};

export type EvmDepositFundsMetadata = {
  amount: Scalars["String"];
  symbol: Scalars["String"];
};

export type EvmMintCreatorReservesMetadata = {
  collectionId?: Maybe<Scalars["String"]>;
  editionId?: Maybe<Scalars["Int"]>;
  mintedTokenIds?: Maybe<Array<Scalars["String"]>>;
  nftContractAddress: Scalars["String"];
  numTokens?: Maybe<Scalars["Int"]>;
};

/** Evm type of the onchain id */
export enum EvmOnChainIdType {
  BYTES32 = "BYTES32",
  UINT256 = "UINT256",
}

export type EvmSaleMetadata = {
  collectionId?: Maybe<Scalars["String"]>;
  nftContractAddress: Scalars["String"];
  reservoirOrderId: Scalars["String"];
  saleKind: EvmTxSaleKind;
  tokenId: Scalars["String"];
};

export type EvmSetBaseUriMetadata = {
  collectionId?: Maybe<Scalars["String"]>;
  nftContractAddress: Scalars["String"];
};

export type EvmSponsoredMintMetadata = {
  collectionId?: Maybe<Scalars["String"]>;
  sponsoredVectorId: Scalars["String"];
};

export type EvmTransferMetadata = {
  collectionId?: Maybe<Scalars["String"]>;
  fromAddress: Scalars["String"];
  nftContractAddress: Scalars["String"];
  toAddress: Scalars["String"];
  tokenId: Scalars["String"];
};

export type EvmTx = {
  chainId: Scalars["String"];
  createdAt: Scalars["String"];
  evm721AuctionMetadata?: Maybe<Evm721AuctionMetadata>;
  evm721BidMetadata?: Maybe<Evm721BidMetadata>;
  evm721MintMetadata?: Maybe<Evm721MintMetadata>;
  evmApproveTransferMetadata?: Maybe<EvmApproveTransferMetadata>;
  evmApproveTransferWrappedMetadata?: Maybe<EvmApproveTransferWrappedMetadata>;
  evmCrosschainBurnMetadata?: Maybe<EvmCrosschainBurnMetadata>;
  evmCurrencyAllowanceMetadata?: Maybe<EvmCurrencyAllowanceMetadata>;
  evmDeployCollectionMetadata?: Maybe<EvmDeployCollectionMetadata>;
  evmDepositFundsMetadata?: Maybe<EvmDepositFundsMetadata>;
  evmMintCreatorReservesMetadata?: Maybe<EvmMintCreatorReservesMetadata>;
  evmSaleMetadata?: Maybe<EvmSaleMetadata>;
  evmSetBaseUriMetadata?: Maybe<EvmSetBaseUriMetadata>;
  evmSponsoredMintMetadata?: Maybe<EvmSponsoredMintMetadata>;
  evmTransferMetadata?: Maybe<EvmTransferMetadata>;
  evmUpdateCollectionMetadata?: Maybe<EvmUpdateCollectionMetadata>;
  evmUpdateRoyaltyMetadata?: Maybe<EvmUpdateRoyaltyMetadata>;
  id: Scalars["String"];
  receipt?: Maybe<SimplifiedTxReceipt>;
  status: TransactionStatus;
  transaction: SimplifiedTx;
  txHash: Scalars["String"];
  type: TransactionType;
  updatedAt: Scalars["String"];
};

/** Sale kind of the sale */
export enum EvmTxSaleKind {
  LISTING = "LISTING",
  OFFER = "OFFER",
}

export type EvmTxWithMintTokens = {
  evmTxGraphql: EvmTx;
  mintedTokenIds?: Maybe<Array<Scalars["String"]>>;
  sponsor?: Maybe<Sponsor>;
};

export type EvmUpdateCollectionMetadata = {
  collectionId?: Maybe<Scalars["String"]>;
  nftContractAddress: Scalars["String"];
};

export type EvmUpdateRoyaltyMetadata = {
  collectionId?: Maybe<Scalars["String"]>;
  nftContractAddress: Scalars["String"];
};

/** Explore category */
export enum ExploreCategory {
  Auctions = "Auctions",
  Categories = "Categories",
  CommunityToken = "CommunityToken",
  Curated = "Curated",
  EndingSoon = "EndingSoon",
  Farcaster = "Farcaster",
  Free = "Free",
  New = "New",
  PopularOnSecondary = "PopularOnSecondary",
  Tags = "Tags",
  Trending = "Trending",
}

export type ExploreFilters = {
  category: Scalars["String"];
  mainnetOnly: Scalars["Boolean"];
  periodHours: Scalars["Int"];
};

export type ExploreItem = {
  collection: CollectionCombined;
  minters?: Maybe<Array<Minter>>;
};

export type ExploreMints = {
  edges: Array<ExploreItem>;
  filters: ExploreFilters;
  pageInfo: CursorPaginatedInfo;
};

export type FailureReason = {
  code: FailureReasonCode;
  reason?: Maybe<Scalars["String"]>;
};

/** Failure Unique Code */
export enum FailureReasonCode {
  FC_NOT_FOLLOWING_ACCOUNT = "FC_NOT_FOLLOWING_ACCOUNT",
  FC_WALLET_NOT_CONNECTED = "FC_WALLET_NOT_CONNECTED",
  INSUFFICIENT_NFT_BALANCE = "INSUFFICIENT_NFT_BALANCE",
  NO_CREATOR_ACCESS = "NO_CREATOR_ACCESS",
  NO_EMAIL_LINKED = "NO_EMAIL_LINKED",
  PENDING_EMAIL_VERIFICATION = "PENDING_EMAIL_VERIFICATION",
  SERVER_FAILURE = "SERVER_FAILURE",
}

export type FarcasterUser = {
  displayName?: Maybe<Scalars["String"]>;
  fid: Scalars["Int"];
  followerCount?: Maybe<Scalars["String"]>;
  username: Scalars["String"];
  verifications: Array<Scalars["String"]>;
};

export type FcUser = {
  avatarUrl?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  fid: Scalars["Float"];
  username: Scalars["String"];
};

export type FeedbackInput = {
  description: Scalars["String"];
  url: Scalars["String"];
};

export type FrequentTags = {
  pageInfo: CursorPaginatedInfo;
  tags: Array<Tag>;
};

export type GaslessClaim = {
  consumerMintVectorId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  mintRecipient: Scalars["String"];
  numToMint?: Maybe<Scalars["Float"]>;
  relayerTaskId: Scalars["String"];
  sponsoredMintVectorId?: Maybe<Scalars["String"]>;
  tokenIds?: Maybe<Array<Scalars["Float"]>>;
  txHash?: Maybe<Scalars["String"]>;
};

export type GaslessClaimAppInput = {
  mintRecipient: Scalars["String"];
  numTokensToMint: Scalars["Float"];
};

export type GaslessClaimAppResponse = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  claimId: Scalars["String"];
  claimStatus: GaslessClaimStatus;
};

/** Gasless claim error code */
export enum GaslessClaimErrorCode {
  GAS_POOL_DEPLETED = "GAS_POOL_DEPLETED",
  INTERNAL = "INTERNAL",
  MINT_NOT_FUNDED = "MINT_NOT_FUNDED",
  MINT_UNAVAILABLE = "MINT_UNAVAILABLE",
  TOP_LEVEL_MINT_FEE_NOT_WAIVED = "TOP_LEVEL_MINT_FEE_NOT_WAIVED",
  UNKNOWN = "UNKNOWN",
}

export type GaslessClaimNft = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  metadata?: Maybe<GaslessClaimNftMetadata>;
  tokenId: Scalars["Float"];
};

export type GaslessClaimNftMetadata = {
  animationUrl?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<TokenMetadataAttribute>>;
  description?: Maybe<Scalars["String"]>;
  externalUrl?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  name: Scalars["String"];
};

export type GaslessClaimObject = {
  claim: GaslessClaim;
  contract: TransactionArgs;
};

/** Gasless claim status */
export enum GaslessClaimStatus {
  INITIATED = "INITIATED",
  TX_CANCELLED = "TX_CANCELLED",
  TX_COMPLETE = "TX_COMPLETE",
  TX_REVERTED = "TX_REVERTED",
  TX_SENT = "TX_SENT",
}

export type GaslessClaimStatusAppResponse = {
  nfts?: Maybe<Array<GaslessClaimNft>>;
  revertCode?: Maybe<GaslessClaimErrorCode>;
  status: GaslessClaimStatus;
};

export type GaslessMintClaimInput = {
  mintKey?: Maybe<Scalars["String"]>;
  numTokensToMint: Scalars["Float"];
};

export type GaslessTransactionStatus = {
  hash?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  status: Scalars["String"];
};

export type Gate = {
  buyButtonURL?: Maybe<Scalars["String"]>;
  conditions?: Maybe<Array<Condition>>;
  createdAt?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lockedMessage?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type GateContract = {
  address: Scalars["String"];
  contractType: Scalars["String"];
  decimals?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  platform: Scalars["String"];
  symbol?: Maybe<Scalars["String"]>;
  tokens: Array<GateToken>;
};

export type GateCreateInput = {
  buyButtonURL?: Maybe<Scalars["String"]>;
  conditions: Array<ConditionInput>;
  lockedMessage?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type GateDeleteResult = {
  deletedCount: Scalars["Int"];
};

export type GateToken = {
  address: Scalars["String"];
  contractType: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  platform: Scalars["String"];
  symbol?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
};

export type GateUpdateInput = {
  buyButtonURL?: Maybe<Scalars["String"]>;
  conditions: Array<ConditionInput>;
  lockedMessage?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type GatedAuction = IGatedEntity & {
  chainId?: Maybe<Scalars["Float"]>;
  collectionId: Scalars["String"];
  createdAt: Scalars["String"];
  currency: Scalars["String"];
  customBufferMinutes?: Maybe<Scalars["Float"]>;
  editionName: Scalars["String"];
  end: Scalars["String"];
  gate: Gate;
  gateId: Scalars["String"];
  history?: Maybe<Array<BidEvent>>;
  id?: Maybe<Scalars["String"]>;
  paused?: Maybe<Scalars["Boolean"]>;
  price: Scalars["String"];
  start: Scalars["String"];
  stats?: Maybe<AuctionStats>;
  tokenId: Scalars["String"];
  userGateAccess?: Maybe<UnlockGateResult>;
};

export type GatedAuctionUserGateAccessArgs = {
  fullResult: Scalars["Boolean"];
};

export type GatedMintVector = IGatedEntity & {
  addressGateAccess?: Maybe<UnlockGateResult>;
  chain?: Maybe<Chain>;
  chainId?: Maybe<Scalars["Float"]>;
  collectionId?: Maybe<Scalars["String"]>;
  collectorMessage?: Maybe<Scalars["String"]>;
  consumerData?: Maybe<MintConsumerData>;
  currency: Scalars["String"];
  currentPrice: Scalars["String"];
  customProjectData?: Maybe<CustomProjectData>;
  editionId?: Maybe<Scalars["Float"]>;
  editionName: Scalars["String"];
  end?: Maybe<Scalars["String"]>;
  ethRate: Scalars["String"];
  gate: Gate;
  gateId: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  isClosed: Scalars["Boolean"];
  isDirectMint?: Maybe<Scalars["Boolean"]>;
  isPublic: Scalars["Boolean"];
  /** check if this specific mint vector is sold out, expensive operation, only use when checking standalone vector */
  isSoldOut?: Maybe<Scalars["Boolean"]>;
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  mintVectorStats?: Maybe<MintVectorStats>;
  name?: Maybe<Scalars["String"]>;
  onchainMintVectorId?: Maybe<Scalars["String"]>;
  onchainVectorDataMeta?: Maybe<OnchainVectorDataMeta>;
  paused?: Maybe<Scalars["Boolean"]>;
  paymentCurrency?: Maybe<Currency>;
  paymentRecipient: Scalars["String"];
  price: Scalars["String"];
  priceDropInterval?: Maybe<Array<PriceDropTimePeriod>>;
  priceDrops?: Maybe<Array<PriceDrop>>;
  priceType?: Maybe<PriceType>;
  rankedAuction?: Maybe<RankedAuctionSettings>;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  sponsored?: Maybe<Scalars["Boolean"]>;
  sponsoredLatestErrorTimestamp?: Maybe<Scalars["Float"]>;
  start: Scalars["String"];
  stats?: Maybe<MintVectorStats>;
  userGateAccess?: Maybe<UnlockGateResult>;
};

export type GatedMintVectorAddressGateAccessArgs = {
  address: Scalars["String"];
  fullResult: Scalars["Boolean"];
};

export type GatedMintVectorIsSoldOutArgs = {
  checkCollectionTotalSold?: Maybe<Scalars["Boolean"]>;
};

export type GatedMintVectorMintVectorStatsArgs = {
  getEarned: Scalars["Boolean"];
  getUserStats: Scalars["Boolean"];
};

export type GatedMintVectorUserGateAccessArgs = {
  fullResult: Scalars["Boolean"];
};

export type GatedPage = IGatedEntity & {
  created: Scalars["String"];
  gate: Gate;
  gateId: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  sections: Array<PageSection>;
  slug: Scalars["String"];
  status: PageStatus;
  templateId?: Maybe<Scalars["String"]>;
  theme?: Maybe<PageTheme>;
};

export type GenSeriesHash = {
  hash: Scalars["String"];
  tokenId?: Maybe<Scalars["Int"]>;
};

export type GenSeriesHashesPaginated = {
  edges: Array<GenSeriesHash>;
  pageInfo: CursorPaginatedInfo;
};

export type GeneralToken = {
  animation_url?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  id: Scalars["String"];
  image: Scalars["String"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  royalty?: Maybe<V1Royalty>;
};

export type GenerativeCaptureSettings = {
  delay?: Maybe<Scalars["Float"]>;
  selector?: Maybe<Scalars["String"]>;
  selectorType?: Maybe<Scalars["String"]>;
  trigger: CapturePreviewTriggerType;
  viewPort?: Maybe<GenerativeCaptureViewPortSettings>;
};

export type GenerativeCaptureSettingsInput = {
  delay?: Maybe<Scalars["Float"]>;
  selector?: Maybe<Scalars["String"]>;
  selectorType?: Maybe<Scalars["String"]>;
  trigger: CapturePreviewTriggerType;
  viewPort?: Maybe<GenerativeCaptureViewPortSettingsInput>;
};

export type GenerativeCaptureViewPortSettings = {
  height: Scalars["Float"];
  width: Scalars["Float"];
};

export type GenerativeCaptureViewPortSettingsInput = {
  height: Scalars["Float"];
  width: Scalars["Float"];
};

export type GenerativeDetails = {
  allowedHashes?: Maybe<Array<Scalars["String"]>>;
  captureSettings: GenerativeCaptureSettings;
  curateOutput?: Maybe<Scalars["Boolean"]>;
  featuredTokenId?: Maybe<Scalars["String"]>;
  generativeCodeUri: Scalars["String"];
  initialIterationParams?: Maybe<Scalars["String"]>;
  isGPURendering?: Maybe<Scalars["Boolean"]>;
  isResponsive?: Maybe<Scalars["Boolean"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  mirroredBaseUri?: Maybe<Scalars["String"]>;
};

export type GenerativeDetailsDraft = {
  allowedHashes?: Maybe<Array<Scalars["String"]>>;
  captureSettings?: Maybe<GenerativeCaptureSettings>;
  curateOutput?: Maybe<Scalars["Boolean"]>;
  editionType?: Maybe<GenerativeEditionType>;
  isGPURendering?: Maybe<Scalars["Boolean"]>;
};

export type GenerativeDetailsInput = {
  allowedHashes?: Maybe<Array<Scalars["String"]>>;
  captureSettings?: Maybe<GenerativeCaptureSettingsInput>;
  curateOutput?: Maybe<Scalars["Boolean"]>;
  editionType?: Maybe<GenerativeEditionType>;
  isGPURendering?: Maybe<Scalars["Boolean"]>;
};

/** Generative edition type */
export enum GenerativeEditionType {
  Limited = "Limited",
  Open = "Open",
}

export type GenerativeTokenParams = {
  address?: Maybe<Scalars["String"]>;
  blockHash?: Maybe<Scalars["String"]>;
  blockNumber?: Maybe<Scalars["Int"]>;
  chainId?: Maybe<Scalars["Int"]>;
  editionSize?: Maybe<Scalars["Int"]>;
  gasPrice?: Maybe<Scalars["Int"]>;
  gasUsed?: Maybe<Scalars["Int"]>;
  isCurated?: Maybe<Scalars["Boolean"]>;
  mintIteration?: Maybe<Scalars["Int"]>;
  mintSize?: Maybe<Scalars["Int"]>;
  timestamp?: Maybe<Scalars["Float"]>;
  tokenId?: Maybe<Scalars["Int"]>;
  txHash: Scalars["String"];
  walletAddress?: Maybe<Scalars["String"]>;
};

export type GenericConditionData = {
  address?: Maybe<Scalars["String"]>;
  allowlist?: Maybe<Array<Scalars["String"]>>;
  allowlistLength?: Maybe<Scalars["Float"]>;
  allowlistRaw?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<Attribute>>;
  collectionName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["Boolean"]>;
  followFID?: Maybe<Scalars["String"]>;
  followFcUrl?: Maybe<Scalars["String"]>;
  followName?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  network?: Maybe<NetworkType>;
  standard?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
  videoUrl?: Maybe<Scalars["String"]>;
};

export type GenericConditionDataInput = {
  address?: Maybe<Scalars["String"]>;
  allowlist?: Maybe<Array<Scalars["String"]>>;
  allowlistLength?: Maybe<Scalars["Float"]>;
  allowlistRaw?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<AttributeInput>>;
  collectionName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["Boolean"]>;
  followFID?: Maybe<Scalars["String"]>;
  followFcUrl?: Maybe<Scalars["String"]>;
  followName?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  network?: Maybe<NetworkType>;
  standard?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
  videoUrl?: Maybe<Scalars["String"]>;
};

export type GetDiscordLinkUrlResponse = {
  linkUrl: Scalars["String"];
};

export type GetPageUploadUrlRequest = {
  mime: PageSupportedMediaMime;
  type: PageSupportedMediaType;
};

export type GetUploadUrlForContractUriRequest = {
  mime: SupportedMediaMime;
};

export type GetUploadUrlRequest = {
  communitySlug: Scalars["String"];
  mime: SupportedMediaMime;
  type: SupportedMediaType;
};

export type GlobalSearchResults = {
  accountSettings: Array<PublicAccountSettings>;
  collections: Array<Collection>;
  filters: SearchFilters;
};

export type GrantMarketingAccessInput = {
  creatorId?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
};

export type HighlightGuide = {
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type HighlightTip = {
  content: Scalars["String"];
  enabled: Scalars["Boolean"];
  event: Scalars["String"];
  id: Scalars["String"];
  link?: Maybe<Scalars["String"]>;
};

export type HostedMediaResource = {
  id: Scalars["String"];
  metadata?: Maybe<HostedMediaResourceMetadata>;
  type: SupportedMediaType;
};

export type HostedMediaResourceMetadata = {
  mime: SupportedMediaMime;
  publicUrl?: Maybe<Scalars["String"]>;
  type: SupportedMediaType;
  url?: Maybe<Scalars["String"]>;
};

export type IGatedEntity = {
  gate: Gate;
  gateId: Scalars["String"];
};

export type IdentitiesAuth = {
  account: Account;
  jwt: Scalars["String"];
};

export type IdentitiesNonce = {
  nonce: Scalars["String"];
};

export type Identity = {
  account?: Maybe<PublicAccount>;
  flagVariations: UserFlagVariations;
};

export type ImportData = {
  collectionSupply?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
  importedAt?: Maybe<Scalars["String"]>;
  importedFromPlatform?: Maybe<Scalars["String"]>;
  isMinting?: Maybe<Scalars["Boolean"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  media?: Maybe<Scalars["String"]>;
  royalty?: Maybe<V1Royalty>;
  sampleImages?: Maybe<Array<Scalars["String"]>>;
  standard?: Maybe<Scalars["String"]>;
};

export type ImportedToken = {
  accountId: Scalars["String"];
  address: Scalars["String"];
  chainId: Scalars["Float"];
  contractUri?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  id: Scalars["String"];
  importData?: Maybe<ImportData>;
  name: Scalars["String"];
  symbol?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
  type?: Maybe<ImportedTokenType>;
};

export type ImportedTokenDeleteResult = {
  deletedCount: Scalars["Int"];
};

/** Token type to import */
export enum ImportedTokenType {
  ERC20 = "ERC20",
  ERC721 = "ERC721",
  ERC1155 = "ERC1155",
  Native = "Native",
}

export type ImportedTokensPaginated = {
  importedTokens: Array<ImportedToken>;
  pageInfo: CursorPaginatedInfo;
};

export type InputThemeButton = {
  borderType?: Maybe<BorderType>;
  onPageBackgroundBackgroundColor?: Maybe<Scalars["String"]>;
  onPageBackgroundBorderColor?: Maybe<Scalars["String"]>;
  onPageBackgroundBorderWidth?: Maybe<Scalars["String"]>;
  onPageBackgroundTextColor?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundBackgroundColor?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundBorderColor?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundBorderWidth?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundTextColor?: Maybe<Scalars["String"]>;
  textFontFamily?: Maybe<Scalars["String"]>;
  textFontWeight?: Maybe<Scalars["String"]>;
  textLetterSpacing?: Maybe<Scalars["String"]>;
  textTextTransform?: Maybe<Scalars["String"]>;
};

export type InputThemeGlobal = {
  containerBorderType?: Maybe<Scalars["String"]>;
};

export type InputThemeLabel = {
  fontWeight?: Maybe<Scalars["String"]>;
  letterSpacing?: Maybe<Scalars["String"]>;
};

export type InputThemePageBackground = {
  backgroundColor?: Maybe<Scalars["String"]>;
};

export type InputThemePrimaryBackground = {
  backgroundColor?: Maybe<Scalars["String"]>;
};

export type InputThemeText = {
  boldFontWeight?: Maybe<Scalars["String"]>;
  fontFamily?: Maybe<Scalars["String"]>;
  fontWeight?: Maybe<Scalars["String"]>;
  letterSpacing?: Maybe<Scalars["String"]>;
  onPageBackgroundPrimaryColor?: Maybe<Scalars["String"]>;
  onPrimaryBackgroundPrimaryColor?: Maybe<Scalars["String"]>;
  textTransform?: Maybe<Scalars["String"]>;
};

export type InputThemeTextInput = {
  backgroundColor?: Maybe<Scalars["String"]>;
  borderColor?: Maybe<Scalars["String"]>;
  borderType: BorderType;
  borderWidth?: Maybe<Scalars["String"]>;
  primaryTextColor?: Maybe<Scalars["String"]>;
};

export type InputThemeTitle = {
  fontFamily?: Maybe<Scalars["String"]>;
  fontWeight?: Maybe<Scalars["String"]>;
  letterSpacing?: Maybe<Scalars["String"]>;
  textTransform?: Maybe<Scalars["String"]>;
};

export type InputWebsiteTheme = {
  button?: Maybe<InputThemeButton>;
  global?: Maybe<InputThemeGlobal>;
  label?: Maybe<InputThemeLabel>;
  pageBackground?: Maybe<InputThemePageBackground>;
  primaryBackground?: Maybe<InputThemePrimaryBackground>;
  text?: Maybe<InputThemeText>;
  textInput?: Maybe<InputThemeTextInput>;
  title?: Maybe<InputThemeTitle>;
};

export type IsSeriesUnzipped = {
  finishedUnzipping: Scalars["Boolean"];
};

export type LimitedMintsSettings = {
  limitPerWallet: Scalars["Int"];
};

export type LimitedMintsSettingsInput = {
  limitPerWallet: Scalars["Int"];
};

export type LinkDiscordAccountInput = {
  authCode: Scalars["String"];
  joinServers?: Maybe<Scalars["Boolean"]>;
};

export type LinkDiscordAccountResponse = {
  authMethod: Scalars["String"];
  blockchainAccount: Eoa;
  createdAt: Scalars["String"];
  createdCommunities: Array<CommunityIdentifier>;
  creatorTerms?: Maybe<TermsRecord>;
  discord?: Maybe<DiscordLinkedAccountData>;
  emailPreference: EmailPreference;
  flagVariations: UserFlagVariations;
  id: Scalars["String"];
  isMember?: Maybe<Scalars["Boolean"]>;
  magicLinkId: Scalars["String"];
  pendingTokens: Array<UserToken>;
  personalInformation: PersonalInformation;
  profile?: Maybe<Profile>;
  stripeAccount?: Maybe<StripeAccount>;
  syncDetails?: Maybe<SyncBenefitsResponse>;
  syncSuccessful?: Maybe<Scalars["Boolean"]>;
  terms?: Maybe<TermsRecord>;
  tokens: Array<UserToken>;
  updatedAt: Scalars["String"];
};

export type LinkDiscordAccountResponseIsMemberArgs = {
  slug: Scalars["String"];
};

export type LinkDiscordAccountResponseTokensArgs = {
  slug?: Maybe<Scalars["String"]>;
};

export type LinkedEmail = {
  address: Scalars["String"];
  email?: Maybe<LinkedEmail>;
  verifiedOn?: Maybe<Scalars["String"]>;
};

export type LinkedSocials = {
  email?: Maybe<LinkedEmail>;
};

export type LiveFeed = {
  chainId: Scalars["Float"];
  collection: LiveFeedCollection;
  createdAt: Scalars["Float"];
  from: Scalars["String"];
  kind: LiveFeedEventKind;
  mint?: Maybe<LiveFeedMint>;
  pk: Scalars["String"];
  sk?: Maybe<Scalars["String"]>;
  subKind: LiveFeedEventSubKind;
  to?: Maybe<Scalars["String"]>;
  transactionHash: Scalars["String"];
  users?: Maybe<Array<LiveFeedUsers>>;
  value: Scalars["String"];
};

export type LiveFeedCollection = {
  collectionType: _CollectionType;
  id: Scalars["String"];
  logo: Scalars["String"];
  name: Scalars["String"];
  ownerAddress?: Maybe<Scalars["String"]>;
};

/** Event Kind for Live Feed */
export enum LiveFeedEventKind {
  highlightxyz = "highlightxyz",
}

/** Event SubKind for Live Feed */
export enum LiveFeedEventSubKind {
  HighlightxyzMintManagerChooseTokenMint = "HighlightxyzMintManagerChooseTokenMint",
  HighlightxyzMintManagerNumTokenMint = "HighlightxyzMintManagerNumTokenMint",
  HighlightxyzObservabilityGenerativeSeriesDeployed = "HighlightxyzObservabilityGenerativeSeriesDeployed",
  HighlightxyzObservabilityMultipleEditionsDeployed = "HighlightxyzObservabilityMultipleEditionsDeployed",
  HighlightxyzObservabilitySeriesDeployed = "HighlightxyzObservabilitySeriesDeployed",
  HighlightxyzObservabilitySingleEditionDeployed = "HighlightxyzObservabilitySingleEditionDeployed",
}

export type LiveFeedMint = {
  minter: Scalars["String"];
  tokenIds: Array<Scalars["String"]>;
  value: Scalars["String"];
};

export type LiveFeedUsers = {
  avatar?: Maybe<Scalars["String"]>;
  ens?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  ownerAddress: Scalars["String"];
};

export type Log = {
  data: Scalars["String"];
  topics: Array<Scalars["String"]>;
};

export type ManageCollection = {
  address: Scalars["String"];
  archivedAt?: Maybe<Scalars["String"]>;
  baseUri?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  collectionType: _CollectionType;
  contract?: Maybe<ManageCollectionContract>;
  contractId: Scalars["String"];
  createdAt: Scalars["String"];
  creditCardEnabled?: Maybe<Scalars["Boolean"]>;
  crossmint?: Maybe<CrossmintEnv>;
  description?: Maybe<Scalars["String"]>;
  editionId?: Maybe<Scalars["String"]>;
  editions?: Maybe<Array<EditionToken>>;
  flagVariations: CollectionFlagVariations;
  generativeDetails?: Maybe<GenerativeDetails>;
  hidden?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  importData?: Maybe<ImportData>;
  isPublic: Scalars["Boolean"];
  mintVectors: Array<ManageCollectionSale>;
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  onChainBaseUri?: Maybe<Scalars["String"]>;
  onchainId: Scalars["String"];
  reveal?: Maybe<Scalars["Boolean"]>;
  seriesDetails?: Maybe<SeriesDetails>;
  size?: Maybe<Scalars["Float"]>;
  status: CollectionStatus;
  tokens?: Maybe<Array<GeneralToken>>;
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

export type ManageCollectionContract = {
  chainId: Scalars["Float"];
  contractAddress?: Maybe<Scalars["String"]>;
  contractUri?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  symbol: Scalars["String"];
  type: ContractEntityType;
};

export type ManageCollectionSale = {
  chainId?: Maybe<Scalars["Float"]>;
  collectorMessage?: Maybe<Scalars["String"]>;
  currency: Scalars["String"];
  earned?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  ethRate?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isDirectMint?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  onchainId?: Maybe<Scalars["String"]>;
  onchainMintVectorId?: Maybe<Scalars["String"]>;
  paused?: Maybe<Scalars["Boolean"]>;
  paymentCurrency?: Maybe<Currency>;
  paymentRecipient: Scalars["String"];
  price: Scalars["String"];
  priceDropInterval?: Maybe<Array<PriceDropTimePeriod>>;
  priceDrops?: Maybe<Array<PriceDrop>>;
  priceType?: Maybe<PriceType>;
  rankedAuction?: Maybe<RankedAuctionSettings>;
  saleId: Scalars["String"];
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  sold: Scalars["Float"];
  start: Scalars["String"];
  stats?: Maybe<MintVectorStats>;
  status: MintVectorStatus;
  vectorId?: Maybe<Scalars["String"]>;
};

export type ManageContract = {
  chainId: Scalars["Float"];
  contractAddress: Scalars["String"];
  contractId: Scalars["String"];
  createdAt: Scalars["String"];
  name: Scalars["String"];
  symbol: Scalars["String"];
  type: Scalars["String"];
};

export type MarketingPreferences = {
  allowedCreators: Array<CreatorDetails>;
};

export type MarketplaceCollectionAttribute = {
  name: Scalars["String"];
  variants: Array<MarketplaceTokenVariant>;
};

export type MarketplaceCollectionAttributesFilter = {
  name: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export type MarketplaceListing = {
  chainId: Scalars["Float"];
  collection?: Maybe<OrderCollection>;
  collectionMarketplaceId?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  createdAt: Scalars["String"];
  criteriaKind?: Maybe<Scalars["String"]>;
  expiredAt?: Maybe<Scalars["String"]>;
  feeBps: Scalars["Float"];
  fees: Array<OrderFee>;
  id: Scalars["String"];
  kind: Scalars["String"];
  makerAddress: Scalars["String"];
  price: OrderPrice;
  quantityFilled: Scalars["Float"];
  quantityRemaining: Scalars["Float"];
  reservoirId: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  sourceUrl?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  takerAddress?: Maybe<Scalars["String"]>;
  token?: Maybe<OrderToken>;
  tokenId: Scalars["String"];
  updatedAt: Scalars["String"];
  validFrom: Scalars["String"];
  validUntil?: Maybe<Scalars["String"]>;
};

export type MarketplaceListingWithStats = {
  chainId: Scalars["Float"];
  collection?: Maybe<OrderCollection>;
  collectionMarketplaceId?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  createdAt: Scalars["String"];
  criteriaKind?: Maybe<Scalars["String"]>;
  expiredAt?: Maybe<Scalars["String"]>;
  feeBps: Scalars["Float"];
  fees: Array<OrderFee>;
  floorPrice: Scalars["String"];
  highestOffer?: Maybe<MarketplaceOffer>;
  id: Scalars["String"];
  kind: Scalars["String"];
  makerAddress: Scalars["String"];
  offersAboveFloorCount: Scalars["Float"];
  offersCount: Scalars["Float"];
  price: OrderPrice;
  quantityFilled: Scalars["Float"];
  quantityRemaining: Scalars["Float"];
  reservoirId: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  sourceUrl?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  takerAddress?: Maybe<Scalars["String"]>;
  token?: Maybe<OrderToken>;
  tokenId: Scalars["String"];
  updatedAt: Scalars["String"];
  validFrom: Scalars["String"];
  validUntil?: Maybe<Scalars["String"]>;
};

export type MarketplaceListingsWithStatsPaginated = {
  edges: Array<MarketplaceListingWithStats>;
  pageInfo: CursorPaginatedInfo;
};

export type MarketplaceOffer = {
  chainId: Scalars["Float"];
  collection?: Maybe<OrderCollection>;
  collectionMarketplaceId?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  createdAt: Scalars["String"];
  criteriaKind?: Maybe<Scalars["String"]>;
  expiredAt?: Maybe<Scalars["String"]>;
  feeBps: Scalars["Float"];
  fees: Array<OrderFee>;
  floorPrice?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  kind: Scalars["String"];
  makerAddress: Scalars["String"];
  price: OrderPrice;
  quantityFilled: Scalars["Float"];
  quantityRemaining: Scalars["Float"];
  reservoirId: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  sourceUrl?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  takerAddress?: Maybe<Scalars["String"]>;
  tokenIds: Array<Scalars["String"]>;
  tokens?: Maybe<Array<OrderToken>>;
  updatedAt: Scalars["String"];
  user?: Maybe<OrderUser>;
  validFrom: Scalars["String"];
  validUntil?: Maybe<Scalars["String"]>;
};

export type MarketplaceOffersPaginated = {
  edges: Array<MarketplaceOffer>;
  pageInfo: CursorPaginatedInfo;
};

export type MarketplaceToken = {
  animationUrl?: Maybe<Scalars["String"]>;
  attributes: Array<MarketplaceTokenAttribute>;
  chainId: Scalars["Float"];
  collection: TokenCollection;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  imageUrl: Scalars["String"];
  metadata?: Maybe<MarketplaceTokenMetadata>;
  minted: Scalars["Boolean"];
  name: Scalars["String"];
  onChainId: Scalars["String"];
  ownerAddress?: Maybe<Scalars["String"]>;
  stats?: Maybe<MarketplaceTokenStats>;
  tokenAddress: Scalars["String"];
  tokenId: Scalars["String"];
  tokenListing?: Maybe<MarketplaceListing>;
  tokenRevealId?: Maybe<Scalars["String"]>;
  topOffer?: Maybe<MarketplaceOffer>;
};

export type MarketplaceTokenAttribute = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export type MarketplaceTokenMetadata = {
  animationMimeType?: Maybe<Scalars["String"]>;
  animationUrlOriginal?: Maybe<Scalars["String"]>;
  imageMimeType?: Maybe<Scalars["String"]>;
  imageUrlOriginal?: Maybe<Scalars["String"]>;
};

export type MarketplaceTokenStats = {
  floorListing?: Maybe<MarketplaceListing>;
  offersAboveFloorCount: Scalars["Float"];
  offersCount: Scalars["Float"];
  onChainId: Scalars["String"];
  tokenListing?: Maybe<MarketplaceListing>;
  topOffer?: Maybe<MarketplaceOffer>;
};

export type MarketplaceTokenVariant = {
  count: Scalars["Float"];
  value: Scalars["String"];
};

export type MarketplaceTokenWithStats = {
  animationUrl?: Maybe<Scalars["String"]>;
  attributes: Array<MarketplaceTokenAttribute>;
  chainId: Scalars["Float"];
  collection: TokenCollection;
  description?: Maybe<Scalars["String"]>;
  floorListing?: Maybe<MarketplaceListing>;
  id: Scalars["String"];
  imageUrl: Scalars["String"];
  metadata?: Maybe<MarketplaceTokenMetadata>;
  minted: Scalars["Boolean"];
  name: Scalars["String"];
  offersAboveFloorCount: Scalars["Float"];
  offersCount: Scalars["Float"];
  onChainId: Scalars["String"];
  ownerAddress?: Maybe<Scalars["String"]>;
  stats?: Maybe<MarketplaceTokenStats>;
  tokenAddress: Scalars["String"];
  tokenId: Scalars["String"];
  tokenListing?: Maybe<MarketplaceListing>;
  tokenRevealId?: Maybe<Scalars["String"]>;
  topOffer?: Maybe<MarketplaceOffer>;
};

export type MarketplaceTokensPaginated = {
  edges: Array<MarketplaceTokenWithStats>;
  pageInfo: CursorPaginatedInfo;
};

export type MarketplaceTokensPaginatedV2 = {
  edges: Array<MarketplaceToken>;
  pageInfo: CursorPaginatedInfo;
};

export type MediaMetadata = {
  mime: SupportedMediaMime;
  publicUrl?: Maybe<Scalars["String"]>;
  type: SupportedMediaType;
  url?: Maybe<Scalars["String"]>;
};

export type MediaMetadataInput = {
  mime: SupportedMediaMime;
  publicUrl?: Maybe<Scalars["String"]>;
  type: SupportedMediaType;
  url?: Maybe<Scalars["String"]>;
};

export type MergedCollection = {
  accountId?: Maybe<Scalars["String"]>;
  address: Scalars["String"];
  archivedAt?: Maybe<Scalars["String"]>;
  auctions: Array<Auction>;
  baseUri?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Scalars["String"]>>;
  chainId: Scalars["Float"];
  collectionImage?: Maybe<Scalars["String"]>;
  collectionType: _CollectionType;
  collectorsChoiceTokens?: Maybe<SeriesTokensPaginatedNew>;
  contract?: Maybe<Contract2>;
  contractId: Scalars["String"];
  contractUri?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  creatorAccountSettings?: Maybe<PublicAccountSettings>;
  creatorAddresses?: Maybe<Array<EvmAddress>>;
  creatorEns?: Maybe<Scalars["String"]>;
  creditCardEnabled?: Maybe<Scalars["Boolean"]>;
  crossmint?: Maybe<CrossmintEnv>;
  description?: Maybe<Scalars["String"]>;
  edition?: Maybe<EditionToken>;
  editionId?: Maybe<Scalars["String"]>;
  editions?: Maybe<Array<EditionToken>>;
  entityId?: Maybe<Scalars["String"]>;
  flagVariations: CollectionFlagVariations;
  genSeriesHashes?: Maybe<GenSeriesHashesPaginated>;
  generativeDetails?: Maybe<GenerativeDetails>;
  hasDetails: Scalars["Boolean"];
  hidden?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  importData?: Maybe<ImportData>;
  isDraft: Scalars["Boolean"];
  logoUrl?: Maybe<Scalars["String"]>;
  marketplaceId?: Maybe<Scalars["String"]>;
  mintVectors: Array<MintVector>;
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  onChainBaseUri?: Maybe<Scalars["String"]>;
  onChainMetadata?: Maybe<Scalars["String"]>;
  onchainId: Scalars["String"];
  openSeaCollectionUrl?: Maybe<Scalars["String"]>;
  reveal?: Maybe<Scalars["Boolean"]>;
  revealClaimedIds?: Maybe<Array<Scalars["Float"]>>;
  royalty?: Maybe<V1Royalty>;
  seriesAttributes: Array<AttributeSummary>;
  seriesDetails?: Maybe<SeriesDetails>;
  seriesImages?: Maybe<SeriesImages>;
  seriesToken?: Maybe<SeriesToken>;
  seriesTokenNormalized?: Maybe<SeriesToken>;
  seriesTokens?: Maybe<SeriesTokensPaginated>;
  size?: Maybe<Scalars["Float"]>;
  status: CollectionStatus;
  supply?: Maybe<Scalars["Float"]>;
  symbol?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  tokens?: Maybe<Array<GeneralToken>>;
  tokensNormalized?: Maybe<Array<SeriesToken>>;
  type: CollectionContractType;
  userCategories?: Maybe<Array<Category>>;
  userTags?: Maybe<Array<Tag>>;
  usingOpenseaBlocklist?: Maybe<Scalars["Boolean"]>;
};

export type MergedCollectionCollectorsChoiceTokensArgs = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<MarketplaceCollectionAttributesFilter>>;
  first?: Maybe<Scalars["Float"]>;
  minted?: Maybe<Scalars["Boolean"]>;
  sortBy?: Maybe<TokenSortableField>;
  sortDirection?: Maybe<SortDirection>;
  tokenNameOrId?: Maybe<Scalars["String"]>;
};

export type MergedCollectionCreatorAccountSettingsArgs = {
  withEns?: Maybe<Scalars["Boolean"]>;
};

export type MergedCollectionEditionArgs = {
  withDetails: Scalars["Boolean"];
  withProperties: Scalars["Boolean"];
};

export type MergedCollectionGenSeriesHashesArgs = {
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
};

export type MergedCollectionSeriesAttributesArgs = {
  minted?: Maybe<Scalars["Boolean"]>;
};

export type MergedCollectionSeriesTokenArgs = {
  tokenId: Scalars["String"];
};

export type MergedCollectionSeriesTokenNormalizedArgs = {
  fromS3?: Maybe<Scalars["Boolean"]>;
  tokenId: Scalars["String"];
};

export type MergedCollectionSeriesTokensArgs = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<NftAttribute>>;
  first?: Maybe<Scalars["Float"]>;
  minted?: Maybe<Scalars["Boolean"]>;
};

export type MergedCollectionTokensNormalizedArgs = {
  fromS3?: Maybe<Scalars["Boolean"]>;
  isImportedCollection?: Maybe<Scalars["Boolean"]>;
  tokenIds: Array<Scalars["String"]>;
};

export type MergedCollectionsPaginated = {
  collections: Array<CollectionWithDraft>;
  pageInfo: PagePaginatedInfo;
};

export type MergedDraft = {
  accountId: Scalars["String"];
  archivedAt?: Maybe<Scalars["String"]>;
  collectionDraft?: Maybe<CollectionDetailsDraft>;
  collectionType: _CollectionType;
  contractDraft?: Maybe<ContractDraft>;
  contractId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  distributionDraft?: Maybe<DistributionDraft>;
  distributionVectorsDraft?: Maybe<Array<DistributionVectorDraft>>;
  furthestStep: DraftProgress;
  gateId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isDraft: Scalars["Boolean"];
  metadata?: Maybe<CollectionMetadata>;
  moduleSettings: ModuleSettingsDraft;
  seriesTokens?: Maybe<SeriesTokensPaginated>;
  updatedAt: Scalars["String"];
  version?: Maybe<Scalars["Float"]>;
};

export type MergedDraftSeriesTokensArgs = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<NftAttribute>>;
  first?: Maybe<Scalars["Float"]>;
  minted?: Maybe<Scalars["Boolean"]>;
};

export type MetaTxPacket = {
  functionSignature: Scalars["String"];
  sigR: Scalars["String"];
  sigS: Scalars["String"];
  sigV: Scalars["Int"];
};

export type MintAttributes = {
  onChain: Scalars["Boolean"];
  params: Scalars["String"];
};

export type MintAttributesInput = {
  onChain: Scalars["Boolean"];
  params: Scalars["String"];
};

export type MintClaimInput = {
  mintData?: Maybe<Scalars["String"]>;
  numTokensToMint: Scalars["Float"];
  referrer?: Maybe<Scalars["String"]>;
};

export type MintConsumerData = {
  id?: Maybe<Scalars["String"]>;
  type: ConsumerMintVectorType;
};

export type MintContent = {
  attributes: MintAttributes;
  id?: Maybe<Scalars["String"]>;
  type: MintIdType;
};

export type MintContentInput = {
  attributes: MintAttributesInput;
  id?: Maybe<Scalars["String"]>;
  type: MintIdType;
};

/** Mint ID Type */
export enum MintIdType {
  ContractAddress = "ContractAddress",
  Vector = "Vector",
}

export type MintNewTokenInput = {
  benefits?: Maybe<Array<TokenBenefitType>>;
  description: Scalars["String"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  quantity: Scalars["Float"];
  releasePartyId?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type MintPage = {
  address: Scalars["String"];
  archivedAt?: Maybe<Scalars["String"]>;
  baseUri?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  collectionAddress: Scalars["String"];
  collectionImage?: Maybe<Scalars["String"]>;
  collectionType: _CollectionType;
  contractUri: Scalars["String"];
  createdAt: Scalars["String"];
  creatorAccountSettings?: Maybe<PublicAccountSettings>;
  creatorAddresses: Array<EvmAddress>;
  creatorEns?: Maybe<Scalars["String"]>;
  creditCardEnabled?: Maybe<Scalars["Boolean"]>;
  crossmint?: Maybe<CrossmintEnv>;
  description?: Maybe<Scalars["String"]>;
  edition?: Maybe<EditionToken>;
  editionId?: Maybe<Scalars["String"]>;
  editions?: Maybe<Array<EditionToken>>;
  flagVariations: CollectionFlagVariations;
  generativeDetails?: Maybe<GenerativeDetails>;
  hidden?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  importData?: Maybe<ImportData>;
  marketplaceId: Scalars["String"];
  name: Scalars["String"];
  nonTransferable?: Maybe<Scalars["Boolean"]>;
  onChainBaseUri?: Maybe<Scalars["String"]>;
  onChainMetadata?: Maybe<Scalars["String"]>;
  ownerAddress: Scalars["String"];
  reveal?: Maybe<Scalars["Boolean"]>;
  royalty?: Maybe<V1Royalty>;
  sales: Array<MintPageSale>;
  seriesDetails?: Maybe<SeriesDetails>;
  seriesImages?: Maybe<SeriesImages>;
  size?: Maybe<Scalars["Float"]>;
  status: CollectionStatus;
  supply?: Maybe<Scalars["Float"]>;
  symbol?: Maybe<Scalars["String"]>;
  tokens?: Maybe<Array<GeneralToken>>;
  type: CollectionContractType;
  usingOpenseaBlocklist: Scalars["Boolean"];
};

export type MintPageCreatorAccountSettingsArgs = {
  withEns?: Maybe<Scalars["Boolean"]>;
};

export type MintPageSale = {
  chain?: Maybe<MintPageSaleChain>;
  chainId?: Maybe<Scalars["Float"]>;
  collectorMessage?: Maybe<Scalars["String"]>;
  consumerData?: Maybe<MintConsumerData>;
  currency: Scalars["String"];
  customProjectData?: Maybe<CustomProjectData>;
  end?: Maybe<Scalars["String"]>;
  ethRate: Scalars["String"];
  gateId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isDirectMint?: Maybe<Scalars["Boolean"]>;
  isPublic?: Maybe<Scalars["Boolean"]>;
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  onchainMintVectorId?: Maybe<Scalars["String"]>;
  paused?: Maybe<Scalars["Boolean"]>;
  paymentCurrency?: Maybe<Currency>;
  paymentRecipient: Scalars["String"];
  price: Scalars["String"];
  priceDropInterval?: Maybe<Array<PriceDropTimePeriod>>;
  priceDrops?: Maybe<Array<PriceDrop>>;
  priceType?: Maybe<PriceType>;
  rankedAuction?: Maybe<RankedAuctionSettings>;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  sponsored?: Maybe<Scalars["Boolean"]>;
  sponsoredLatestErrorTimestamp?: Maybe<Scalars["Float"]>;
  start: Scalars["String"];
  stats?: Maybe<MintVectorStats>;
  userGateAccess?: Maybe<UnlockGateResult>;
};

export type MintPageSaleUserGateAccessArgs = {
  fullResult: Scalars["Boolean"];
};

export type MintPageSaleChain = {
  chainId: Scalars["Float"];
  contracts: Array<SystemContract>;
  enabled: Scalars["Boolean"];
  id: Scalars["String"];
  mintFee: Scalars["String"];
  name: Scalars["String"];
  network: NetworkType;
  networkUrl: Scalars["String"];
  supportedFeatures: Array<AppFeature>;
  supportedPaymentCurrencies: Array<Currency>;
  testnet: Scalars["Boolean"];
};

export type MintVector = {
  addressGateAccess?: Maybe<UnlockGateResult>;
  chain?: Maybe<Chain>;
  chainId?: Maybe<Scalars["Float"]>;
  collectionId?: Maybe<Scalars["String"]>;
  collectorMessage?: Maybe<Scalars["String"]>;
  consumerData?: Maybe<MintConsumerData>;
  currency: Scalars["String"];
  currentPrice: Scalars["String"];
  customProjectData?: Maybe<CustomProjectData>;
  editionId?: Maybe<Scalars["Float"]>;
  end?: Maybe<Scalars["String"]>;
  ethRate: Scalars["String"];
  gateId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  isClosed: Scalars["Boolean"];
  isDirectMint?: Maybe<Scalars["Boolean"]>;
  isPublic: Scalars["Boolean"];
  /** check if this specific mint vector is sold out, expensive operation, only use when checking standalone vector */
  isSoldOut?: Maybe<Scalars["Boolean"]>;
  maxPerTxn: Scalars["Float"];
  maxPerUser: Scalars["Float"];
  maxPerVector: Scalars["Float"];
  mintVectorStats?: Maybe<MintVectorStats>;
  name?: Maybe<Scalars["String"]>;
  onchainMintVectorId?: Maybe<Scalars["String"]>;
  onchainVectorDataMeta?: Maybe<OnchainVectorDataMeta>;
  paused?: Maybe<Scalars["Boolean"]>;
  paymentCurrency?: Maybe<Currency>;
  paymentRecipient: Scalars["String"];
  price: Scalars["String"];
  priceDropInterval?: Maybe<Array<PriceDropTimePeriod>>;
  priceDrops?: Maybe<Array<PriceDrop>>;
  priceType?: Maybe<PriceType>;
  rankedAuction?: Maybe<RankedAuctionSettings>;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  sponsored?: Maybe<Scalars["Boolean"]>;
  sponsoredLatestErrorTimestamp?: Maybe<Scalars["Float"]>;
  start: Scalars["String"];
  stats?: Maybe<MintVectorStats>;
  userGateAccess?: Maybe<UnlockGateResult>;
};

export type MintVectorAddressGateAccessArgs = {
  address: Scalars["String"];
  fullResult: Scalars["Boolean"];
};

export type MintVectorIsSoldOutArgs = {
  checkCollectionTotalSold?: Maybe<Scalars["Boolean"]>;
};

export type MintVectorMintVectorStatsArgs = {
  getEarned: Scalars["Boolean"];
  getUserStats: Scalars["Boolean"];
};

export type MintVectorUserGateAccessArgs = {
  fullResult: Scalars["Boolean"];
};

export type MintVectorStats = {
  claimedByCurrentUser?: Maybe<Scalars["Float"]>;
  dutchAuctionRebateWei?: Maybe<Scalars["String"]>;
  earned?: Maybe<Scalars["String"]>;
  onchainDutchAuctionStats?: Maybe<OnchainDutchAuctionStats>;
  sold: Scalars["Float"];
  status: MintVectorStatus;
  total: Scalars["Float"];
};

/** Status of the Mint Vector */
export enum MintVectorStatus {
  Ended = "Ended",
  InProgress = "InProgress",
  NotStarted = "NotStarted",
}

export type Minter = {
  address: Scalars["String"];
  count: Scalars["Int"];
  fcUser: FcUser;
};

export type ModuleSettingsDraft = {
  customPaymentRecipient?: Maybe<CustomPaymentRecipientSettings>;
  limitedMints?: Maybe<LimitedMintsSettings>;
  maxPerTxn?: Maybe<Scalars["Float"]>;
  nonTransferable?: Maybe<NonTransferableSettings>;
  royalty?: Maybe<RoyaltySettings>;
};

export type ModuleSettingsDraftInput = {
  customPaymentRecipient?: Maybe<CustomPaymentRecipientSettingsInput>;
  limitedMints?: Maybe<LimitedMintsSettingsInput>;
  maxPerTxn?: Maybe<Scalars["Float"]>;
  nonTransferable?: Maybe<NonTransferableSettingsInput>;
  royalty?: Maybe<RoyaltySettingsInput>;
};

export type Mutation = {
  acceptCurrentUserTerms: User;
  auctionBid: AuctionBidOutput;
  auctionUpdate: AuctionUpdateOutput;
  /** @deprecated use logInWithMagicLink or createUserWithMagicLink */
  authWithMagicLink: Auth;
  checkoutCompleted?: Maybe<Scalars["Boolean"]>;
  claimByCollectionApp: GaslessClaimAppResponse;
  claimFreeOffer: BlockchainTaskRef;
  claimMint: ClaimObject;
  claimMintTo: ClaimObject;
  clearPendingEmail: AccountSettings;
  collectionDelete: CollectionDeleteResult;
  collectionUpdate: Collection;
  completeConnectWithSignedMessage: IdentitiesAuth;
  completeCreateUserWithSignedMessage: Auth;
  completeLoginWithSignedMessage: Auth;
  completeSignInWithJWT: IdentitiesAuth;
  createCollectionDraft: CreateCollectionDraftOutput;
  createCommunity: Community;
  createContract: Contract2;
  createEvm721AuctionTransaction: EvmTx;
  createEvm721BidTransaction: EvmTx;
  createEvm721MintTransaction: EvmTx;
  createEvm721RankedAuctionBidTransaction: EvmTx;
  createEvm721RankedAuctionClaimEarningsTx: EvmTx;
  createEvm721RankedAuctionReclaimBidFundsTx: EvmTx;
  createEvmApproveTransferTransaction: EvmTx;
  createEvmApproveTransferWrappedTransaction: EvmTx;
  createEvmCrosschainBurnTransaction: EvmTx;
  createEvmCurrencyAllowanceTransaction: EvmTx;
  createEvmDeployTransaction: EvmTx;
  createEvmDepositFundsTransaction: EvmTx;
  createEvmMintCreatorReservesTransaction: EvmTx;
  createEvmSaleTransaction: EvmTx;
  createEvmSetBaseUriTransaction: EvmTx;
  createEvmSponsorMintTransaction: EvmTx;
  createEvmTransferTransaction: EvmTx;
  createEvmUpdateCollectionTransaction: EvmTx;
  createEvmUpdateRoyaltyTransaction: EvmTx;
  createFeedback: Scalars["Boolean"];
  /** @deprecated should use create import token */
  createImportedCollection: CreateImportedCollectionOutput;
  createImportedToken: Scalars["String"];
  createMintVector: MintVector;
  createNewComment: Comment;
  createNewPost: Post;
  createOffer: Offer;
  createPost: Post;
  createToken: CreateTokenOutput;
  createUserWithMagicLink: Auth;
  crosschainBurn: ClaimObject;
  crossmintClaim: ClaimObject;
  cryptoOrderSend: BlockchainTaskRef;
  deleteComment: Comment;
  /** Delete banner image for a community. */
  deleteCommunityBannerImage: Community;
  /** Delete logo image for a community. */
  deleteCommunityLogoImage: Community;
  /** Delete creator avatar image for a community. */
  deleteCreatorAvatarImage: Community;
  deleteDiscordInfo: Community;
  deleteDraft: DraftDeletedOutput;
  deleteImportedToken: ImportedTokenDeleteResult;
  /** Delete post */
  deletePost: Scalars["Boolean"];
  /** Delete the profile image for a user. */
  deleteProfileImage: User;
  deleteShopifyCollectionBuyButton: DeleteCollectionBuyButtonResult;
  deployCollection: CollectionDraft;
  disableClaimTokenContest: Scalars["Boolean"];
  disconnectTwitter: Scalars["Boolean"];
  duplicateCollection: MergedDraft;
  duplicateCollectionDraft: MergedDraft;
  emitAnalyticsEvent: EmitResult;
  fulfillAuction: TransactionArgs;
  gaslessClaimMint: GaslessClaimObject;
  gateCreate: Gate;
  gateDelete: GateDeleteResult;
  gateUpdate: Gate;
  genSeriesCapturePreview: CaptureGenerativeSeriesPreviewOutput;
  generateClaimTokenContest: ClaimToken;
  generateTwitterAuthURL: Scalars["String"];
  getPageUploadUrl: PageAttachment;
  getUploadUrl: Attachment;
  getUploadUrlForContractUri: HostedMediaResource;
  getUploadUrlForSeries: SeriesUploadOutput;
  grantCreatorMarketingAccess: AccountSettings;
  insertCurrentUserToContest: ContestEntry;
  launchCommunity: Community;
  linkDiscordUser: LinkDiscordAccountResponse;
  linkEmail: AccountSettings;
  logInWithMagicLink: Auth;
  /** @deprecated The create transaction endpoint will handle this case */
  markDeploying: Collection;
  /** @deprecated The update transactions endpoint will handle this case */
  markFinished: Collection;
  mintNewToken: BlockchainTaskRef;
  pageCreate: Page;
  pageDelete: PageDeleteResult;
  pageUpdate: Page;
  portfolioAddItem?: Maybe<Portfolio>;
  portfolioCreate?: Maybe<Portfolio>;
  portfolioDelete?: Maybe<PortfolioDeleteResult>;
  portfolioEditItem?: Maybe<Portfolio>;
  portfolioRemoveItem?: Maybe<Portfolio>;
  portfolioUpdate?: Maybe<Portfolio>;
  prepareForMint: BlockchainTaskRefNullable;
  refreshAccessToken: Auth;
  refreshCollectionDraft: CollectionDraft;
  removeAllMarketingAccess: AccountSettings;
  removeCreatorMarketingAccess: AccountSettings;
  sendTokens: SendToken;
  seriesClaimMint: ClaimObject;
  seriesClaimMintTo: ClaimObject;
  setColorScheme: ColorSchemeType;
  sponsoredClaimMint: GaslessClaimObject;
  syncStripeAccount: StripeAccount;
  syncUserBenefits: SyncBenefitsResponse;
  toggleArchive: MergedCollection;
  toggleDraftArchive: MergedDraft;
  unlinkDiscordUser: UnlinkDiscordAccountResponse;
  updateAccountSettings: PublicAccountSettings;
  updateCollectionDraft: CollectionDraft;
  updateCollectionMetadata: TransactionArgs;
  updateCommentReaction: Comment;
  updateContract: Contract2;
  /** Update theme styles for a community. */
  updateCreatedCommunityTheme: WebsiteTheme;
  updateCurrentUserEmail: User;
  updateDiscordInfo: Community;
  updateEmailPreference: User;
  updateEvm721AuctionTx: EvmTx;
  updateEvm721BidTx: EvmTx;
  updateEvm721MintTx: EvmTxWithMintTokens;
  updateEvm721RankedAuctionBidTx: EvmTx;
  updateEvm721RankedAuctionBidTxConsumer: EvmTx;
  updateEvm721RankedAuctionClaimAuctionEarningsTx: EvmTx;
  updateEvmApproveTransferTx: EvmTx;
  updateEvmApproveTransferWrappedTx: EvmTx;
  updateEvmCreateMintVectorTx: EvmTx;
  updateEvmCrosschainBurnTx: EvmTx;
  updateEvmCurrencyAllowanceTx: EvmTx;
  updateEvmDeployTx: EvmTx;
  updateEvmDepositFundsTx: EvmTx;
  updateEvmMintCreatorReservesTx: EvmTxWithMintTokens;
  updateEvmSaleTx: EvmTx;
  updateEvmSetBaseUriTx: EvmTx;
  updateEvmSponsorMintTx: EvmTx;
  updateEvmTransferTx: EvmTx;
  updateEvmUpdateCollectionTx: EvmTx;
  updateEvmUpdateRoyaltyTx: EvmTx;
  updateGeneralInfo: Community;
  updateMintVector: MintVector;
  updateOffer: Offer;
  updatePaymentRecipientOnPrivateSales: Scalars["Boolean"];
  updatePhoneNumber: User;
  updatePostContent: Post;
  /** Update Post Pin */
  updatePostPin: Post;
  updatePostReaction: Post;
  updateProfile: User;
  updateShopifyCollectionBuyButton: UpdateCollectionBuyButtonResult;
  updateUsername: User;
  /** Upload banner image for a community. */
  uploadCommunityBannerImage: Community;
  /** Upload logo image for a community. */
  uploadCommunityLogoImage: Community;
  /** Upload creator avatar image for a community. */
  uploadCreatorAvatarImage: Community;
  /** Upload profile image for a user. */
  uploadProfileImage: User;
  verifyEmail: AccountSettings;
  verifyPhoneNumber: User;
  verifyTwitterConnection: Scalars["String"];
  withdrawCreatorRoyalties: BlockchainTaskRef;
};

export type MutationAcceptCurrentUserTermsArgs = {
  terms: AcceptTermsInput;
};

export type MutationAuctionBidArgs = {
  auctionId: Scalars["String"];
  data: AuctionBidInput;
};

export type MutationAuctionUpdateArgs = {
  data: AuctionUpdateInput;
};

export type MutationAuthWithMagicLinkArgs = {
  didToken: Scalars["String"];
};

export type MutationCheckoutCompletedArgs = {
  paymentIntent: Scalars["String"];
  paymentIntentClientSecret: Scalars["String"];
  redirectStatus: Scalars["String"];
};

export type MutationClaimByCollectionAppArgs = {
  collectionId: Scalars["String"];
  data: GaslessClaimAppInput;
};

export type MutationClaimFreeOfferArgs = {
  offerId: Scalars["String"];
};

export type MutationClaimMintArgs = {
  data: MintClaimInput;
  vectorId: Scalars["String"];
};

export type MutationClaimMintToArgs = {
  data: MintClaimInput;
  mintTo: Scalars["String"];
  vectorId: Scalars["String"];
};

export type MutationCollectionDeleteArgs = {
  id: Scalars["String"];
};

export type MutationCollectionUpdateArgs = {
  collectionId: Scalars["String"];
  data: CollectionUpdateInput;
};

export type MutationCompleteConnectWithSignedMessageArgs = {
  message: Scalars["String"];
  signature: Scalars["String"];
};

export type MutationCompleteCreateUserWithSignedMessageArgs = {
  email: Scalars["String"];
  publicAddress: Scalars["String"];
  signature: Scalars["String"];
};

export type MutationCompleteLoginWithSignedMessageArgs = {
  publicAddress: Scalars["String"];
  signature: Scalars["String"];
};

export type MutationCompleteSignInWithJwtArgs = {
  jwt: Scalars["String"];
};

export type MutationCreateCollectionDraftArgs = {
  data: CreateCollectionDraftInput;
};

export type MutationCreateCommunityArgs = {
  data: CreateCommunityInput;
};

export type MutationCreateContractArgs = {
  data: CreateContractInput;
};

export type MutationCreateEvm721AuctionTransactionArgs = {
  input: CreateEvm721AuctionTransactionInput;
};

export type MutationCreateEvm721BidTransactionArgs = {
  input: CreateEvm721BidTransactionInput;
};

export type MutationCreateEvm721MintTransactionArgs = {
  input: CreateEvm721MintTransactionInput;
};

export type MutationCreateEvm721RankedAuctionBidTransactionArgs = {
  input: CreateEvm721RankedAuctionBidTransactionInput;
};

export type MutationCreateEvm721RankedAuctionClaimEarningsTxArgs = {
  input: CreateEvm721AuctionTransactionInput;
};

export type MutationCreateEvm721RankedAuctionReclaimBidFundsTxArgs = {
  input: CreateEvm721RankedAuctionBidTransactionInput;
};

export type MutationCreateEvmApproveTransferTransactionArgs = {
  input: CreateEvmApproveTransferTransactionInput;
};

export type MutationCreateEvmApproveTransferWrappedTransactionArgs = {
  input: CreateEvmApproveTransferWrappedTransactionInput;
};

export type MutationCreateEvmCrosschainBurnTransactionArgs = {
  input: CreateEvmCrosschainBurnTransactionInput;
};

export type MutationCreateEvmCurrencyAllowanceTransactionArgs = {
  input: CreateEvmCurrencyAllowanceTransactionInput;
};

export type MutationCreateEvmDeployTransactionArgs = {
  data: CreateEvmDeployTransactionInput;
};

export type MutationCreateEvmDepositFundsTransactionArgs = {
  input: CreateEvmDepositFundsTransactionInput;
};

export type MutationCreateEvmMintCreatorReservesTransactionArgs = {
  input: CreateEvmMintCreatorReservesTransactionInput;
};

export type MutationCreateEvmSaleTransactionArgs = {
  input: CreateEvmSaleTransactionInput;
};

export type MutationCreateEvmSetBaseUriTransactionArgs = {
  input: CreateEvmSetBaseUriTransactionInput;
};

export type MutationCreateEvmSponsorMintTransactionArgs = {
  input: CreateEvmSponsorMintTransactionInput;
};

export type MutationCreateEvmTransferTransactionArgs = {
  input: CreateEvmTransferTransactionInput;
};

export type MutationCreateEvmUpdateCollectionTransactionArgs = {
  input: CreateEvmUpdateCollectionTransactionInput;
};

export type MutationCreateEvmUpdateRoyaltyTransactionArgs = {
  input: CreateEvmUpdateRoyaltyTransactionInput;
};

export type MutationCreateFeedbackArgs = {
  feedback: FeedbackInput;
};

export type MutationCreateImportedCollectionArgs = {
  data: CreateImportedCollectionInput;
};

export type MutationCreateImportedTokenArgs = {
  data: CreateImportedTokenInput;
};

export type MutationCreateMintVectorArgs = {
  data: CreateMintVectorInput;
};

export type MutationCreateNewCommentArgs = {
  data: CreateReleasePartyCommentInput;
};

export type MutationCreateNewPostArgs = {
  data: CreateNewPostInput;
};

export type MutationCreateOfferArgs = {
  data: CreateOfferInput;
};

export type MutationCreatePostArgs = {
  data: CreatePostInput;
};

export type MutationCreateTokenArgs = {
  data: CreateTokenInput;
};

export type MutationCreateUserWithMagicLinkArgs = {
  didToken: Scalars["String"];
};

export type MutationCrosschainBurnArgs = {
  data: MintClaimInput;
  vectorId: Scalars["String"];
};

export type MutationCrossmintClaimArgs = {
  data: CrossmintClaimInput;
  vectorId: Scalars["String"];
};

export type MutationCryptoOrderSendArgs = {
  input: CryptoOrderInput;
};

export type MutationDeleteCommentArgs = {
  commentId: Scalars["String"];
};

export type MutationDeleteCommunityBannerImageArgs = {
  id: Scalars["String"];
};

export type MutationDeleteCommunityLogoImageArgs = {
  id: Scalars["String"];
};

export type MutationDeleteCreatorAvatarImageArgs = {
  id: Scalars["String"];
};

export type MutationDeleteDiscordInfoArgs = {
  id: Scalars["String"];
};

export type MutationDeleteDraftArgs = {
  draftId: Scalars["String"];
};

export type MutationDeleteImportedTokenArgs = {
  tokenId: Scalars["String"];
};

export type MutationDeletePostArgs = {
  communitySlug: Scalars["String"];
  id: Scalars["String"];
};

export type MutationDeleteShopifyCollectionBuyButtonArgs = {
  communityId: Scalars["String"];
};

export type MutationDeployCollectionArgs = {
  draftId: Scalars["String"];
};

export type MutationDisableClaimTokenContestArgs = {
  contestId: Scalars["String"];
  id: Scalars["String"];
};

export type MutationDuplicateCollectionArgs = {
  collectionId: Scalars["String"];
  data: DuplicateCollectionInput;
};

export type MutationDuplicateCollectionDraftArgs = {
  data: DuplicateCollectionInput;
  draftId: Scalars["String"];
};

export type MutationEmitAnalyticsEventArgs = {
  event: AnalyticsInput;
};

export type MutationFulfillAuctionArgs = {
  auctionId: Scalars["String"];
  chainId: Scalars["Float"];
};

export type MutationGaslessClaimMintArgs = {
  data: GaslessMintClaimInput;
  vectorId: Scalars["String"];
};

export type MutationGateCreateArgs = {
  input: GateCreateInput;
};

export type MutationGateDeleteArgs = {
  id: Scalars["String"];
};

export type MutationGateUpdateArgs = {
  id: Scalars["String"];
  input: GateUpdateInput;
};

export type MutationGenSeriesCapturePreviewArgs = {
  codeURI: Scalars["String"];
  isGPURendering?: Maybe<Scalars["Boolean"]>;
  params: GenerativeTokenParams;
  settings: GenerativeCaptureSettingsInput;
  skipImageCapture?: Maybe<Scalars["Boolean"]>;
};

export type MutationGenerateClaimTokenContestArgs = {
  communityId: Scalars["String"];
  data: ClaimTokenInput;
  id: Scalars["String"];
};

export type MutationGetPageUploadUrlArgs = {
  data: GetPageUploadUrlRequest;
};

export type MutationGetUploadUrlArgs = {
  data: GetUploadUrlRequest;
};

export type MutationGetUploadUrlForContractUriArgs = {
  data: GetUploadUrlForContractUriRequest;
};

export type MutationGetUploadUrlForSeriesArgs = {
  data: SeriesZipUploadInput;
};

export type MutationGrantCreatorMarketingAccessArgs = {
  data: GrantMarketingAccessInput;
};

export type MutationInsertCurrentUserToContestArgs = {
  contestEntry: ContestEntryBody;
  contestId: Scalars["String"];
};

export type MutationLaunchCommunityArgs = {
  id: Scalars["String"];
};

export type MutationLinkDiscordUserArgs = {
  input: LinkDiscordAccountInput;
};

export type MutationLinkEmailArgs = {
  creatorId?: Maybe<Scalars["String"]>;
  emailAddress: Scalars["String"];
  redirectUrl?: Maybe<Scalars["String"]>;
};

export type MutationLogInWithMagicLinkArgs = {
  didToken: Scalars["String"];
};

export type MutationMarkDeployingArgs = {
  collectionId: Scalars["String"];
};

export type MutationMarkFinishedArgs = {
  collectionId: Scalars["String"];
};

export type MutationMintNewTokenArgs = {
  animation?: Maybe<Scalars["Upload"]>;
  asset: Scalars["Upload"];
  data: MintNewTokenInput;
  id: Scalars["String"];
};

export type MutationPageCreateArgs = {
  input: PageCreateInput;
};

export type MutationPageDeleteArgs = {
  id: Scalars["String"];
};

export type MutationPageUpdateArgs = {
  id: Scalars["String"];
  input: PageUpdateInput;
};

export type MutationPortfolioAddItemArgs = {
  input: CreatePortfolioItemInput;
  portfolioId: Scalars["String"];
};

export type MutationPortfolioCreateArgs = {
  input: CreatePortfolioInput;
};

export type MutationPortfolioDeleteArgs = {
  portfolioId: Scalars["String"];
};

export type MutationPortfolioEditItemArgs = {
  item?: Maybe<UpdatePortfolioItemInput>;
  itemId: Scalars["String"];
  portfolioId: Scalars["String"];
  position?: Maybe<Scalars["Int"]>;
};

export type MutationPortfolioRemoveItemArgs = {
  itemId: Scalars["String"];
  portfolioId: Scalars["String"];
};

export type MutationPortfolioUpdateArgs = {
  input: UpdatePortfolioInput;
  portfolioId: Scalars["String"];
};

export type MutationPrepareForMintArgs = {
  id: Scalars["String"];
};

export type MutationRefreshCollectionDraftArgs = {
  draftId: Scalars["String"];
};

export type MutationRemoveCreatorMarketingAccessArgs = {
  creatorId: Scalars["String"];
};

export type MutationSendTokensArgs = {
  communityId: Scalars["String"];
  tokenId: Scalars["String"];
};

export type MutationSeriesClaimMintArgs = {
  data: SeriesMintClaimInput;
  vectorId: Scalars["String"];
};

export type MutationSeriesClaimMintToArgs = {
  data: SeriesMintClaimInput;
  mintTo: Scalars["String"];
  vectorId: Scalars["String"];
};

export type MutationSetColorSchemeArgs = {
  colorScheme: Scalars["String"];
};

export type MutationSponsoredClaimMintArgs = {
  data: SponsoredMintClaimInput;
  vectorId: Scalars["String"];
};

export type MutationToggleArchiveArgs = {
  collectionId: Scalars["String"];
};

export type MutationToggleDraftArchiveArgs = {
  draftId: Scalars["String"];
};

export type MutationUpdateAccountSettingsArgs = {
  input: UpdateAccountSettings;
};

export type MutationUpdateCollectionDraftArgs = {
  data: UpdateCollectionDraftInput;
  draftId: Scalars["String"];
};

export type MutationUpdateCollectionMetadataArgs = {
  collectionId: Scalars["String"];
  data: CollectionMetadataUpdate;
};

export type MutationUpdateCommentReactionArgs = {
  commentId: Scalars["String"];
  data: UpdatePostReactionInput;
};

export type MutationUpdateContractArgs = {
  contractId: Scalars["String"];
  data: UpdateContractInput;
};

export type MutationUpdateCreatedCommunityThemeArgs = {
  communityId: Scalars["String"];
  theme: InputWebsiteTheme;
};

export type MutationUpdateCurrentUserEmailArgs = {
  email: Scalars["String"];
};

export type MutationUpdateDiscordInfoArgs = {
  data: UpdateDiscordInfoInput;
  id: Scalars["String"];
};

export type MutationUpdateEmailPreferenceArgs = {
  emailPreference: UpdateEmailPreferenceInput;
};

export type MutationUpdateEvm721AuctionTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvm721BidTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvm721MintTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvm721RankedAuctionBidTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvm721RankedAuctionBidTxConsumerArgs = {
  chainId: Scalars["Float"];
  metadata: Evm721BidMetadataInput;
  txHash: Scalars["String"];
};

export type MutationUpdateEvm721RankedAuctionClaimAuctionEarningsTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmApproveTransferTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmApproveTransferWrappedTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmCreateMintVectorTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmCrosschainBurnTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmCurrencyAllowanceTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmDeployTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmDepositFundsTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmMintCreatorReservesTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmSaleTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmSetBaseUriTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmSponsorMintTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmTransferTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmUpdateCollectionTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateEvmUpdateRoyaltyTxArgs = {
  txHash: Scalars["String"];
};

export type MutationUpdateGeneralInfoArgs = {
  data: UpdateGeneralInfoInput;
  id: Scalars["String"];
};

export type MutationUpdateMintVectorArgs = {
  data: UpdateMintVectorInput;
};

export type MutationUpdateOfferArgs = {
  communityId: Scalars["String"];
  data: UpdateOfferInput;
  id: Scalars["String"];
};

export type MutationUpdatePaymentRecipientOnPrivateSalesArgs = {
  collectionId: Scalars["String"];
  paymentRecipient: Scalars["String"];
};

export type MutationUpdatePhoneNumberArgs = {
  phone: UpdatePhoneNumberInput;
};

export type MutationUpdatePostContentArgs = {
  data: UpdatePostContentInput;
  id: Scalars["String"];
};

export type MutationUpdatePostPinArgs = {
  data: UpdatePostPinInput;
  id: Scalars["String"];
};

export type MutationUpdatePostReactionArgs = {
  data: UpdatePostReactionInput;
  id: Scalars["String"];
};

export type MutationUpdateProfileArgs = {
  profile: UpdateProfileInput;
};

export type MutationUpdateShopifyCollectionBuyButtonArgs = {
  communityId: Scalars["String"];
  input: UpdateCollectionBuyButtonInput;
};

export type MutationUpdateUsernameArgs = {
  username: Scalars["String"];
};

export type MutationUploadCommunityBannerImageArgs = {
  asset: Scalars["Upload"];
  id: Scalars["String"];
  mime: Scalars["String"];
};

export type MutationUploadCommunityLogoImageArgs = {
  asset: Scalars["Upload"];
  id: Scalars["String"];
  mime: Scalars["String"];
};

export type MutationUploadCreatorAvatarImageArgs = {
  asset: Scalars["Upload"];
  id: Scalars["String"];
  mime: Scalars["String"];
};

export type MutationUploadProfileImageArgs = {
  asset: Scalars["Upload"];
  mime: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  verifyCode: Scalars["String"];
};

export type MutationVerifyPhoneNumberArgs = {
  verifyPhone: VerifyPhoneNumberInput;
};

export type MutationVerifyTwitterConnectionArgs = {
  code: Scalars["String"];
  state: Scalars["String"];
};

export type MutationWithdrawCreatorRoyaltiesArgs = {
  communityId: Scalars["String"];
  currency: Scalars["String"];
};

export type NftMetadata = {
  attributes?: Maybe<Array<TokenAttribute>>;
  backgroundColor?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  externalUrl?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  standard?: Maybe<Scalars["String"]>;
  tokenId: Scalars["String"];
  tokenUri?: Maybe<Scalars["String"]>;
};

/** NFT Standard Type */
export enum NftStandardType {
  ERC721 = "ERC721",
  ERC1155 = "ERC1155",
}

/** Blockchain Network Types */
export enum NetworkType {
  ARBITRUM = "ARBITRUM",
  ARBITRUM_GOERLI = "ARBITRUM_GOERLI",
  ARBITRUM_RINKEBY = "ARBITRUM_RINKEBY",
  BASE = "BASE",
  BASE_GOERLI = "BASE_GOERLI",
  BASE_SEPOLIA = "BASE_SEPOLIA",
  ETHEREUM = "ETHEREUM",
  FORMA = "FORMA",
  FOUNDRY = "FOUNDRY",
  GOERLI = "GOERLI",
  HARDHAT = "HARDHAT",
  KOVAN = "KOVAN",
  LOCALHOST = "LOCALHOST",
  MANTLE = "MANTLE",
  MUMBAI = "MUMBAI",
  OPTIMISM = "OPTIMISM",
  OPTIMISM_GOERLI = "OPTIMISM_GOERLI",
  OPTIMISM_KOVAN = "OPTIMISM_KOVAN",
  POLYGON = "POLYGON",
  RINKEBY = "RINKEBY",
  ROPSTEN = "ROPSTEN",
  SCROLL = "SCROLL",
  SEPOLIA = "SEPOLIA",
  ZORA = "ZORA",
  ZORA_GOERLI = "ZORA_GOERLI",
}

export type NftAttribute = {
  traitType: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export type NftAvatar = {
  collectionId: Scalars["String"];
  id: Scalars["String"];
  url: Scalars["String"];
};

export type NftCollected = {
  address: Scalars["String"];
  animationUrl?: Maybe<Scalars["String"]>;
  collectionId?: Maybe<Scalars["String"]>;
  collectionName?: Maybe<Scalars["String"]>;
  collectionType?: Maybe<_CollectionType>;
  creator?: Maybe<PublicAccountSettings>;
  creatorId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
};

export type NftOnchainMetadata = {
  imageOriginalUrl?: Maybe<Scalars["String"]>;
  imageOriginalUrlHttp?: Maybe<Scalars["String"]>;
};

export type NftsCollected = {
  items: Array<NftCollected>;
  pageInfo: CursorPaginatedInfo;
};

export type NonTransferableSettings = {
  active: Scalars["Boolean"];
};

export type NonTransferableSettingsInput = {
  active: Scalars["Boolean"];
};

export type NotificationAuth = {
  token: Scalars["String"];
};

/** Notification Status */
export enum NotificationStatus {
  FAILURE = "FAILURE",
  SUCCESS = "SUCCESS",
}

export type Offer = {
  active: Scalars["Boolean"];
  communityId: Scalars["String"];
  /** Used for crypto offers only. */
  crypto?: Maybe<CryptoCurrency>;
  currency: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  onAboutPage?: Maybe<Scalars["Boolean"]>;
  onPartyPage?: Maybe<Scalars["Boolean"]>;
  onPrivatePage?: Maybe<Scalars["Boolean"]>;
  price: Scalars["String"];
  /**
   * Field resolver not implemented yet.
   * @deprecated Field for backward compatibility only.
   */
  token?: Maybe<Token>;
  /** @deprecated Field for backward compatibility only. */
  tokenId?: Maybe<Scalars["Float"]>;
  tokenIds: Array<Scalars["Float"]>;
  /** Field resolver not implemented yet. */
  tokens?: Maybe<Array<Token>>;
};

export type OnChainDutchAuctionCreateInput = {
  end?: Maybe<Scalars["String"]>;
  maxPerTxn: Scalars["Int"];
  maxPerUser: Scalars["Int"];
  maxPerVector: Scalars["Int"];
  paymentRecipient: Scalars["String"];
  priceDropInterval: Array<PriceDropTimePeriodInput>;
  scheduledPrices: Array<Scalars["String"]>;
  start: Scalars["String"];
};

export type OnChainDutchAuctionUpdateInput = {
  end?: Maybe<Scalars["String"]>;
  maxPerTxn?: Maybe<Scalars["Int"]>;
  maxPerUser?: Maybe<Scalars["Int"]>;
  maxPerVector?: Maybe<Scalars["Int"]>;
  paymentRecipient?: Maybe<Scalars["String"]>;
  priceDropInterval?: Maybe<Array<PriceDropTimePeriodInput>>;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  start?: Maybe<Scalars["String"]>;
};

export type OnChainMintVectorCreateInput = {
  currency: Scalars["String"];
  end?: Maybe<Scalars["String"]>;
  maxPerTxn: Scalars["Int"];
  maxPerUser: Scalars["Int"];
  maxPerVector: Scalars["Int"];
  paymentRecipient: Scalars["String"];
  price: Scalars["String"];
  start: Scalars["String"];
};

export type OnChainMintVectorUpdateInput = {
  currency?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  maxPerTxn?: Maybe<Scalars["Int"]>;
  maxPerUser?: Maybe<Scalars["Int"]>;
  maxPerVector?: Maybe<Scalars["Int"]>;
  paused?: Maybe<Scalars["Boolean"]>;
  paymentRecipient?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
};

export type OnChainRankedAuctionUpdateInput = {
  currency?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  maxPerUser?: Maybe<Scalars["Int"]>;
  maxPerVector?: Maybe<Scalars["Int"]>;
  paymentRecipient?: Maybe<Scalars["String"]>;
  reserveBid?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
};

export type Onboarding = {
  guide: Array<HighlightGuide>;
  id: Scalars["String"];
  tips: Array<HighlightTip>;
};

export type OnboardingLink = {
  created_at: Scalars["Float"];
  expires_at: Scalars["Float"];
  object: Scalars["String"];
  url: Scalars["String"];
};

export type OnchainDutchAuctionStats = {
  currentPrice: Scalars["String"];
  earnedAmountIsValid: Scalars["Boolean"];
  exhausted: Scalars["Boolean"];
  fundsToClaim: Scalars["String"];
  inFPP: Scalars["Boolean"];
  revenueWithdrawnFromEscrow: Scalars["Boolean"];
};

export type OnchainVectorDataMeta = {
  mechanicContractName?: Maybe<Scalars["String"]>;
  mintFunctionName?: Maybe<Scalars["String"]>;
  mintingContractAddress?: Maybe<Scalars["String"]>;
  onchainId: Scalars["String"];
  onchainIdEvmType: EvmOnChainIdType;
  perUserVectorDataFunctionName: Scalars["String"];
  vectorDataContractName?: Maybe<Scalars["String"]>;
  vectorDataFunctionName?: Maybe<Scalars["String"]>;
};

export type OrderAmount = {
  decimal: Scalars["String"];
  native: Scalars["String"];
  raw: Scalars["String"];
  usd: Scalars["String"];
};

export type OrderCollection = {
  address: Scalars["String"];
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  marketplaceId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  onchainId?: Maybe<Scalars["String"]>;
  status?: Maybe<CollectionStatus>;
};

export type OrderCurrency = {
  contract: Scalars["String"];
  decimals: Scalars["Float"];
  name: Scalars["String"];
  symbol: Scalars["String"];
};

export type OrderFee = {
  bps: Scalars["Float"];
  kind: Scalars["String"];
  recipient: Scalars["String"];
};

export type OrderPrice = {
  amount: OrderAmount;
  currency: OrderCurrency;
  netAmount: OrderAmount;
};

/** Sorting options for marketplace orders (listings and offers) */
export enum OrderSortOptions {
  DATE_CREATED = "DATE_CREATED",
  EXPIRING = "EXPIRING",
  HIGHEST_ABOVE_FLOOR = "HIGHEST_ABOVE_FLOOR",
  PRICE = "PRICE",
}

export type OrderToken = {
  animationUrl?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  imageUrl: Scalars["String"];
  name: Scalars["String"];
  owner?: Maybe<Scalars["String"]>;
  tokenRevealId?: Maybe<Scalars["String"]>;
};

export type OrderUser = {
  displayName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  walletAddresses: Array<Scalars["String"]>;
};

export type Page = {
  created: Scalars["String"];
  gate?: Maybe<Gate>;
  gateId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  sections: Array<PageSection>;
  slug: Scalars["String"];
  status: PageStatus;
  templateId?: Maybe<Scalars["String"]>;
  theme?: Maybe<PageTheme>;
};

export type PageAttachment = {
  id: Scalars["String"];
  metadata?: Maybe<PageMediaMetadata>;
  type: PageAttachmentType;
};

/** Type of Page Attachment. */
export enum PageAttachmentType {
  MEDIA = "MEDIA",
}

export type PageContent = {
  editorContent?: Maybe<EditorContent>;
  mintContent?: Maybe<MintContent>;
};

export type PageContentInput = {
  editorContent?: Maybe<EditorContentInput>;
  mintContent?: Maybe<MintContentInput>;
};

export type PageCreateInput = {
  gateId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  sections: Array<PageSectionInput>;
  status?: Maybe<PageStatus>;
  templateId?: Maybe<Scalars["String"]>;
  theme?: Maybe<PageThemeInput>;
};

export type PageDeleteResult = {
  deletedCount: Scalars["Int"];
};

export type PageMediaMetadata = {
  mime: PageSupportedMediaMime;
  publicUrl?: Maybe<Scalars["String"]>;
  type: PageSupportedMediaType;
  url?: Maybe<Scalars["String"]>;
};

export type PagePaginatedInfo = {
  page: Scalars["Int"];
  totalCount: Scalars["Int"];
};

export type PagePaginatedInfoV3 = {
  hasNext: Scalars["Boolean"];
  page: Scalars["Int"];
};

export type PageSection = {
  content: PageContent;
  gateId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  type: SectionType;
};

export type PageSectionInput = {
  content: PageContentInput;
  gateId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  type: SectionType;
};

/** Page status */
export enum PageStatus {
  Draft = "Draft",
  Public = "Public",
}

/** Supported Page Media Mime(s) */
export enum PageSupportedMediaMime {
  GIF = "GIF",
  JPEG = "JPEG",
  MP3 = "MP3",
  MP4 = "MP4",
  MPEG = "MPEG",
  PNG = "PNG",
  WAV = "WAV",
  WEBM = "WEBM",
  WEBP = "WEBP",
}

/** Page Supported Media Types. */
export enum PageSupportedMediaType {
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export type PageTheme = {
  background: PageThemeBackground;
  corners: PageThemeCorners;
  text: PageThemeText;
  title: PageThemeTitle;
};

export type PageThemeBackground = {
  bottomColor?: Maybe<Scalars["String"]>;
  color: Scalars["String"];
  type: BackgroundType;
};

export type PageThemeBackgroundInput = {
  bottomColor?: Maybe<Scalars["String"]>;
  color: Scalars["String"];
  type: BackgroundType;
};

export type PageThemeCorners = {
  shape: CornersShape;
};

export type PageThemeCornersInput = {
  shape: CornersShape;
};

export type PageThemeInput = {
  background: PageThemeBackgroundInput;
  corners: PageThemeCornersInput;
  text: PageThemeTextInput;
  title: PageThemeTitleInput;
};

export type PageThemeText = {
  color: Scalars["String"];
  fontFamily: Scalars["String"];
};

export type PageThemeTextInput = {
  color: Scalars["String"];
  fontFamily: Scalars["String"];
};

export type PageThemeTitle = {
  fontFamily: Scalars["String"];
};

export type PageThemeTitleInput = {
  fontFamily: Scalars["String"];
};

export type PageUpdateInput = {
  gateId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  sections?: Maybe<Array<PageSectionInput>>;
  status?: Maybe<PageStatus>;
  templateId?: Maybe<Scalars["String"]>;
  theme?: Maybe<PageThemeInput>;
};

export type PendingWithdrawal = {
  currency: Scalars["String"];
  taskId: Scalars["String"];
};

export type PersonalInformation = {
  email?: Maybe<Scalars["String"]>;
  phone?: Maybe<PhoneNumber>;
  /** @deprecated Replaced by phone field. */
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type PhoneNumber = {
  isVerified: Scalars["Boolean"];
  number: Scalars["String"];
};

export type Portfolio = {
  accountId: Scalars["String"];
  artistName?: Maybe<Scalars["String"]>;
  artistTwitter?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  curator?: Maybe<Scalars["String"]>;
  curatorSlug?: Maybe<Scalars["String"]>;
  ensName?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  items: PortfolioItemsPaginated;
  ownerAddress?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  status: PortfolioStatus;
  title?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  unclaimed?: Maybe<Scalars["Boolean"]>;
};

export type PortfolioItemsArgs = {
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
};

export type PortfolioDeleteResult = {
  deletedCount: Scalars["Int"];
};

export type PortfolioItem = {
  button?: Maybe<PortfolioItemButton>;
  collectionId?: Maybe<Scalars["String"]>;
  collectionInfo: CollectionInfo;
  collectionName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  imageUrl: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  openSeaLink: Scalars["Boolean"];
  videoUrl?: Maybe<Scalars["String"]>;
};

export type PortfolioItemButton = {
  text?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type PortfolioItemButtonInput = {
  text?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type PortfolioItemsPaginated = {
  edges: Array<PortfolioItem>;
  pageInfo: CursorPaginatedInfo;
};

/** Portfolio Statuses */
export enum PortfolioStatus {
  DRAFT = "DRAFT",
  LIVE = "LIVE",
}

export type PortfoliosPaginated = {
  edges: Array<Portfolio>;
  pageInfo: CursorPaginatedInfo;
};

export type Post = {
  attachment?: Maybe<Attachment>;
  attachments?: Maybe<Array<Attachment>>;
  id: Scalars["String"];
  pinned: Scalars["Boolean"];
  reactions?: Maybe<Array<BasePostReaction>>;
  text?: Maybe<Scalars["String"]>;
};

export type PriceDrop = {
  active: Scalars["Boolean"];
  end: Scalars["String"];
  price: Scalars["String"];
};

export type PriceDropTimePeriod = {
  unit: TimeUnit;
  value: Scalars["Float"];
};

export type PriceDropTimePeriodInput = {
  unit: TimeUnit;
  value: Scalars["Float"];
};

/** Price type of the collection */
export enum PriceType {
  DutchAuction = "DutchAuction",
  EnglishAuction = "EnglishAuction",
  Fixed = "Fixed",
  RankedAuction = "RankedAuction",
}

export type Profile = {
  aboutMe?: Maybe<Scalars["String"]>;
  profileImageUrl?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

export type PublicAccount = {
  ensName?: Maybe<Scalars["String"]>;
  evmAddresses: Array<Scalars["String"]>;
  id: Scalars["String"];
};

export type PublicAccountSettings = {
  accountId: Scalars["String"];
  avatarUrl?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  displayAvatar?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  ensNames?: Maybe<Array<Scalars["String"]>>;
  id?: Maybe<Scalars["String"]>;
  imported?: Maybe<Scalars["Boolean"]>;
  nftAvatar?: Maybe<NftAvatar>;
  socials?: Maybe<PublicLinkedSocials>;
  useNftAvatar?: Maybe<Scalars["Boolean"]>;
  verified?: Maybe<Scalars["Boolean"]>;
  wallet: AccountWallet;
  walletAddresses?: Maybe<Array<Scalars["String"]>>;
  website?: Maybe<Scalars["String"]>;
};

export type PublicLinkedEmail = {
  address: Scalars["String"];
};

export type PublicLinkedSocials = {
  email?: Maybe<PublicLinkedEmail>;
  twitter?: Maybe<PublicLinkedTwitter>;
};

export type PublicLinkedTwitter = {
  username: Scalars["String"];
};

export type PublicPage = {
  creator?: Maybe<PublicAccount>;
  creatorId: Scalars["String"];
  gate?: Maybe<Gate>;
  gateId?: Maybe<Scalars["String"]>;
  isLocked: Scalars["Boolean"];
  lockedMessage?: Maybe<Scalars["String"]>;
  page?: Maybe<Page>;
  theme?: Maybe<PageTheme>;
  unlockGateResult?: Maybe<UnlockGateResult>;
};

export type Query = {
  anonymous: Anonymous;
  approveCurrencyTxArgs: TransactionArgs;
  approveNFTBurnTxArgs: TransactionArgs;
  auth: NotificationAuth;
  checkBurnNFTAllowanceTxArgs: TransactionArgs;
  checkCurrencyAllowanceTxArgs: TransactionArgs;
  checkGateAccess: UnlockGateResult;
  checkoutInitiated: CheckoutInitiateResponse;
  claimStatusApp: GaslessClaimStatusAppResponse;
  collectionAttributes: CollectionAttributes;
  collectionDetails: CollectionDetails;
  collectionDraft: CollectionDraft;
  collectionDrafts: Array<CollectionDraft>;
  collectionNft: CollectionNft;
  collectionNfts: Array<CollectionNft>;
  collectionNftsPaginated: CollectionNftsPaginated;
  /** Find a community by either id or slug. If no arguments are passed, an error is thrown. If both arguments are used, 'id' has precedence over 'slug'. */
  community?: Maybe<Community>;
  /** Get feed for a community by slug. */
  contentFeed?: Maybe<ContentFeed>;
  contest: Contest;
  contestEntries: Array<ContestEntry>;
  contestEntry: ContestEntry;
  contests: Array<Contest>;
  contract?: Maybe<GateContract>;
  contractAttributes: ContractAttributes;
  contractDetails: ContractDetails;
  contractNft: ContractNft;
  contractNfts: Array<ContractNft>;
  contractNftsPaginated: ContractNftsPaginated;
  conversion?: Maybe<CurrencyConversion>;
  /** Find a community from a creator by slug. */
  createdCommunityBySlug?: Maybe<Community>;
  /** Get creator community members. */
  creatorCommunityMembers: CreatorCommunityMembers;
  creatorReservesTxArgs: TransactionArgs;
  crossmintVerificationStatus: CrossmintVerificationStatus;
  cryptoOrderDraft: CryptoOrderDraft;
  currencyDetails: CurrencyDetails;
  currentUser: User;
  deployCollectionParams: DeployDraftOutput;
  dutchAuctionClaimFundsTxArgs: TransactionArgs;
  dutchAuctionMechanicVectorCreateTxArgs: TransactionArgs;
  dutchAuctionRebateTxArgs: TransactionArgs;
  dutchAuctionUpdatePauseTxArgs: TransactionArgs;
  dutchAuctionUpdateTxArgs: TransactionArgs;
  exploreMints: ExploreMints;
  farcasterUser?: Maybe<FarcasterUser>;
  farcasterUserSearch?: Maybe<FarcasterUser>;
  gate: Gate;
  gateToken?: Maybe<GateToken>;
  gatedEntities: Array<IGatedEntity>;
  gates: Array<Gate>;
  generateDashboardLink: DashboardLink;
  generateOnboardingLink: OnboardingLink;
  getAccountSettings: AccountSettings;
  getAllComments: Array<Comment>;
  getAssociatedCollectionByAddress: CollectionByAddress;
  getAuctionsByCollection: Array<Auction>;
  getAudienceMember: AudienceMemberTokensV2;
  getAudienceOfCollection: AudienceByCollection;
  getAudienceOfCreator: Audience;
  getAudienceOfCreatorV2: AudienceV2;
  /** @deprecated Use separate queries for collections and imported tokens */
  getBlockchainEntitiesByUser: BlockchainEntities;
  getCategories: Array<Category>;
  getCollectedByWalletAddress: NftsCollected;
  getCollection: Collection;
  getCollectionAttributes: Array<MarketplaceCollectionAttribute>;
  getCollectionByOnChainId: CollectionByOnChainId;
  getCollectionByUser: Array<Collection>;
  getCollectionByUserPaginated: CollectionsPaginated;
  getCollectionTokens: MarketplaceTokensPaginated;
  getCollectionTokensV2: MarketplaceTokensPaginatedV2;
  getCollectionsMarketplaceStats: Array<CollectionMarketplaceStats>;
  getCollectionsMarketplaceStatsV2: Array<CollectionMarketplaceStatsV2>;
  getContractById: Contract2;
  getContractsByUser: Array<Contract2>;
  getContractsForCreateCollection: CreateCollectionContractsPaginated;
  getCreatorRewardsByCollection: CreatorRewardsByCollection;
  getDashboardCollections: CollectionsDashboardPaginated;
  getDashboardContracts: ContractsDashboardPaginated;
  getDiscordLinkUrl: GetDiscordLinkUrlResponse;
  getGaslessTransactionStatus: GaslessTransactionStatus;
  getImportedCollectionByUser: Array<Collection>;
  getImportedToken: ImportedToken;
  getLiveFeed: Array<LiveFeed>;
  getManageCollection: ManageCollection;
  getManageContract: ManageContract;
  getMergedCollectionByUserPaginated: MergedCollectionsPaginated;
  getMintPageDetails: MintPage;
  getMintSponsor: Sponsor;
  getMintVector: MintVector;
  getMintVectorsByCollection: Array<MintVector>;
  getNftAvatarCollection?: Maybe<AvatarCollection>;
  getOnboardingConfig: Onboarding;
  getOwnerOffers: MarketplaceOffersPaginated;
  getPendingEvmTransactions: Array<EvmTx>;
  getPortfolioById?: Maybe<Portfolio>;
  getPortfolioItemMetadata?: Maybe<NftOnchainMetadata>;
  getPublicAccountSettings: PublicAccountSettings;
  getPublicCollection: Collection;
  getPublicCollectionDetails: Collection;
  getPublicCollectionsByWallet: CollectionsPaginated;
  getRankedSponsorsByMintVector: RankedSponsors;
  getRecentEvmTransactions: Array<EvmTx>;
  getRelatedCollections: CollectionsPaginated;
  getSecretMessage: Scalars["String"];
  getSponsoRankingByAmount: Scalars["Int"];
  getSupportedChains: Array<Chain>;
  getSupportedSyncChains: Array<ReservoirChain>;
  getTags: FrequentTags;
  getToken: Token;
  getTokenOffers: MarketplaceOffersPaginated;
  getTokensByOnChainIds: Array<MarketplaceToken>;
  getTokensStats: Array<MarketplaceTokenStats>;
  getUserListings: MarketplaceListingsWithStatsPaginated;
  getUserOffers: MarketplaceOffersPaginated;
  getUserTokens: MarketplaceTokensPaginated;
  globalSearch: GlobalSearchResults;
  identity: Identity;
  importedTokensPaginated: ImportedTokensPaginated;
  isSeriesUnzipDone: IsSeriesUnzipped;
  mintVectorCreateTxArgs: TransactionArgs;
  mintVectorUpdateTxArgs: TransactionArgs;
  offer: Offer;
  page: Page;
  pageView?: Maybe<PublicPage>;
  pages: Array<Page>;
  pendingWithdrawals: Array<PendingWithdrawal>;
  publicMintVector?: Maybe<MintVector>;
  publicPortfolio?: Maybe<Portfolio>;
  publicPortfolios: PortfoliosPaginated;
  rankedAuction: RankedAuction;
  rankedAuctionBidTxArgs: TransactionArgs;
  rankedAuctionClaimAuctionEarningsTxArgs: TransactionArgs;
  rankedAuctionReclaimBidForClaimerTxArgs: TransactionArgs;
  rankedAuctionReclaimBidTxArgs: TransactionArgs;
  rankedAuctionUpdatePauseTxArgs: TransactionArgs;
  rankedAuctionUpdateTxArgs: TransactionArgs;
  releaseParty: ReleaseParty;
  sponsorMintTxArgs: TransactionArgs;
  startConnectWithNonce: IdentitiesNonce;
  startCreateUserWithSignedMessage: Challenge;
  startLoginWithSignedMessage: Challenge;
  startMagicLinkFlow: AllowedMethods;
  tokenMetadata?: Maybe<NftMetadata>;
  trendingArtists: TrendingArtists;
  trendingMints: Trending;
  usdPriceRate?: Maybe<Scalars["Float"]>;
  userPortfolio?: Maybe<Portfolio>;
  userPortfolios?: Maybe<Array<Portfolio>>;
  walletNftsPaginated: WalletNftsPaginated;
};

export type QueryApproveCurrencyTxArgsArgs = {
  chainId: Scalars["Float"];
  currency: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type QueryApproveNftBurnTxArgsArgs = {
  chainId: Scalars["Float"];
  contractAddress: Scalars["String"];
};

export type QueryCheckBurnNftAllowanceTxArgsArgs = {
  chainId: Scalars["Float"];
  contractAddress: Scalars["String"];
};

export type QueryCheckCurrencyAllowanceTxArgsArgs = {
  chainId: Scalars["Float"];
  currency: Scalars["String"];
};

export type QueryCheckGateAccessArgs = {
  fullResult: Scalars["Boolean"];
  gateId: Scalars["String"];
};

export type QueryCheckoutInitiatedArgs = {
  items: Array<CheckoutItemsInput>;
  sessionKey: Scalars["String"];
};

export type QueryClaimStatusAppArgs = {
  claimId: Scalars["String"];
};

export type QueryCollectionAttributesArgs = {
  slug: Scalars["String"];
};

export type QueryCollectionDetailsArgs = {
  slug: Scalars["String"];
};

export type QueryCollectionDraftArgs = {
  id: Scalars["String"];
};

export type QueryCollectionNftArgs = {
  id: Scalars["String"];
  slug: Scalars["String"];
};

export type QueryCollectionNftsArgs = {
  slug: Scalars["String"];
};

export type QueryCollectionNftsPaginatedArgs = {
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  slug: Scalars["String"];
};

export type QueryCommunityArgs = {
  id?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type QueryContentFeedArgs = {
  communitySlug?: Maybe<Scalars["String"]>;
};

export type QueryContestArgs = {
  id: Scalars["String"];
};

export type QueryContestEntriesArgs = {
  contestId: Scalars["String"];
};

export type QueryContestEntryArgs = {
  contestId: Scalars["String"];
  id: Scalars["String"];
};

export type QueryContractArgs = {
  contractAddress: Scalars["String"];
  platform: Scalars["String"];
};

export type QueryContractAttributesArgs = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type QueryContractDetailsArgs = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type QueryContractNftArgs = {
  address: Scalars["String"];
  id: Scalars["String"];
  network: Scalars["String"];
};

export type QueryContractNftsArgs = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type QueryContractNftsPaginatedArgs = {
  address: Scalars["String"];
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  network: Scalars["String"];
};

export type QueryConversionArgs = {
  from: Scalars["String"];
  to: Scalars["String"];
};

export type QueryCreatedCommunityBySlugArgs = {
  slug: Scalars["String"];
};

export type QueryCreatorCommunityMembersArgs = {
  communityId: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  sort: CreatorCommunityMembersSort;
};

export type QueryCreatorReservesTxArgsArgs = {
  collectionId: Scalars["String"];
  data: CreatorReservesInput;
};

export type QueryCrossmintVerificationStatusArgs = {
  cmCollectionId: Scalars["String"];
  isTestnet: Scalars["Boolean"];
};

export type QueryCryptoOrderDraftArgs = {
  offerId: Scalars["String"];
};

export type QueryCurrencyDetailsArgs = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type QueryDeployCollectionParamsArgs = {
  draftId: Scalars["String"];
};

export type QueryDutchAuctionClaimFundsTxArgsArgs = {
  vectorId: Scalars["String"];
};

export type QueryDutchAuctionMechanicVectorCreateTxArgsArgs = {
  collectionId: Scalars["String"];
  data: OnChainDutchAuctionCreateInput;
};

export type QueryDutchAuctionRebateTxArgsArgs = {
  vectorId: Scalars["String"];
};

export type QueryDutchAuctionUpdatePauseTxArgsArgs = {
  mintVectorId: Scalars["String"];
  paused: Scalars["Boolean"];
};

export type QueryDutchAuctionUpdateTxArgsArgs = {
  data: OnChainDutchAuctionUpdateInput;
  mintVectorId: Scalars["String"];
};

export type QueryExploreMintsArgs = {
  after?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Scalars["String"]>>;
  category: ExploreCategory;
  communityToken?: Maybe<Scalars["String"]>;
  fid?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  mainnetOnly?: Maybe<Scalars["Boolean"]>;
  periodHours?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
};

export type QueryFarcasterUserArgs = {
  fid: Scalars["Int"];
};

export type QueryFarcasterUserSearchArgs = {
  username: Scalars["String"];
};

export type QueryGateArgs = {
  id: Scalars["String"];
};

export type QueryGateTokenArgs = {
  conditionType?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  platform: Scalars["String"];
  tokenId?: Maybe<Scalars["String"]>;
};

export type QueryGatedEntitiesArgs = {
  gateId: Scalars["String"];
};

export type QueryGenerateOnboardingLinkArgs = {
  communityId: Scalars["String"];
};

export type QueryGetAllCommentsArgs = {
  releasePartyId: Scalars["String"];
};

export type QueryGetAssociatedCollectionByAddressArgs = {
  contractAddress: Scalars["String"];
};

export type QueryGetAuctionsByCollectionArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetAudienceMemberArgs = {
  limit: Scalars["Float"];
  member: Scalars["String"];
  page: Scalars["Float"];
};

export type QueryGetAudienceOfCollectionArgs = {
  collectionId: Scalars["String"];
  cursor: Scalars["Float"];
  findEnsNames?: Maybe<Scalars["Boolean"]>;
  limit: Scalars["Float"];
};

export type QueryGetAudienceOfCreatorV2Args = {
  limit: Scalars["Float"];
  page: Scalars["Float"];
  sort?: Maybe<Scalars["String"]>;
};

export type QueryGetCategoriesArgs = {
  selectable?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetCollectedByWalletAddressArgs = {
  cursor?: Maybe<Scalars["String"]>;
  walletAddress: Scalars["String"];
};

export type QueryGetCollectionArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetCollectionAttributesArgs = {
  chainId: Scalars["Float"];
  marketplaceId?: Maybe<Scalars["String"]>;
  minted?: Maybe<Scalars["Boolean"]>;
  tokenAddress: Scalars["String"];
  tokenNameOrId?: Maybe<Scalars["String"]>;
  withListings?: Maybe<Scalars["Boolean"]>;
  withOffers?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetCollectionByOnChainIdArgs = {
  onChainId: Scalars["String"];
};

export type QueryGetCollectionByUserPaginatedArgs = {
  after?: Maybe<Scalars["String"]>;
  contractId?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  search?: Maybe<Scalars["String"]>;
  sortBy?: Maybe<CollectionSortableField>;
  sortDirection?: Maybe<CollectionSortDirection>;
};

export type QueryGetCollectionTokensArgs = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<MarketplaceCollectionAttributesFilter>>;
  chainId: Scalars["Float"];
  collectionMarketplaceId?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  minted?: Maybe<Scalars["Boolean"]>;
  sortBy?: Maybe<TokenSortOptions>;
  sortDirection?: Maybe<SortDirection>;
  tokenAddress: Scalars["String"];
  tokenNameOrId?: Maybe<Scalars["String"]>;
  withListings?: Maybe<Scalars["Boolean"]>;
  withOffers?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetCollectionTokensV2Args = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<MarketplaceCollectionAttributesFilter>>;
  chainId: Scalars["Float"];
  collectionMarketplaceId?: Maybe<Scalars["String"]>;
  editionId?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  isImportedCollection?: Maybe<Scalars["Boolean"]>;
  minted?: Maybe<Scalars["Boolean"]>;
  ownerAddress?: Maybe<Scalars["String"]>;
  sortBy?: Maybe<TokenSortOptions>;
  sortDirection?: Maybe<SortDirection>;
  tokenAddress: Scalars["String"];
  tokenNameOrId?: Maybe<Scalars["String"]>;
  withListings?: Maybe<Scalars["Boolean"]>;
  withOffers?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetCollectionsMarketplaceStatsArgs = {
  onChainIds: Array<Scalars["String"]>;
};

export type QueryGetCollectionsMarketplaceStatsV2Args = {
  collectionIds: Array<Scalars["String"]>;
};

export type QueryGetContractByIdArgs = {
  contractId: Scalars["String"];
};

export type QueryGetContractsForCreateCollectionArgs = {
  limit?: Maybe<Scalars["Float"]>;
  page?: Maybe<Scalars["Float"]>;
};

export type QueryGetDashboardCollectionsArgs = {
  filters?: Maybe<CollectionFilters>;
  limit?: Maybe<Scalars["Float"]>;
  page?: Maybe<Scalars["Float"]>;
  sortBy?: Maybe<CollectionSortableField>;
  sortDirection?: Maybe<CollectionSortDirection>;
};

export type QueryGetDashboardContractsArgs = {
  limit?: Maybe<Scalars["Float"]>;
  page?: Maybe<Scalars["Float"]>;
};

export type QueryGetGaslessTransactionStatusArgs = {
  chainId: Scalars["Float"];
  id: Scalars["String"];
  type: Scalars["String"];
};

export type QueryGetImportedTokenArgs = {
  tokenId: Scalars["String"];
};

export type QueryGetLiveFeedArgs = {
  limit?: Maybe<Scalars["Float"]>;
  mainnetOnly?: Maybe<Scalars["Boolean"]>;
  page?: Maybe<Scalars["Float"]>;
};

export type QueryGetManageCollectionArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetManageContractArgs = {
  contractId: Scalars["String"];
};

export type QueryGetMergedCollectionByUserPaginatedArgs = {
  filters?: Maybe<CollectionFilters>;
  limit?: Maybe<Scalars["Float"]>;
  page?: Maybe<Scalars["Float"]>;
  sortBy?: Maybe<CollectionSortableField>;
  sortDirection?: Maybe<CollectionSortDirection>;
};

export type QueryGetMintPageDetailsArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetMintSponsorArgs = {
  mintedOrderId: Scalars["String"];
  sponsoredVectorId: Scalars["String"];
};

export type QueryGetMintVectorArgs = {
  vectorId: Scalars["String"];
};

export type QueryGetMintVectorsByCollectionArgs = {
  cacheMaxAge?: Maybe<Scalars["Int"]>;
  collectionId: Scalars["String"];
  getSponsoredMints?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetOwnerOffersArgs = {
  aboveFloor?: Maybe<Scalars["Boolean"]>;
  after?: Maybe<Scalars["String"]>;
  chainId?: Maybe<Scalars["Float"]>;
  createdAfter?: Maybe<Scalars["DateTime"]>;
  first?: Maybe<Scalars["Float"]>;
  sortBy?: Maybe<OrderSortOptions>;
  sortDirection?: Maybe<SortDirection>;
  tokenAddress?: Maybe<Scalars["String"]>;
  tokenId?: Maybe<Scalars["String"]>;
  tokenOrCollectionName?: Maybe<Scalars["String"]>;
};

export type QueryGetPortfolioByIdArgs = {
  portfolioId: Scalars["String"];
};

export type QueryGetPortfolioItemMetadataArgs = {
  itemId: Scalars["String"];
  slug: Scalars["String"];
};

export type QueryGetPublicAccountSettingsArgs = {
  slug: Scalars["String"];
};

export type QueryGetPublicCollectionArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetPublicCollectionDetailsArgs = {
  collectionId: Scalars["String"];
};

export type QueryGetPublicCollectionsByWalletArgs = {
  cursor?: Maybe<Scalars["String"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  walletAddress: Scalars["String"];
};

export type QueryGetRankedSponsorsByMintVectorArgs = {
  limit: Scalars["Float"];
  mintVectorId: Scalars["String"];
  page: Scalars["Float"];
};

export type QueryGetRecentEvmTransactionsArgs = {
  hours: Scalars["Int"];
};

export type QueryGetRelatedCollectionsArgs = {
  after?: Maybe<Scalars["String"]>;
  collectionId: Scalars["String"];
  first?: Maybe<Scalars["Float"]>;
};

export type QueryGetSecretMessageArgs = {
  releasePartyId: Scalars["String"];
};

export type QueryGetSponsoRankingByAmountArgs = {
  amount: Scalars["Float"];
  mintVectorId: Scalars["String"];
};

export type QueryGetTagsArgs = {
  cursor?: Maybe<Scalars["String"]>;
  search?: Maybe<Scalars["String"]>;
};

export type QueryGetTokenArgs = {
  communityAddress: Scalars["String"];
  tokenId: Scalars["Int"];
};

export type QueryGetTokenOffersArgs = {
  aboveFloor?: Maybe<Scalars["Boolean"]>;
  after?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  createdAfter?: Maybe<Scalars["DateTime"]>;
  first?: Maybe<Scalars["Float"]>;
  isImportedCollection?: Maybe<Scalars["Boolean"]>;
  sortBy?: Maybe<OrderSortOptions>;
  sortDirection?: Maybe<SortDirection>;
  tokenAddress: Scalars["String"];
  tokenId: Scalars["String"];
};

export type QueryGetTokensByOnChainIdsArgs = {
  onChainIds: Array<Scalars["String"]>;
};

export type QueryGetTokensStatsArgs = {
  onChainIds: Array<Scalars["String"]>;
};

export type QueryGetUserListingsArgs = {
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  sortBy?: Maybe<OrderSortOptions>;
  sortDirection?: Maybe<SortDirection>;
  tokenOrCollectionName?: Maybe<Scalars["String"]>;
};

export type QueryGetUserOffersArgs = {
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  sortBy?: Maybe<OrderSortOptions>;
  sortDirection?: Maybe<SortDirection>;
  tokenOrCollectionName?: Maybe<Scalars["String"]>;
};

export type QueryGetUserTokensArgs = {
  after?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Array<MarketplaceCollectionAttributesFilter>>;
  first?: Maybe<Scalars["Float"]>;
  minted?: Maybe<Scalars["Boolean"]>;
  sortBy?: Maybe<TokenSortOptions>;
  sortDirection?: Maybe<SortDirection>;
  tokenNameOrId?: Maybe<Scalars["String"]>;
  walletAddress: Scalars["String"];
  withListings?: Maybe<Scalars["Boolean"]>;
  withOffers?: Maybe<Scalars["Boolean"]>;
};

export type QueryGlobalSearchArgs = {
  limit?: Maybe<Scalars["Int"]>;
  query: Scalars["String"];
};

export type QueryImportedTokensPaginatedArgs = {
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
  onlyCollections?: Maybe<Scalars["Boolean"]>;
  onlySingleTokens?: Maybe<Scalars["Boolean"]>;
};

export type QueryIsSeriesUnzipDoneArgs = {
  seriesZipFolderName: Scalars["String"];
};

export type QueryMintVectorCreateTxArgsArgs = {
  collectionId: Scalars["String"];
  data: OnChainMintVectorCreateInput;
};

export type QueryMintVectorUpdateTxArgsArgs = {
  data: OnChainMintVectorUpdateInput;
  mintVectorId: Scalars["String"];
};

export type QueryOfferArgs = {
  id: Scalars["String"];
  onPrivatePage?: Maybe<Scalars["Boolean"]>;
};

export type QueryPageArgs = {
  id: Scalars["String"];
};

export type QueryPageViewArgs = {
  slug: Scalars["String"];
};

export type QueryPendingWithdrawalsArgs = {
  communityId: Scalars["String"];
};

export type QueryPublicMintVectorArgs = {
  collectionId: Scalars["String"];
};

export type QueryPublicPortfolioArgs = {
  slug: Scalars["String"];
};

export type QueryPublicPortfoliosArgs = {
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
};

export type QueryRankedAuctionArgs = {
  mintVectorId: Scalars["String"];
};

export type QueryRankedAuctionBidTxArgsArgs = {
  input: RaBidInput;
  mintVectorId: Scalars["String"];
};

export type QueryRankedAuctionClaimAuctionEarningsTxArgsArgs = {
  mintVectorId: Scalars["String"];
};

export type QueryRankedAuctionReclaimBidForClaimerTxArgsArgs = {
  bidId: Scalars["String"];
  claimer: Scalars["String"];
  mintVectorId: Scalars["String"];
};

export type QueryRankedAuctionReclaimBidTxArgsArgs = {
  bidId: Scalars["String"];
  mintVectorId: Scalars["String"];
};

export type QueryRankedAuctionUpdatePauseTxArgsArgs = {
  mintVectorId: Scalars["String"];
  paused: Scalars["Boolean"];
};

export type QueryRankedAuctionUpdateTxArgsArgs = {
  data: OnChainRankedAuctionUpdateInput;
  mintVectorId: Scalars["String"];
};

export type QueryReleasePartyArgs = {
  communityId: Scalars["String"];
  slug: Scalars["String"];
};

export type QuerySponsorMintTxArgsArgs = {
  numToMint: Scalars["Float"];
  sponsorVectorId: Scalars["String"];
};

export type QueryStartCreateUserWithSignedMessageArgs = {
  publicAddress: Scalars["String"];
};

export type QueryStartLoginWithSignedMessageArgs = {
  publicAddress: Scalars["String"];
};

export type QueryStartMagicLinkFlowArgs = {
  email: Scalars["String"];
};

export type QueryTokenMetadataArgs = {
  contractAddress: Scalars["String"];
  platform: Scalars["String"];
  tokenId: Scalars["String"];
};

export type QueryTrendingArtistsArgs = {
  activeOnly?: Maybe<Scalars["Boolean"]>;
  after?: Maybe<Scalars["String"]>;
  chainId?: Maybe<Scalars["Float"]>;
  freeOnly?: Maybe<Scalars["Boolean"]>;
  ignoreArtistAdresses?: Maybe<Array<Scalars["String"]>>;
  limit?: Maybe<Scalars["Int"]>;
  mainnetOnly?: Maybe<Scalars["Boolean"]>;
  periodHours: Scalars["Int"];
};

export type QueryTrendingMintsArgs = {
  activeOnly?: Maybe<Scalars["Boolean"]>;
  after?: Maybe<Scalars["String"]>;
  chainId?: Maybe<Scalars["Float"]>;
  freeOnly?: Maybe<Scalars["Boolean"]>;
  ignoreCollectionIds?: Maybe<Array<Scalars["String"]>>;
  limit?: Maybe<Scalars["Float"]>;
  mainnetOnly?: Maybe<Scalars["Boolean"]>;
  periodHours: Scalars["Int"];
};

export type QueryUsdPriceRateArgs = {
  chainId: Scalars["Float"];
};

export type QueryWalletNftsPaginatedArgs = {
  address: Scalars["String"];
  after?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Float"]>;
};

export type RaBid = {
  bidAmount: Scalars["String"];
  bidderAddress: Scalars["String"];
  bidderEnsName?: Maybe<Scalars["String"]>;
  bidderProfile?: Maybe<BidderProfile>;
  currentlyValid: Scalars["Boolean"];
  id: Scalars["String"];
  rank: Scalars["Float"];
};

export type RaBidInput = {
  bidAmount: Scalars["String"];
  bidId?: Maybe<Scalars["String"]>;
  quantity: Scalars["Int"];
};

export type RaBidsPaginated = {
  edges: Array<RaBid>;
  pageInfo: CursorPaginatedInfo;
};

export type RankedAuction = {
  auctionEarningsClaimed?: Maybe<Scalars["Boolean"]>;
  bids: RaBidsPaginated;
  end: Scalars["String"];
  id: Scalars["String"];
  isFinished: Scalars["Boolean"];
  lowestBidRequired: Scalars["String"];
  lowestWinningBid: Scalars["String"];
  minBid: Scalars["String"];
  rebateWinningBids: Scalars["Boolean"];
  size: Scalars["Int"];
  start: Scalars["String"];
  userBids?: Maybe<Array<RaBid>>;
  userNumTokensLeftToClaim?: Maybe<Scalars["Int"]>;
};

export type RankedAuctionBidsArgs = {
  isWinning?: Maybe<Scalars["Boolean"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type RankedAuctionSettings = {
  rebateWinningBids?: Maybe<Scalars["Boolean"]>;
  reserveBidAmount?: Maybe<Scalars["String"]>;
};

export type RankedSponsors = {
  pageInfo: PagePaginatedInfo;
  sponsors: Array<Sponsor>;
};

export type ReleaseParty = {
  baseImageUrl: Scalars["String"];
  comments: Array<Comment>;
  community?: Maybe<Community>;
  communityId: Scalars["String"];
  description: Scalars["String"];
  embeddedContent: Scalars["String"];
  end: Scalars["String"];
  hasSecretMessage: Scalars["Boolean"];
  id: Scalars["String"];
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
  notificationId?: Maybe<Scalars["String"]>;
  offerIds: Array<Scalars["String"]>;
  offers: Array<Offer>;
  secretMessage: Scalars["String"];
  slug: Scalars["String"];
  start: Scalars["String"];
};

export type ReservoirChain = {
  id: Scalars["Float"];
  isTestnet: Scalars["Boolean"];
  name: Scalars["String"];
  proxyApi: Scalars["String"];
  reservoirBaseUrl: Scalars["String"];
  routePrefix: Scalars["String"];
  wssUrl?: Maybe<Scalars["String"]>;
};

export type Royalty = {
  allowToWithdraw: Scalars["Boolean"];
  balance: CryptoCurrency;
};

export type RoyaltyBreakdown = {
  bps: Scalars["Float"];
  recipient: Scalars["String"];
};

export type RoyaltySettings = {
  payoutEnsName?: Maybe<Scalars["String"]>;
  payoutEoa?: Maybe<Scalars["String"]>;
  royaltyAmount?: Maybe<Scalars["Float"]>;
};

export type RoyaltySettingsInput = {
  payoutEnsName?: Maybe<Scalars["String"]>;
  payoutEoa?: Maybe<Scalars["String"]>;
  royaltyAmount?: Maybe<Scalars["Float"]>;
};

/** Sale type of a mint vector */
export enum SaleType {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export type SearchFilters = {
  limit: Scalars["Int"];
  query: Scalars["String"];
};

export type SecondaryPriceAmount = {
  native: Scalars["String"];
  raw: Scalars["String"];
  usd: Scalars["String"];
};

export type SecondaryPriceCurrency = {
  contract: Scalars["String"];
  decimals: Scalars["Float"];
  name: Scalars["String"];
  symbol: Scalars["String"];
};

/** Section Types */
export enum SectionType {
  EditorData = "EditorData",
  MintButton = "MintButton",
}

export type SendToken = {
  availableTokenBalance: Scalars["Float"];
  communityId: Scalars["String"];
  communityMembersCount: Scalars["Float"];
  sent: Scalars["Boolean"];
  tokenId: Scalars["String"];
};

export type SeriesDetails = {
  coverImage: Scalars["Float"];
  featuredTokenId?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  mintPreviewImageUrls?: Maybe<Array<Scalars["String"]>>;
  mintPreviewImages: Array<Scalars["Float"]>;
  mintPreviewMediaUrls?: Maybe<Array<Scalars["String"]>>;
  mirroredBaseUri?: Maybe<Scalars["String"]>;
  showAllMetadata?: Maybe<Scalars["Boolean"]>;
};

export type SeriesDetailsDraft = {
  coverImage?: Maybe<Array<Scalars["Float"]>>;
  mintPreviewImageUrls?: Maybe<Array<Scalars["String"]>>;
  mintPreviewImages?: Maybe<Array<Scalars["Float"]>>;
  mintPreviewMediaUrls?: Maybe<Array<Scalars["String"]>>;
  mirroredBaseUri?: Maybe<Scalars["String"]>;
  showAllMetadata?: Maybe<Scalars["Boolean"]>;
};

export type SeriesDetailsInput = {
  coverImage?: Maybe<Array<Scalars["Float"]>>;
  mintPreviewImageUrls?: Maybe<Array<Scalars["String"]>>;
  mintPreviewImages?: Maybe<Array<Scalars["Float"]>>;
  mintPreviewMediaUrls?: Maybe<Array<Scalars["String"]>>;
  mirroredBaseUri?: Maybe<Scalars["String"]>;
  showAllMetadata?: Maybe<Scalars["Boolean"]>;
};

export type SeriesDetailsUpdateInput = {
  mintPreviewImageUrls?: Maybe<Array<Scalars["String"]>>;
};

export type SeriesImages = {
  coverImageUrl: Scalars["String"];
  urls: Array<Scalars["String"]>;
};

export type SeriesMintClaimInput = {
  referrer?: Maybe<Scalars["String"]>;
  tokenIds: Array<Scalars["Float"]>;
};

export type SeriesToken = {
  animationUrl?: Maybe<Scalars["String"]>;
  attributes: Array<SeriesTokenAttribute>;
  chainId?: Maybe<Scalars["Float"]>;
  collectionAddress?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** internal id, can be different from minted id on blockchain */
  id: Scalars["String"];
  /** Image URL of the token. It can be null if the token mint hasn't completed yet. */
  imageUrl?: Maybe<Scalars["String"]>;
  metadata?: Maybe<MarketplaceTokenMetadata>;
  minted?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  ownerAddress?: Maybe<Scalars["String"]>;
  ownerEns?: Maybe<Scalars["String"]>;
  secondaryPrice?: Maybe<TokenSecondaryPrice>;
  /** external, minted tokenId on blockchain */
  tokenId?: Maybe<Scalars["String"]>;
};

export type SeriesTokenAttribute = {
  name: Scalars["String"];
  value: Scalars["String"];
};

export type SeriesTokenListing = {
  chainId: Scalars["Float"];
  collectionMarketplaceId?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  createdAt: Scalars["String"];
  criteriaKind?: Maybe<Scalars["String"]>;
  expiredAt?: Maybe<Scalars["String"]>;
  feeBps: Scalars["Float"];
  fees: Array<OrderFee>;
  id: Scalars["String"];
  kind: Scalars["String"];
  maker: Scalars["String"];
  price: OrderPrice;
  quantityFilled: Scalars["Float"];
  quantityRemaining: Scalars["Float"];
  reservoirId: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  sourceUrl?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  taker?: Maybe<Scalars["String"]>;
  tokenId: Scalars["String"];
  updatedAt: Scalars["String"];
  validFrom: Scalars["String"];
  validUntil?: Maybe<Scalars["String"]>;
};

export type SeriesTokenNew = {
  animationUrl?: Maybe<Scalars["String"]>;
  attributes: Array<SeriesTokenAttribute>;
  chainId: Scalars["Float"];
  description?: Maybe<Scalars["String"]>;
  /** internal id, can be different from minted id on blockchain */
  id: Scalars["String"];
  imageUrl: Scalars["String"];
  minted: Scalars["Boolean"];
  name: Scalars["String"];
  ownerAddress?: Maybe<Scalars["String"]>;
  tokenId: Scalars["String"];
  tokenListing?: Maybe<SeriesTokenListing>;
  topOffer?: Maybe<SeriesTokenOffer>;
};

export type SeriesTokenOffer = {
  chainId: Scalars["Float"];
  collectionMarketplaceId?: Maybe<Scalars["String"]>;
  contractAddress: Scalars["String"];
  createdAt: Scalars["String"];
  criteriaKind?: Maybe<Scalars["String"]>;
  expiredAt?: Maybe<Scalars["String"]>;
  feeBps: Scalars["Float"];
  fees: Array<OrderFee>;
  id: Scalars["String"];
  kind: Scalars["String"];
  maker: Scalars["String"];
  price: OrderPrice;
  quantityFilled: Scalars["Float"];
  quantityRemaining: Scalars["Float"];
  reservoirId: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  sourceUrl?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  taker?: Maybe<Scalars["String"]>;
  tokenIds: Array<Scalars["String"]>;
  updatedAt: Scalars["String"];
  validFrom: Scalars["String"];
  validUntil?: Maybe<Scalars["String"]>;
};

export type SeriesTokensPaginated = {
  allMintedTokenIds?: Maybe<Array<Scalars["Float"]>>;
  edges: Array<SeriesToken>;
  pageInfo: CursorPaginatedInfo;
};

export type SeriesTokensPaginatedNew = {
  edges: Array<SeriesTokenNew>;
  pageInfo: CursorPaginatedInfo;
};

export type SeriesUploadOutput = {
  id: Scalars["String"];
  metadata: SeriesZipUploadMetadata;
};

export type SeriesZipUploadInput = {
  mime: Scalars["String"];
};

export type SeriesZipUploadMetadata = {
  mime: Scalars["String"];
  publicUrl: Scalars["String"];
  type: SupportedZipMediaType;
  url: Scalars["String"];
};

export type ShopifyData = {
  benefit: Benefit;
  buyButtons: Array<BuyButton>;
  collectionBuyButton?: Maybe<CollectionBuyButton>;
};

export type SimplifiedTx = {
  from: Scalars["String"];
  hash: Scalars["String"];
  nonce: Scalars["Float"];
  to?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type SimplifiedTxReceipt = {
  blockHash: Scalars["String"];
  blockNumber: Scalars["Float"];
  confirmations: Scalars["Float"];
  effectiveGasPrice: Scalars["String"];
  gasUsed: Scalars["String"];
  logs: Array<Log>;
  status: Scalars["String"];
};

/** Sorting direction of the data */
export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type Sponsor = {
  ranking?: Maybe<Scalars["Float"]>;
  sponsorUserAddress: Scalars["String"];
  totalNumSponsored: Scalars["Float"];
  user?: Maybe<PublicAccountSettings>;
};

export type SponsoredMintClaimInput = {
  numTokensToMint?: Maybe<Scalars["Float"]>;
  tokenIds?: Maybe<Array<Scalars["Float"]>>;
};

export type StripeAccount = {
  charges_enabled: Scalars["Boolean"];
  id: Scalars["String"];
};

export type StripeBalance = {
  /** @deprecated use `total` */
  amount?: Maybe<Scalars["Float"]>;
  currency: Scalars["String"];
  processing: Scalars["Float"];
  total: Scalars["Float"];
};

export type Subscription = {
  community: CommunityNotification;
  token: TokenNotification;
};

export type SubscriptionCommunityArgs = {
  communityId: Scalars["String"];
  type: CommunityNotificationType;
};

export type SubscriptionTokenArgs = {
  communityId: Scalars["String"];
  taskId?: Maybe<Scalars["String"]>;
  tokenIds?: Maybe<Array<Scalars["Float"]>>;
  type: TokenNotificationType;
};

/** Blockchain Supported Mainnets */
export enum SupportedMainnets {
  ARBITRUM = "ARBITRUM",
  BASE = "BASE",
  ETHEREUM = "ETHEREUM",
  FORMA = "FORMA",
  MANTLE = "MANTLE",
  OPTIMISM = "OPTIMISM",
  POLYGON = "POLYGON",
  SCROLL = "SCROLL",
  ZORA = "ZORA",
}

/** Supported Media Mime(s) */
export enum SupportedMediaMime {
  GIF = "GIF",
  JPEG = "JPEG",
  MP3 = "MP3",
  MP4 = "MP4",
  PNG = "PNG",
  WAV = "WAV",
  WEBM = "WEBM",
  WEBP = "WEBP",
}

/** Supported Media Types. */
export enum SupportedMediaType {
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

/** Supported Zipped Media Types */
export enum SupportedZipMediaType {
  ZIP = "ZIP",
}

export type SyncBenefitsResponse = {
  failed: Scalars["Float"];
  successful: Scalars["Float"];
  total: Scalars["Float"];
};

export type SystemContract = {
  address: Scalars["String"];
  chainId: Scalars["Float"];
  id: Scalars["String"];
  type: SystemContractType;
};

/** Contract type */
export enum SystemContractType {
  AuctionManager = "AuctionManager",
  CollectionFactory = "CollectionFactory",
  ConsensualNonTransferableTokenManage2 = "ConsensualNonTransferableTokenManage2",
  ConsensualNonTransferableTokenManager = "ConsensualNonTransferableTokenManager",
  DiscreteDutchAuctionMechanic = "DiscreteDutchAuctionMechanic",
  EditionsMetadataRenderer = "EditionsMetadataRenderer",
  GaslessMechanic = "GaslessMechanic",
  GenerativeSeriesImplementation = "GenerativeSeriesImplementation",
  MinimalForwarder = "MinimalForwarder",
  MintFeeOracle = "MintFeeOracle",
  MintManager = "MintManager",
  MultipleEditionsDFSImplementation = "MultipleEditionsDFSImplementation",
  MultipleEditionsImplementation = "MultipleEditionsImplementation",
  NonTransferableTokenManager = "NonTransferableTokenManager",
  Observability = "Observability",
  PermissionsRegistry = "PermissionsRegistry",
  RandomRankedAuctionMechanic = "RandomRankedAuctionMechanic",
  RandomSeriesImplementation = "RandomSeriesImplementation",
  RankedAuctionMechanic = "RankedAuctionMechanic",
  ReferralManager = "ReferralManager",
  SeedBasedMintMechanic = "SeedBasedMintMechanic",
  SeriesImplementation = "SeriesImplementation",
  SingleEditionDFSImplementation = "SingleEditionDFSImplementation",
  SingleEditionImplementation = "SingleEditionImplementation",
}

export type Tag = {
  count: Scalars["Float"];
  id: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type TagDraft = {
  name: Scalars["String"];
  slug: Scalars["Float"];
};

export type TagDraftInput = {
  name: Scalars["String"];
  slug: Scalars["Float"];
};

export type TermsRecord = {
  acceptedAt?: Maybe<Scalars["String"]>;
  family?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

export type ThemeButton = {
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  backgroundColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderColor?: Maybe<Scalars["String"]>;
  borderType: BorderType;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderWidth?: Maybe<Scalars["String"]>;
  onPageBackgroundBackgroundColor: Scalars["String"];
  onPageBackgroundBorderColor: Scalars["String"];
  onPageBackgroundBorderWidth: Scalars["String"];
  onPageBackgroundTextColor: Scalars["String"];
  onPrimaryBackgroundBackgroundColor: Scalars["String"];
  onPrimaryBackgroundBorderColor: Scalars["String"];
  onPrimaryBackgroundBorderWidth: Scalars["String"];
  onPrimaryBackgroundTextColor: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  textColor?: Maybe<Scalars["String"]>;
  textFontFamily: Scalars["String"];
  textFontWeight: Scalars["String"];
  textLetterSpacing: Scalars["String"];
  textTextTransform: Scalars["String"];
};

export type ThemeGlobal = {
  containerBorderType: Scalars["String"];
};

export type ThemeLabel = {
  fontWeight: Scalars["String"];
  letterSpacing: Scalars["String"];
};

export type ThemeModal = {
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderType?: Maybe<BorderType>;
};

export type ThemePageBackground = {
  backgroundColor: Scalars["String"];
};

export type ThemePrimaryBackground = {
  backgroundColor: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  backgroundImageUrl?: Maybe<Scalars["String"]>;
};

export type ThemeSecondaryBackground = {
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  backgroundColor?: Maybe<Scalars["String"]>;
};

export type ThemeSecondaryButton = {
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  backgroundColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderType?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  borderWidth?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  textColor?: Maybe<Scalars["String"]>;
};

export type ThemeText = {
  boldFontWeight: Scalars["String"];
  fontFamily: Scalars["String"];
  fontWeight: Scalars["String"];
  letterSpacing: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  onBackgroundPrimaryColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  onBackgroundSecondaryColor?: Maybe<Scalars["String"]>;
  onPageBackgroundPrimaryColor: Scalars["String"];
  onPrimaryBackgroundPrimaryColor: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  onSecondaryBackgroundPrimaryColor?: Maybe<Scalars["String"]>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  onSecondaryBackgroundSecondaryColor?: Maybe<Scalars["String"]>;
  textTransform: Scalars["String"];
};

export type ThemeTextInput = {
  backgroundColor: Scalars["String"];
  borderColor: Scalars["String"];
  borderType: BorderType;
  borderWidth: Scalars["String"];
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  placeholderTextColor?: Maybe<Scalars["String"]>;
  primaryTextColor: Scalars["String"];
};

export type ThemeTitle = {
  fontFamily: Scalars["String"];
  fontWeight: Scalars["String"];
  letterSpacing: Scalars["String"];
  textTransform: Scalars["String"];
};

/** Time unit of an interval */
export enum TimeUnit {
  HOURS = "HOURS",
  MINUTES = "MINUTES",
}

export type Token = {
  animationUri?: Maybe<Scalars["String"]>;
  /** Is user balance of token if used under user context. Is supply if used under any other context. Default to supply. */
  balanceOrSupply?: Maybe<Scalars["String"]>;
  benefits?: Maybe<Array<TokenBenefit>>;
  communityAddress: Scalars["String"];
  /** Number of tokens available for sale or distribution */
  creatorBalance?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
  mintedAsNonTransferable?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  /** Total number of tokens minted */
  supply?: Maybe<Scalars["Float"]>;
  taskId?: Maybe<Scalars["String"]>;
  tokenId: Scalars["ID"];
  tokenManagerAddress?: Maybe<Scalars["String"]>;
  tokenStatus?: Maybe<TokenStatus>;
  tokenType: TokenType;
  uri?: Maybe<Scalars["String"]>;
};

export type TokenUriArgs = {
  useChain: Scalars["Boolean"];
};

export type TokenAttribute = {
  traitType: Scalars["String"];
  value: Scalars["String"];
};

export type TokenBenefit = {
  customName?: Maybe<Scalars["String"]>;
  releasePartyId?: Maybe<Scalars["String"]>;
  type: BenefitType;
};

export type TokenBenefitType = {
  customName?: Maybe<Scalars["String"]>;
  releasePartyId?: Maybe<Scalars["String"]>;
  type: BenefitType;
};

export type TokenCollection = {
  address: Scalars["String"];
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  imported: Scalars["Boolean"];
  marketplaceId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  onchainId?: Maybe<Scalars["String"]>;
  status?: Maybe<CollectionStatus>;
  type: Scalars["String"];
};

/** Distribution Type for the Token */
export enum TokenDistributionType {
  Auction = "Auction",
  Mint = "Mint",
}

export type TokenMetadata = {
  /** Contest ID if the token can be claimed (have a valid open contest) */
  claimable?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
};

export type TokenMetadataAttribute = {
  trait_type: Scalars["String"];
  value: Scalars["String"];
};

export type TokenMetadataAttributeInput = {
  trait_type: Scalars["String"];
  value: Scalars["String"];
};

export type TokenNotification = {
  communityId: Scalars["String"];
  status: NotificationStatus;
  taskId?: Maybe<Scalars["String"]>;
  tokenIds: Array<Scalars["Float"]>;
  type: TokenNotificationType;
};

/** Token Notification Type */
export enum TokenNotificationType {
  MINT = "MINT",
  TRANSFER = "TRANSFER",
}

export type TokenSecondaryPrice = {
  amount: SecondaryPriceAmount;
  currency: SecondaryPriceCurrency;
};

/** Sorting options for marketplace tokens */
export enum TokenSortOptions {
  DATE_CREATED = "DATE_CREATED",
  DATE_LISTED = "DATE_LISTED",
  PRICE = "PRICE",
}

/** Sortable fields of a collection token */
export enum TokenSortableField {
  DATE_CREATED = "DATE_CREATED",
  PRICE = "PRICE",
}

/** Availability status of the Token. */
export enum TokenStatus {
  Minted = "Minted",
  Pending = "Pending",
}

/** Token type */
export enum TokenType {
  All = "All",
  Benefit = "Benefit",
  Collectible = "Collectible",
  Membership = "Membership",
}

export type TokensMetadata = {
  maxQuantity?: Maybe<Scalars["Float"]>;
  quantity: Scalars["Float"];
  totalGrantedQuantity?: Maybe<Scalars["Float"]>;
};

export type TraitAttribute = {
  traitName: Scalars["String"];
  traitValue: Scalars["String"];
};

export type TraitAttributeInput = {
  traitName: Scalars["String"];
  traitValue: Scalars["String"];
};

export type TransactionArgs = {
  address?: Maybe<Scalars["String"]>;
  args: Scalars["String"];
  bytecode?: Maybe<Scalars["String"]>;
  chainId: Scalars["Float"];
  gasLimit?: Maybe<Scalars["String"]>;
  method?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** what is the status of the onchain transaction */
export enum TransactionStatus {
  MINED = "MINED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/** type of highlight onchain action associated with this transaction */
export enum TransactionType {
  EVM_721_AUCTION = "EVM_721_AUCTION",
  EVM_721_BID = "EVM_721_BID",
  EVM_721_MINT = "EVM_721_MINT",
  EVM_721_RANKED_AUCTION_BID = "EVM_721_RANKED_AUCTION_BID",
  EVM_721_RANKED_AUCTION_EARNINGS = "EVM_721_RANKED_AUCTION_EARNINGS",
  EVM_721_RANKED_AUCTION_RECLAIM_BID_FUNDS = "EVM_721_RANKED_AUCTION_RECLAIM_BID_FUNDS",
  EVM_APPROVE_TRANSFER = "EVM_APPROVE_TRANSFER",
  EVM_APPROVE_TRANSFER_WRAPPED = "EVM_APPROVE_TRANSFER_WRAPPED",
  EVM_CREATE_MECHANIC_VECTOR = "EVM_CREATE_MECHANIC_VECTOR",
  EVM_CREATE_MINT_VECTOR = "EVM_CREATE_MINT_VECTOR",
  EVM_CROSSCHAIN_BURN = "EVM_CROSSCHAIN_BURN",
  EVM_CURRENCY_ALLOWANCE = "EVM_CURRENCY_ALLOWANCE",
  EVM_DEPLOY_COLLECTION = "EVM_DEPLOY_COLLECTION",
  EVM_DEPOSIT_FUNDS = "EVM_DEPOSIT_FUNDS",
  EVM_DUTCH_AUCTION_CLAIM_FUNDS = "EVM_DUTCH_AUCTION_CLAIM_FUNDS",
  EVM_DUTCH_AUCTION_REBATE = "EVM_DUTCH_AUCTION_REBATE",
  EVM_MINT_CREATOR_RESERVES = "EVM_MINT_CREATOR_RESERVES",
  EVM_RANKED_AUCTION_BID = "EVM_RANKED_AUCTION_BID",
  EVM_SET_BASE_URI = "EVM_SET_BASE_URI",
  EVM_SPONSOR_MINT = "EVM_SPONSOR_MINT",
  EVM_TOKEN_SALE = "EVM_TOKEN_SALE",
  EVM_TOKEN_TRANSFER = "EVM_TOKEN_TRANSFER",
  EVM_UPDATE_DUTCH_AUCTION_VECTOR = "EVM_UPDATE_DUTCH_AUCTION_VECTOR",
  EVM_UPDATE_METADATA = "EVM_UPDATE_METADATA",
  EVM_UPDATE_MINT_VECTOR = "EVM_UPDATE_MINT_VECTOR",
  EVM_UPDATE_RANKED_AUCTION_VECTOR = "EVM_UPDATE_RANKED_AUCTION_VECTOR",
  EVM_UPDATE_ROYALTY = "EVM_UPDATE_ROYALTY",
}

export type Trending = {
  edges: Array<TrendingItem>;
  filters: TrendingFilters;
  pageInfo: CursorPaginatedInfo;
};

export type TrendingArtists = {
  edges: Array<ArtistItem>;
  filters: TrendingArtistsFilters;
  pageInfo: CursorPaginatedInfo;
};

export type TrendingArtistsFilters = {
  ignoreArtistAdresses: Array<Scalars["String"]>;
  mainnetOnly: Scalars["Boolean"];
  periodHours: Scalars["Int"];
};

export type TrendingFilters = {
  freeOnly: Scalars["Boolean"];
  mainnetOnly: Scalars["Boolean"];
  periodHours: Scalars["Int"];
};

export type TrendingItem = {
  collection: CollectionCombined;
  mintsCount: Scalars["Int"];
};

export type TwitterConnectState = {
  codeVerifier: Scalars["String"];
  state: Scalars["String"];
};

export type UnlinkDiscordAccountResponse = {
  authMethod: Scalars["String"];
  blockchainAccount: Eoa;
  createdAt: Scalars["String"];
  createdCommunities: Array<CommunityIdentifier>;
  creatorTerms?: Maybe<TermsRecord>;
  discord?: Maybe<DiscordLinkedAccountData>;
  emailPreference: EmailPreference;
  flagVariations: UserFlagVariations;
  id: Scalars["String"];
  isMember?: Maybe<Scalars["Boolean"]>;
  magicLinkId: Scalars["String"];
  pendingTokens: Array<UserToken>;
  personalInformation: PersonalInformation;
  profile?: Maybe<Profile>;
  stripeAccount?: Maybe<StripeAccount>;
  terms?: Maybe<TermsRecord>;
  tokens: Array<UserToken>;
  updatedAt: Scalars["String"];
};

export type UnlinkDiscordAccountResponseIsMemberArgs = {
  slug: Scalars["String"];
};

export type UnlinkDiscordAccountResponseTokensArgs = {
  slug?: Maybe<Scalars["String"]>;
};

export type UnlockGateResult = {
  conditionResults?: Maybe<Array<ConditionResult>>;
  failureReasons?: Maybe<Array<FailureReason>>;
  gateOwnerId?: Maybe<Scalars["String"]>;
  passed: Scalars["Boolean"];
  type?: Maybe<Scalars["String"]>;
};

export type UpdateAccountSettings = {
  avatarUrl?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<AccountSettingsEmailInput>;
  useNftAvatar?: Maybe<Scalars["Boolean"]>;
  website?: Maybe<Scalars["String"]>;
};

/** Type of update Action post reactions. */
export enum UpdateActionType {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export type UpdateCollectionBuyButtonInput = {
  collectionComponentDivId: Scalars["String"];
  collectionId: Scalars["String"];
  domain: Scalars["String"];
  storefrontAccessToken: Scalars["String"];
};

export type UpdateCollectionBuyButtonResult = {
  collectionComponentDivId: Scalars["String"];
  collectionId: Scalars["String"];
  communityId: Scalars["String"];
  domain: Scalars["String"];
  storefrontAccessToken: Scalars["String"];
};

export type UpdateCollectionDraftInput = {
  collectionDraft?: Maybe<CollectionDetailsDraftInput>;
  collectionType?: Maybe<_CollectionType>;
  contractDraft?: Maybe<ContractDraftInput>;
  contractId?: Maybe<Scalars["String"]>;
  distributionDraft?: Maybe<DistributionDraftInput>;
  distributionVectorsDraft?: Maybe<Array<DistributionVectorDraftInput>>;
  furthestStep: DraftProgress;
  gateId?: Maybe<Scalars["String"]>;
  metadata?: Maybe<CollectionMetadataInput>;
  moduleSettings: ModuleSettingsDraftInput;
  version?: Maybe<Scalars["Float"]>;
};

export type UpdateContractInput = {
  asciiArt?: Maybe<Scalars["String"]>;
  chainId?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
  type?: Maybe<ContractEntityType>;
};

export type UpdateDiscordInfoInput = {
  roleId: Scalars["String"];
  serverId: Scalars["String"];
  skipValidation?: Maybe<Scalars["Boolean"]>;
};

export type UpdateEmailPreferenceInput = {
  community: Scalars["Boolean"];
  marketing: Scalars["Boolean"];
};

export type UpdateGeneralInfoInput = {
  contactEmail?: Maybe<Scalars["String"]>;
  creatorName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type UpdateMintVectorInput = {
  collectorMessage?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  gateId?: Maybe<Scalars["String"]>;
  maxPerTxn?: Maybe<Scalars["Float"]>;
  maxPerUser?: Maybe<Scalars["Float"]>;
  maxPerVector?: Maybe<Scalars["Float"]>;
  mintVectorId: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  paused?: Maybe<Scalars["Boolean"]>;
  price?: Maybe<Scalars["String"]>;
  priceDropInterval?: Maybe<Array<PriceDropTimePeriodInput>>;
  scheduledPrices?: Maybe<Array<Scalars["String"]>>;
  start?: Maybe<Scalars["String"]>;
};

export type UpdateOfferInput = {
  currency?: Maybe<Scalars["String"]>;
  onAboutPage?: Maybe<Scalars["Boolean"]>;
  onPrivatePage?: Maybe<Scalars["Boolean"]>;
  price?: Maybe<Scalars["String"]>;
};

export type UpdatePhoneNumberInput = {
  number: Scalars["String"];
};

export type UpdatePortfolioInput = {
  artistName?: Maybe<Scalars["String"]>;
  artistTwitter?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  curator?: Maybe<Scalars["String"]>;
  curatorSlug?: Maybe<Scalars["String"]>;
  status?: Maybe<PortfolioStatus>;
  title?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  unclaimed?: Maybe<Scalars["Boolean"]>;
};

export type UpdatePortfolioItemInput = {
  button?: Maybe<PortfolioItemButtonInput>;
  collectionId?: Maybe<Scalars["String"]>;
  collectionInfo?: Maybe<CollectionInfoInput>;
  collectionName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  openSeaLink?: Maybe<Scalars["Boolean"]>;
  videoUrl?: Maybe<Scalars["String"]>;
};

export type UpdatePostContentInput = {
  attachments?: Maybe<Array<AttachmentInput>>;
  communitySlug: Scalars["String"];
  text: Scalars["String"];
};

export type UpdatePostPinInput = {
  communitySlug: Scalars["String"];
  type: UpdateActionType;
};

export type UpdatePostReactionInput = {
  communitySlug: Scalars["String"];
  reaction: Scalars["String"];
  type: UpdateActionType;
};

export type UpdateProfileInput = {
  aboutMe?: Maybe<Scalars["String"]>;
  username: Scalars["String"];
};

export type User = {
  authMethod: Scalars["String"];
  blockchainAccount: Eoa;
  createdAt: Scalars["String"];
  createdCommunities: Array<CommunityIdentifier>;
  creatorTerms?: Maybe<TermsRecord>;
  discord?: Maybe<DiscordLinkedAccountData>;
  emailPreference: EmailPreference;
  flagVariations: UserFlagVariations;
  id: Scalars["String"];
  isMember?: Maybe<Scalars["Boolean"]>;
  magicLinkId: Scalars["String"];
  pendingTokens: Array<UserToken>;
  personalInformation: PersonalInformation;
  profile?: Maybe<Profile>;
  stripeAccount?: Maybe<StripeAccount>;
  terms?: Maybe<TermsRecord>;
  tokens: Array<UserToken>;
  updatedAt: Scalars["String"];
};

export type UserIsMemberArgs = {
  slug: Scalars["String"];
};

export type UserTokensArgs = {
  slug?: Maybe<Scalars["String"]>;
};

export type UserFlagVariations = {
  accountsRequireCaptchaForCreation: Scalars["Boolean"];
  apolloMintPageUpdate: Scalars["Boolean"];
  audienceTabV2: Scalars["Boolean"];
  calendarWatch: Scalars["Boolean"];
  capitolMintingNewUx: Scalars["Boolean"];
  collections721mEnableFeature: Scalars["Boolean"];
  contentFeedEnableNewPostEditor: Scalars["Boolean"];
  contractsCollections721mCreate: Scalars["Boolean"];
  createCollectionsWithoutMintVectors: Scalars["Boolean"];
  createConditionFromWallet: Scalars["Boolean"];
  createGateFromImportedCollection: Scalars["Boolean"];
  createGateFromImportedToken: Scalars["Boolean"];
  creatorDashboardCreateOneCommunityPerUser: Scalars["Boolean"];
  creatorDashboardCustomBenefitTokens: Scalars["Boolean"];
  creatorDashboardDisableCreateCommunity: Scalars["Boolean"];
  creatorDashboardEnableMintTokenLimit: Scalars["Boolean"];
  creatorDashboardIncomeTab: Scalars["Boolean"];
  creatorDashboardMembersTab: Scalars["Boolean"];
  creatorDashboardRequirePhoneValidation: Scalars["Boolean"];
  creatorDashboardShowDiscordServerConfigPainel: Scalars["Boolean"];
  creatorDashboardThemeTab: Scalars["Boolean"];
  creatorDashboardVideoAssetOnToken: Scalars["Boolean"];
  crossmintPayments: Scalars["Boolean"];
  crossmintSelfServe: Scalars["Boolean"];
  cryptoByoWalletAuth: Scalars["Boolean"];
  discordSyncBenefitsButton: Scalars["Boolean"];
  embedsShowModal: Scalars["Boolean"];
  emergencyAlertFrontendMessage: Scalars["String"];
  emergencyStopClaimToken: Scalars["Boolean"];
  emergencyStopDeployContract: Scalars["Boolean"];
  emergencyStopMintNewTokens: Scalars["Boolean"];
  emergencyStopNewContentFeedPosts: Scalars["Boolean"];
  emergencyStopSendTokensToAll: Scalars["Boolean"];
  emergencyStopShowContentFeedPosts: Scalars["Boolean"];
  enableAsyncCollectionCreation: Scalars["Boolean"];
  enableAuctions: Scalars["Boolean"];
  enableContractCustomization: Scalars["Boolean"];
  enableCreatorPortfolio: Scalars["Boolean"];
  enableCreatorRewards: Scalars["Boolean"];
  enableCryptoPayments: Scalars["Boolean"];
  enableDegenCurrency: Scalars["Boolean"];
  enableDeployDetectionAndForcePageReload: Scalars["Boolean"];
  enableDirectMinting: Scalars["Boolean"];
  enableDutchAuctionsCreation: Scalars["Boolean"];
  enableEditCollectionDetails: Scalars["Boolean"];
  enableFormaCollections: Scalars["Boolean"];
  enableGenSeriesCuratedOutput: Scalars["Boolean"];
  enableInhotimCreateUx: Scalars["Boolean"];
  enableInhotimDrafts: Scalars["Boolean"];
  enableInhotimSeriesCollections: Scalars["Boolean"];
  enableJobsGenSeries: Scalars["Boolean"];
  enableMintRevealPage: Scalars["Boolean"];
  enableMintingNonTransferable: Scalars["Boolean"];
  enableMultiplePortfolios: Scalars["Boolean"];
  enableNavbarSearch: Scalars["Boolean"];
  enableNewToolsDashboard: Scalars["Boolean"];
  enableRankedAuctions: Scalars["Boolean"];
  enableReferrals: Scalars["Boolean"];
  enableResizedImages: Scalars["Boolean"];
  enableSponsoredMints: Scalars["Boolean"];
  enableTagsAndCategories: Scalars["Boolean"];
  enableTransfers: Scalars["Boolean"];
  enableUserProfile: Scalars["Boolean"];
  enableV3API: Scalars["Boolean"];
  gateByFarcasterFollowers: Scalars["Boolean"];
  gateByTokenAttribute: Scalars["Boolean"];
  gatedMints: Scalars["Boolean"];
  gatesAppShowOperationStatusHeader: Scalars["Boolean"];
  gatesDisablePageCreate: Scalars["Boolean"];
  gatesDisableTokenTestnets: Scalars["Boolean"];
  gatesEditorDisableHTMLPlugin: Scalars["Boolean"];
  gatesEnableS3Upload: Scalars["Boolean"];
  gatesStopPageView: Scalars["Boolean"];
  importCollections: Scalars["Boolean"];
  importEVMCollections: Scalars["Boolean"];
  incomeAllowWithdraw: Scalars["Boolean"];
  incomeShowUsdEarnings: Scalars["Boolean"];
  incomeShowWEthEarnings: Scalars["Boolean"];
  marketplaceAllFeatures: Scalars["Boolean"];
  marketplaceCollectorsChoice: Scalars["Boolean"];
  marketplacePageOptimization: Scalars["Boolean"];
  mintPageLoadOptimization: Scalars["Boolean"];
  multipleMintVectorsEnableCreation: Scalars["Boolean"];
  onboardingSquadCreatorTermsOfService: Scalars["Boolean"];
  paymentsEnableFiatOffers: Scalars["Boolean"];
  paymentsStripeConnectOnboarding: Scalars["Boolean"];
  releasePartyAccessFeature: Scalars["String"];
  releasePartyUpdateUserProfile: Scalars["String"];
  scaffoldBecomeCreatorCta: Scalars["Boolean"];
  scaffoldEnableAccountEmailPreferencesPage: Scalars["Boolean"];
  scaffoldEnableAccountTransactionHistoryPage: Scalars["Boolean"];
  scaffoldEnableAccountWalletPage: Scalars["Boolean"];
  scaffoldEnableCreatorDashboard: Scalars["Boolean"];
  scaffoldEnableMagiclinkPrivateKeyExport: Scalars["Boolean"];
  segmentEnableInCommunities: Scalars["Boolean"];
  seriesMetadataUpdates: Scalars["Boolean"];
  shopifyOnboardWithBuyButton: Scalars["Boolean"];
  showAccountSettingsOnFrontend: Scalars["Boolean"];
  showAudienceTab: Scalars["Boolean"];
  showCaptureSelectorType: Scalars["Boolean"];
  showCollectionsContextMenu: Scalars["Boolean"];
  showCreateEmailGate: Scalars["Boolean"];
  showDarkModeSettings: Scalars["Boolean"];
  showDebugPages: Scalars["Boolean"];
  showGPUSupportToggle: Scalars["Boolean"];
  showLockedPreviewToggleInTools: Scalars["Boolean"];
  showMintPageCollectors: Scalars["Boolean"];
  showTestnetCurrencies: Scalars["Boolean"];
  showUploadZipPreview: Scalars["Boolean"];
  skipFrontendValidation: Scalars["Boolean"];
  trendingModule24HoursFilter: Scalars["Boolean"];
  trendingModuleFreeMintsFilter: Scalars["Boolean"];
  trendingModuleMintedColumn: Scalars["Boolean"];
  useNewContractDeploy: Scalars["Boolean"];
  useV3Marketplace: Scalars["Boolean"];
  zipIncreaseFileSizeLimit: Scalars["Boolean"];
};

export type UserSubmission = {
  formFieldAnswer?: Maybe<Scalars["String"]>;
  submittedAt: Scalars["String"];
};

export type UserToken = {
  /** User balance */
  balance?: Maybe<Scalars["Float"]>;
  benefits?: Maybe<Array<TokenBenefit>>;
  community: CommunityIdentifier;
  description?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  name: Scalars["String"];
  /** Total number of tokens minted */
  supply?: Maybe<Scalars["Float"]>;
  tokenId: Scalars["Int"];
  tokenType: TokenType;
};

export type V1Royalty = {
  royaltyBreakdown?: Maybe<Array<RoyaltyBreakdown>>;
  royaltyPercentageString?: Maybe<Scalars["String"]>;
  royaltyRecipient?: Maybe<Scalars["String"]>;
};

export type VerifyPhoneNumberInput = {
  code: Scalars["String"];
};

export type WalletNft = {
  attributes?: Maybe<Array<Attribute>>;
  chain: Scalars["String"];
  contract: Scalars["String"];
  id: Scalars["String"];
  imageUrl?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  standard?: Maybe<Scalars["String"]>;
  videoUrl?: Maybe<Scalars["String"]>;
};

export type WalletNftsPaginated = {
  pageInfo: CursorPaginatedInfo;
  walletNfts: Array<WalletNft>;
};

export type WebsiteTheme = {
  button: ThemeButton;
  global: ThemeGlobal;
  label: ThemeLabel;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  modal?: Maybe<ThemeModal>;
  pageBackground: ThemePageBackground;
  primaryBackground: ThemePrimaryBackground;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  secondaryBackground?: Maybe<ThemeSecondaryBackground>;
  /** @deprecated This field is part of community v1 which will be removed very soon. */
  secondaryButton?: Maybe<ThemeSecondaryButton>;
  text: ThemeText;
  textInput: ThemeTextInput;
  title: ThemeTitle;
};

/** New collection type - temp until migration is over */
export enum _CollectionType {
  GenerativeSeries = "GenerativeSeries",
  Imported = "Imported",
  LimitedEdition = "LimitedEdition",
  OneOfOne = "OneOfOne",
  OpenEdition = "OpenEdition",
  Series = "Series",
}
