import { NumberInput } from "@mantine/core";
import { NumberInputProps } from "@mantine/core/lib/NumberInput/NumberInput";

import useMintState from "../../../hooks/useMintState";

const NumMintsInput = ({
  height,
  ...rest
}: Omit<NumberInputProps, "data"> & {
  height?: number;
}) => {
  const { numTokensToMint, setNumTokensToMint, numMintsLeft, collection } =
    useMintState();
  const enableMintGenSeriesControls =
    collection?.flagVariations.enableMintGenSeriesControls;
  if (enableMintGenSeriesControls) return <></>;
  return (
    <NumberInput
      {...rest}
      onChange={(value: number) => setNumTokensToMint(parseInt(String(value)))}
      defaultValue={1}
      value={numTokensToMint}
      min={1}
      max={numMintsLeft}
      w={84}
      miw={84}
      styles={{
        input: {
          marginTop: 0,
          paddingRight: 24,
          height,
        },
        rightSection: {
          paddingRight: 0,
        },
      }}
    />
  );
};

export default NumMintsInput;
