import React from "react";

import { useApolloClient } from "@apollo/client";
import {
  DESTRUCTIVE_COLOR,
  OUTLINE_COLOR,
  SECONDARY_COLOR,
} from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import BidBuyButtons from "@hl/shared-features/lib/features/marketplace/components/BidBuyButtons";
import CancelListingModalHighlight, {
  CancelListingModalTitle,
} from "@hl/shared-features/lib/features/marketplace/modals/CancelListingModal";
import ListModalHighlight from "@hl/shared-features/lib/features/marketplace/modals/ListModal";
import ManageOffersModal, {
  ManageOffersModalTitle,
} from "@hl/shared-features/lib/features/marketplace/modals/ManageOffersModal";
import {
  isTokenV1,
  MarketplaceToken,
  TokenV1,
} from "@hl/shared-features/lib/features/marketplace/utils";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { setMarketplaceTokenMinted } from "@hl/shared-features/lib/utils/cache";
import { Box, Button, ButtonProps, Text } from "@mantine/core";

import { SaleStatus } from "~features/MintPage/utils/types";
import useMintState from "~hooks/useMintState";

import MintCardButton, {
  MintButtonPosition,
} from "../MintPage/MintVector/MintCardButton";

const MarketplaceTokenButton = ({
  token,
  onCompleted = () => null,
}: {
  token: MarketplaceToken;
  onCompleted?: () => void;
}) => {
  const { cache } = useApolloClient();

  const {
    mintVector,
    handleMint,
    collection,
    walletAddress,
    mintingTokenId,
    loadingClaimMint,
    isActiveMint,
    saleStatus,
    isMarketplaceEnabledForCollectionChain,
    isImported,
    isSingleContractEdition,
  } = useMintState();
  const { pushModal } = useModalStack();
  const isOwner =
    walletAddress?.toLowerCase() &&
    token.ownerAddress?.toLowerCase() === walletAddress?.toLowerCase();
  const tokenId = token.tokenId;
  const buttonProps: ButtonProps = {
    size: "sm",
    px: 12,
  };

  if (!mintVector && !isImported) {
    return null;
  }

  if (isSingleContractEdition) {
    return null;
  }

  if (isMarketplaceEnabledForCollectionChain) {
    if (token.minted && collection) {
      if (isOwner) {
        if (token.topOffer?.status === "active") {
          return (
            <Button
              {...buttonProps}
              color={OUTLINE_COLOR}
              onClick={() =>
                pushModal(
                  <ManageOffersModal
                    collectionData={collection}
                    tokenId={tokenId}
                    onCompleted={onCompleted}
                  />,
                  {
                    size: "100%",
                    title: (
                      <ManageOffersModalTitle
                        tokenName={token?.name}
                        collectionName={collection?.name}
                      />
                    ),
                  }
                )
              }
            >
              View offers
            </Button>
          );
        }
        return token?.tokenListing?.status === "active" ? (
          <Button
            {...buttonProps}
            color={DESTRUCTIVE_COLOR}
            onClick={() =>
              pushModal(
                <CancelListingModalHighlight
                  collectionId={collection.id}
                  id={token.tokenListing?.reservoirId ?? ""}
                  collectionName={collection.name}
                  chainId={collection.chainId}
                  imageUrl={token.imageUrl}
                  tokenId={tokenId}
                  onChainId={`${token.chainId}:${collection?.address}:${tokenId}`}
                  floorPrice={
                    isTokenV1(token)
                      ? (token as TokenV1).floorListing?.price.amount.native
                      : undefined
                  }
                  onCancel={onCompleted}
                />,
                {
                  size: "lg",
                  title: CancelListingModalTitle(),
                }
              )
            }
          >
            Cancel listing
          </Button>
        ) : (
          <Button
            {...buttonProps}
            color={SECONDARY_COLOR}
            onClick={() =>
              pushModal(
                <ListModalHighlight
                  src={token.imageUrl || ""}
                  collectionData={collection}
                  tokenId={tokenId ?? null}
                  onCompleted={onCompleted}
                />,
                {
                  size: "lg",
                  title: <Text fw={WEIGHT_BOLD}>Create a listing</Text>,
                }
              )
            }
          >
            List for sale
          </Button>
        );
      }

      return <BidBuyButtons token={token} collection={collection} />;
    }
    if (
      [SaleStatus.ENDED, SaleStatus.SOLD_OUT, SaleStatus.PAUSED].includes(
        saleStatus ?? SaleStatus.SOLD_OUT
      )
    ) {
      return null;
    }
  }

  if (!mintVector) {
    return null;
  }

  return (
    <Box>
      <MintCardButton
        showWarningModals
        tokenId={tokenId}
        mintLabel="Mint now"
        onMintClick={(fromChain: number) =>
          handleMint(fromChain, tokenId, token.imageUrl || "")
        }
        loading={mintingTokenId === tokenId && loadingClaimMint}
        showErrorIcon={false}
        isTokenSold={token.minted}
        enableCrossChainMint={
          collection?.flagVariations.enableCrossChainMinting
        }
        showGateRequirementsModal
        disabledMint={isActiveMint && mintingTokenId !== tokenId}
        showConnectButton={false}
        color={SECONDARY_COLOR}
        onMinted={() => {
          setMarketplaceTokenMinted(cache, String(token.id));
          onCompleted?.();
        }}
        buttonPosition={MintButtonPosition.Marketplace}
        {...buttonProps}
      />
    </Box>
  );
};

export default MarketplaceTokenButton;
