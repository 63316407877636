import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { OUTLINE_COLOR, SECONDARY_COLOR, } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { formatNumber } from "@hl/base-components/lib/utils/numbers";
import { Button, Text } from "@mantine/core";
import { getCurrencySymbol } from "../../../utils/currency";
import { useAuth } from "../../auth/AuthContext";
import { useModalStack } from "../../modal";
import BidModalHighlight from "../modals/BidModal";
import BuyModalHighlight from "../modals/BuyModal";
const BidBuyButtons = ({ token, collection, onCompleted = () => null, }) => {
    var _a, _b, _c, _d;
    const { walletAddress } = useAuth();
    const { pushModal } = useModalStack();
    const isOwner = (walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase()) &&
        ((_a = token.ownerAddress) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase());
    const buttonProps = {
        size: "sm",
        px: 12,
    };
    if (isOwner)
        return null;
    if (token.tokenListing) {
        return (_jsx(Button, { ...buttonProps, color: OUTLINE_COLOR, onClick: () => {
                var _a;
                return pushModal(_jsx(BuyModalHighlight, { tokenId: token.tokenId, src: token.imageUrl || "", collectionData: collection, orderId: (_a = token.tokenListing) === null || _a === void 0 ? void 0 : _a.reservoirId, onCompleted: onCompleted }), {
                    size: "lg",
                    title: (_jsx(Text, { size: "md", fw: WEIGHT_BOLD, children: "Complete purchase" })),
                });
            }, children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: `${formatNumber((_d = (_c = (_b = token.tokenListing) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.amount) === null || _d === void 0 ? void 0 : _d.native)} ${getCurrencySymbol(collection.chainId || "")}` }) }));
    }
    return (_jsx(Button, { color: SECONDARY_COLOR, ...buttonProps, onClick: () => pushModal(_jsx(BidModalHighlight, { tokenId: token.tokenId, src: token.imageUrl || "", collectionData: collection, onCompleted: onCompleted }), {
            size: "lg",
            title: _jsx(Text, { fw: WEIGHT_BOLD, children: "Make an offer" }),
        }), children: "Make offer" }));
};
export default BidBuyButtons;
