import { envConfig } from "@hl/shared-features/lib/config";
import { chainIdToNetworkType } from "@hl/shared-features/lib/features/chain";

import { NetworkType } from "../apollo/graphql.generated";

export const MarketplaceProductionBlockchains = [
  NetworkType.ETHEREUM,
  NetworkType.POLYGON,
  NetworkType.OPTIMISM,
  NetworkType.BASE,
  NetworkType.ZORA,
  NetworkType.SCROLL,
  NetworkType.SEPOLIA,
  NetworkType.BASE_SEPOLIA,
];

export const MarketplaceTestBlockchains = [
  NetworkType.ETHEREUM,
  NetworkType.POLYGON,
  NetworkType.OPTIMISM,
  NetworkType.BASE,
  NetworkType.ZORA,
  NetworkType.SCROLL,
  NetworkType.SEPOLIA,
  NetworkType.MUMBAI,
  NetworkType.BASE_SEPOLIA,
];

export const checkIfMarketplaceEnabled = (chainId?: number) => {
  if (!chainId) {
    return true;
  }
  const network = chainIdToNetworkType(chainId);
  const supportedNetworks = envConfig.isDevelopment
    ? MarketplaceTestBlockchains
    : MarketplaceProductionBlockchains;
  return supportedNetworks.includes(network);
};
