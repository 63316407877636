import { reservoirChains } from "@reservoir0x/reservoir-sdk";
import { envConfig } from "../../config";
export const marketplaceFeeBps = envConfig.marketplaceFeeBps
    ? parseInt(envConfig.marketplaceFeeBps)
    : 150;
// Source: https://docs.google.com/document/d/1E63Vx6RlpqLlzUFygyKaQXbqRq2wPMJHi3m-IAIi5t0
const ProductionMainnetVault = "0x9E6667c15f7CE7967Fb910d53534195881E23092";
const ProductionPlatformPaymentVaults = {
    [reservoirChains.mainnet.id]: ProductionMainnetVault,
    [reservoirChains.polygon.id]: "0xC308915D33F9b4Fa04B4ed0411969eAC46Bd93F6",
    [reservoirChains.optimism.id]: "0x2A37E7929d79fF20C11563fbA55c49c2929c93B1",
    [reservoirChains.arbitrum.id]: "0xA9B56a6E91CB0Fb31e650F95bc0DE7E7d461802e",
    [reservoirChains.base.id]: "0x3D423ca75bc9e8B3F2D3E9082715F2b241Bb9593",
    [reservoirChains.zora.id]: "0x8CBcbD492Eea3De7bDF223CfbeE8d417f5E73b50",
    [reservoirChains.scroll.id]: "0x8CBcbD492Eea3De7bDF223CfbeE8d417f5E73b50",
};
const TestPlatformPaymentVault = "0x665b9a18695e8aa50e69a697f00da24ac598e6b0";
const getMarketplaceFeeConfig = (id) => {
    const marketplaceFeeRecipient = envConfig.isDevelopment
        ? TestPlatformPaymentVault
        : id
            ? ProductionPlatformPaymentVaults[id] || TestPlatformPaymentVault
            : ProductionMainnetVault;
    return [`${marketplaceFeeRecipient}:${marketplaceFeeBps}`];
};
export default getMarketplaceFeeConfig;
