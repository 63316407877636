import { PropsWithChildren } from "react";

import {
  FarcasterIcon,
  Share03,
  XIcon,
  Copy04 as CopyIcon,
} from "@hl/base-components/lib/assets/icons.generated";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { ampli } from "@hl/shared-features/lib/ampli";
import { useAuth } from "@hl/shared-features/lib/features/auth";
import {
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { networkLookup } from "@hl/shared-features/lib/utils/blockExplorer";
import {
  Box,
  Space,
  Button,
  Text,
  Anchor,
  Card,
  Stack,
  CopyButton,
  Group,
  useMantineTheme,
  MantineSize,
  MantineColor,
  Grid,
  Divider,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { TwitterShareButton } from "react-share";

import useReferral from "~features/share/useReferral";
import useMintState from "~hooks/useMintState";

import { NetworkType, PriceType } from "../../apollo/graphql.generated";

interface ReferralModalData {
  chainId: number;
  collection: { id: string; onchainId: string; name: string };
}

interface ButtonData {
  color?: MantineColor;
  size?: MantineSize;
  compact?: boolean;
  show?: boolean;
  collectionName?: string;
}

const WC_TX_CHAINS = [
  // NetworkType.ETHEREUM, not supported yet
  NetworkType.BASE,
  NetworkType.OPTIMISM,
  NetworkType.ZORA,
];

const ReferralModal = ({ data }: { data: ReferralModalData }) => {
  const { walletAddress } = useAuth();
  const { collection } = data;
  const { mintUrlWithReferrer } = useReferral();

  const mintUrlWithCurrentUser = mintUrlWithReferrer(collection, walletAddress);

  const network = networkLookup(data.chainId);

  const isFcTxMintEligible = WC_TX_CHAINS.includes(network.type);

  return (
    <Box mx="auto">
      <Stack spacing={8}>
        <Text component="label">Copy the link to the mint page</Text>
        <Card>
          <Group position="apart" align="center" noWrap>
            <Text
              size={14}
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {mintUrlWithCurrentUser}
            </Text>
            <CopyButton value={mintUrlWithCurrentUser}>
              {({ copied, copy }) => (
                <Button
                  color={SECONDARY_COLOR}
                  size="xs"
                  leftIcon={<CopyIcon />}
                  onClick={() => {
                    copy();
                    if (ampli.isLoaded) {
                      ampli.referEarn({
                        projectName: collection.name,
                        referralSelected: "copyLink",
                      });
                    }
                  }}
                >
                  {copied ? "Copied!" : "Copy"}
                </Button>
              )}
            </CopyButton>
          </Group>
        </Card>
        <Text size="xs" color={TEXT_COLOR.SECONDARY}>
          {walletAddress
            ? `Earn rewards when someone mints your link. `
            : `Connect your wallet to enable a referral link and earn rewards. `}
          <Anchor
            href="https://support.highlight.xyz/knowledge-base/highlight-toolkit/toolkit/mint-referrals"
            target="_blank"
            rel="noreferrer"
            size="xs"
            fw={500}
          >
            Learn more
          </Anchor>
        </Text>
      </Stack>
      <Space h="lg" />
      <Text component="label">Share on social</Text>
      <Space h="xs" />
      <Stack spacing={8}>
        <TwitterShareButton
          url={mintUrlWithCurrentUser}
          css={{ width: "100%" }}
        >
          <Button
            onClick={() => {
              if (!ampli.isLoaded) {
                return;
              }
              ampli.referEarn({
                projectName: collection.name,
                referralSelected: "postOnX",
              });
            }}
            fullWidth
            color={SECONDARY_COLOR}
            leftIcon={<XIcon />}
            size="lg"
          >
            Post on X
          </Button>
        </TwitterShareButton>

        <Box>
          {isFcTxMintEligible && (
            // TODO: need to find a staging project that is farcaster eligible to implement and test with
            <FarcasterSharedButton url={mintUrlWithCurrentUser}>
              Mint with ETH on {network.displayName}
            </FarcasterSharedButton>
          )}
          {isFcTxMintEligible && (
            <Box mt={8}>
              <Text size="xs" color={TEXT_COLOR.SECONDARY}>
                Learn more about{" "}
                <Anchor
                  href="https://support.highlight.xyz/knowledge-base/partnerships/farcaster-integration"
                  target="_blank"
                  rel="noreferrer"
                  size="xs"
                  fw={500}
                >
                  Farcaster frame options
                </Anchor>
              </Text>
            </Box>
          )}
        </Box>
      </Stack>
      <Divider my={16} mx={-16} />
      <CopyButton value={mintUrlWithCurrentUser}>
        {({ copied, copy }) => (
          <Button
            fullWidth
            onClick={() => {
              copy();
              if (ampli.isLoaded) {
                ampli.referEarn({
                  projectName: collection.name,
                  referralSelected: "copyLink",
                });
              }
            }}
            size="lg"
          >
            {copied ? "Copied!" : "Copy"}
          </Button>
        )}
      </CopyButton>
    </Box>
  );
};

const FarcasterSharedButton: React.FC<
  React.PropsWithChildren<{ url: string }>
> = ({ url, children }) => {
  const shareUrls = `https://warpcast.com/~/compose?embeds[]=${encodeURIComponent(
    url
  )}`;
  return (
    <Button
      size="lg"
      component="a"
      target="_blank"
      href={shareUrls}
      fullWidth
      color={SECONDARY_COLOR}
      leftIcon={<FarcasterIcon />}
    >
      {children}
    </Button>
  );
};

const ReferralButton: React.FC<ButtonData> = ({
  color = SECONDARY_COLOR,
  size,
  compact = false,
}) => {
  const { pushModal } = useModalStack();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs - 1}px)`);
  const {
    collection,
    isEnglishAuction,
    isCollectionInactive,
    chainId,
    mintVectors,
  } = useMintState();

  const hasNotFixedPriceSales = mintVectors.some(
    (vector) => !vector || vector.priceType !== PriceType.Fixed
  );

  const disableReferrals =
    !useFeatureFlags(FEATURE_FLAGS.ENABLE_REFERRALS) ||
    isCollectionInactive ||
    hasNotFixedPriceSales ||
    isEnglishAuction ||
    mintVectors.length === 0 ||
    !collection;

  if (disableReferrals) return <></>;

  const label = "Refer & earn";

  const handleOnClick = () =>
    pushModal(<ReferralModal data={{ chainId, collection }} />, {
      size: isMobile ? "xs" : "sm",
      title: (
        <Text size="md" fw={WEIGHT_BOLD}>
          {label}
        </Text>
      ),
    });

  if (compact) {
    return (
      <Button compact size={size} onClick={handleOnClick} color={color}>
        <Share03 height={16} width={16} />
      </Button>
    );
  }

  return (
    <Button
      size={size}
      onClick={handleOnClick}
      color={color}
      leftIcon={<Share03 />}
    >
      {label}
    </Button>
  );
};

export default ReferralButton;

export const AppendReferralButton: React.FC<PropsWithChildren<ButtonData>> = ({
  children,
  show = true,
  ...rest
}) => {
  if (!show) return <>{children}</>;
  return (
    <Grid gutter={4} w="100%" p={0} m={0} align="stretch">
      <Grid.Col pl={0} py={0} span="auto">
        {children}
      </Grid.Col>
      <Grid.Col pr={0} py={0} span="content">
        <ReferralButton {...rest} compact />
      </Grid.Col>
    </Grid>
  );
};
