import dayjs from "dayjs";
export const formatNumber = (number) => {
    const formatter = Intl.NumberFormat("en");
    return formatter.format(number);
};
export const formatDate = (date) => dayjs(date).format("MMM[.] D");
export const formatDateAndTime = (date) => dayjs(date).format("MMMM D[,] YYYY [at] hh:mma");
export const formatRelativeDateTime = (date1, date2, timeout, onZeroSecondsDiff) => {
    const diffInMs = new Date(Math.abs(date1 - date2)).getTime();
    const minMs = 60 * 1000;
    const hourMs = 60 * minMs;
    const dayMs = 24 * hourMs;
    const days = Math.floor(diffInMs / dayMs);
    const hours = Math.floor((diffInMs % dayMs) / hourMs);
    const mins = Math.floor(((diffInMs % dayMs) % hourMs) / minMs);
    const seconds = Math.round((((diffInMs % dayMs) % hourMs) % minMs) / 1000);
    let time = "";
    if (days > 0)
        time += `${days}d `;
    if (hours > 0)
        time += `${hours}h `;
    if (mins > 0)
        time += `${mins}m `;
    if (days + hours === 0)
        time += `${seconds}s`;
    if (days + hours + mins + seconds === 0) {
        setTimeout(() => onZeroSecondsDiff === null || onZeroSecondsDiff === void 0 ? void 0 : onZeroSecondsDiff(), timeout);
    }
    return time;
};
export const reactTimeAgoStyle = (onZeroSecondsDiff, timeout = 0) => ({
    labels: "mini",
    steps: [
        {
            format: (date, locale, options) => formatRelativeDateTime(date, options.now, timeout, onZeroSecondsDiff),
            getTimeToNextUpdate: (date, options) => {
                const time = date;
                if (options.future && time - options.now <= 0)
                    return undefined;
                return options.getTimeToNextUpdateForUnit("second");
            },
        },
    ],
});
