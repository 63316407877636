import { forwardRef, Ref } from "react";

import {
  pendingStates,
  TransactionState,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { Button, ButtonProps, Stack } from "@mantine/core";

import ErrorBox from "~features/MintPage/MintVector/ErrorBox";
import { AppendReferralButton } from "~features/share/ReferralButton";
import { useApprove1155 } from "~hooks/useApprove1155";
import useMintState, { MintStateRequired } from "~hooks/useMintState";

import { TransactionType } from "../../../apollo/graphql.generated";

export type CardButtonProps = {
  onMintClick: () => void;
  initialButtonLabel: string;
  showReferralLink?: boolean;
  showErrorIcon?: boolean;
} & Partial<ButtonProps>;

const getErrorMessage = (
  hasApproveError?: boolean,
  transactionState?: TransactionState
) => {
  if (hasApproveError) {
    return "Approval failed, please try again later";
  }
  if (
    transactionState?.error?.details?.includes("insufficient funds") ||
    transactionState?.error?.cause?.reason?.includes("amount exceeds balance")
  ) {
    return "Insufficient funds";
  }
  if (
    transactionState?.error?.cause?.reason?.includes("insufficient allowance")
  ) {
    return "Insufficient allowance";
  }
  if (transactionState?.error) {
    return "There was an error, please try again later";
  }
  return null;
};

const ApproveERC1155Button = forwardRef(
  (
    {
      onMintClick,
      initialButtonLabel,
      showErrorIcon = true,
      showReferralLink = false,
      ...rest
    }: CardButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const { collection, mintVector, userAllowed1155, txnId } =
      useMintState() as MintStateRequired;
    const transactionState = useTransactionState(txnId);

    const {
      buttonLoading,
      buttonLabel,
      error: approveError,
      approve,
      isDone,
    } = useApprove1155(
      collection.id,
      collection.collectionType,
      mintVector,
      onMintClick
    );

    const mintTxPending = transactionState?.type
      ? pendingStates.includes(transactionState.type) &&
        transactionState.transactionType === TransactionType.EVM_721_MINT
      : false;
    const burnTxPending = transactionState?.type
      ? transactionState.transactionType ===
          TransactionType.EVM_CROSSCHAIN_BURN && !transactionState.redeemReceipt
      : false;
    const isLoading =
      rest.disabled ||
      (!isDone && buttonLoading) ||
      mintTxPending ||
      burnTxPending;
    const label =
      (mintTxPending ? "Minting in progress..." : !isDone && buttonLabel) ||
      initialButtonLabel;
    const error = getErrorMessage(!!approveError, transactionState);

    return (
      <Stack spacing={8} w="100%" align="center">
        <AppendReferralButton size={rest.size} show={showReferralLink}>
          <Button
            ref={ref}
            {...rest}
            disabled={isLoading}
            onClick={userAllowed1155 ? onMintClick : approve}
            loading={isLoading}
          >
            {label}
          </Button>
        </AppendReferralButton>
        {error && <ErrorBox small={!showErrorIcon} message={error} />}
      </Stack>
    );
  }
);

export default ApproveERC1155Button;
