import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { TransactionType } from "@hl/shared-features/lib/apollo/graphql.generated";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import {
  useAuthChangedCallback,
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import {
  TransactionStateType,
  useTransactionsDispatch,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { useMarketplaceSettings } from "@hl/shared-features/lib/features/marketplace/provider";
import { createArweaveUrl } from "@hl/shared-features/lib/utils/arweave";
import { networkLookup } from "@hl/shared-features/lib/utils/blockExplorer";
import {
  isOpenSeaSupported,
  viewCollectionOpenSeaUrl,
} from "@hl/shared-features/lib/utils/opensea";
import { ethers, FixedNumber } from "ethers";

import importedCollectionPlatform from "~features/MintPage/utils/imported-collections";
import { SaleStatus } from "~features/MintPage/utils/types";
import { useAllowance1155 } from "~hooks/useAllowance1155";
import { useAllowanceErc20 } from "~hooks/useAllowanceErc20";
import { useBalance1155 } from "~hooks/useBalance1155";
import { useBalanceErc20 } from "~hooks/useBalanceErc20";
import useRedeemRelayerTaskTx from "~hooks/useRedeemRelayerTx";
import { ContentTypeInfo, getContentTypeInfo } from "~utils/contentType";

import {
  _CollectionType,
  CollectionContractType,
  CollectionStatus,
  CurrencyType,
  EditionToken,
  GeneralToken,
  MintVectorStatus,
  PriceType,
  SponsoredMintClaimInput,
} from "../apollo/graphql.generated";
import {
  Auction,
  BaseToken,
  Collection,
  MintVector,
} from "../features/MintPage";
import { getAuctionStatus } from "../features/MintPage/Auction/utils";
import { getDisabledCardText } from "../features/MintPage/MintVector/MintCard";
import { getPrice } from "../features/MintPage/hooks/useCardSaleData";
import {
  GetSalePageMintVectorsQuery,
  useAuctionBidMutation,
  useClaimMintMutation,
  useSponsoredClaimMintMutation,
  useGetCollectionCreatorDetailsQuery,
  useGetCollectionDetailsQuery,
  useGetCollectionPropertiesQuery,
  useGetCollectionSaleDetailsQuery,
  useGetSalePageAuctionsQuery,
  useGetSalePageCollectionQuery,
  useGetSalePageMintVectorsQuery,
  useSeriesClaimMintMutation,
  useCrosschainBurnMutation,
} from "../features/MintPage/queries.graphql.generated";
import { emptyEdition } from "../features/MintPage/utils/objects";
import {
  getSaleStatus,
  getUserStatus,
} from "../features/MintPage/utils/statuses";
import { MintingToken } from "../features/claim-mints/vars";
import { checkIfMarketplaceEnabled } from "../utils/marketplace";

export const AUCTION_POLL_INTERVAL_MS = 10000;
export const SALE_POLL_INTERVAL_MS = 5000;

type SelectedSeriesToken = {
  imageUrl: string | null;
  minted?: boolean | null;
  id: string | null;
};

const emptyToken: GeneralToken = {
  id: "",
  name: "",
  description: "",
  image: "",
  animation_url: "",
  nonTransferable: false,
};

type Options = {
  skipAuction?: boolean;
  referrer?: string;
  isEmbed?: boolean;
};

export type EligibleRebate = {
  vectorId: string;
  rebateAmount: string;
};

export type MintState = ReturnType<typeof useMintPage>;

type SalePageMintVector =
  GetSalePageMintVectorsQuery["getMintVectorsByCollection"][0];

const LATEST_SPONSOR_ERROR_BUFFER_SECONDS = 40;

export const useMintPage = (
  collectionUrlId: string,
  { skipAuction, referrer, isEmbed }: Options = {}
) => {
  const settings = useMarketplaceSettings();
  const { walletAddress } = useAuth();
  const [seriesTokenSelected, setSeriesTokenSelected] =
    useState<SelectedSeriesToken>();
  const [contentTypeInfo, setContentTypeInfo] =
    useState<ContentTypeInfo | null>(null);
  const [numTokensToMint, setNumTokensToMint] = useState<number>(1);
  const [mintData, setMintData] = useState<string | undefined>(undefined);
  const [mintKey, setMintKey] = useState<string>();
  const [mintingSeriesToken, setMintingSeriesToken] =
    useState<MintingToken | null>(null);
  const genSeriesIframeElementRef = useRef<HTMLIFrameElement>(null);
  const mintSaleContentRef = useRef<HTMLDivElement>(null);
  const appHeaderRef = useRef<HTMLDivElement>(null);
  const [mintDataLoading, setMintDataLoading] = useState(false);

  const isOptimizedLoading = useFeatureFlags(
    FEATURE_FLAGS.MINT_PAGE_LOAD_OPTIMIZATION
  );
  const enableCreatorRewards = useFeatureFlags(
    FEATURE_FLAGS.ENABLE_CREATOR_REWARDS
  );

  const {
    data: collectionData,
    loading: collectionLoading,
    refetch: refetchCollection,
    error: collectionError,
  } = useGetSalePageCollectionQuery({
    variables: {
      collectionId: collectionUrlId,
      withEns: true,
    },
    skip: isOptimizedLoading == null || isOptimizedLoading == true,
  });

  const {
    data: collectionDetailsData,
    loading: collectionDetailsLoading,
    refetch: refetchCollectionDetails,
    error: collectionDetailsError,
  } = useGetCollectionDetailsQuery({
    variables: {
      collectionId: collectionUrlId,
    },
    skip: !isOptimizedLoading,
  });

  const {
    data: mintVectorsData,
    loading: mintVectorsLoading,
    refetch: refetchMintVector,
  } = useGetSalePageMintVectorsQuery({
    variables: {
      collectionId: collectionUrlId,
    },
    skip: isOptimizedLoading == null || isOptimizedLoading == true,
  });

  const {
    data: collectionSaleDetailsData,
    loading: collectionSaleDetailsLoading,
    startPolling: startPollingSale,
    stopPolling: stopPollingSale,
    refetch: refetchCollectionSaleDetails,
  } = useGetCollectionSaleDetailsQuery({
    variables: {
      collectionId: collectionUrlId,
    },
    skip: !isOptimizedLoading,
  });

  const { data: collectionPropertiesData } = useGetCollectionPropertiesQuery({
    variables: {
      collectionId: collectionUrlId,
    },
    skip: !isOptimizedLoading,
  });

  const { data: collectionCreatorDetailsData } =
    useGetCollectionCreatorDetailsQuery({
      variables: {
        withEns: true,
        collectionId: collectionUrlId,
      },
      skip: !isOptimizedLoading,
    });

  const {
    data: auctionsData,
    loading: auctionsLoading,
    refetch: refetchAuction,
    startPolling: startPollingAuction,
    stopPolling: stopPollingAuction,
  } = useGetSalePageAuctionsQuery({
    variables: {
      collectionId: collectionUrlId,
    },
    skip: skipAuction,
  });

  useEffect(() => {
    if (
      skipAuction ||
      !auctionsData?.getAuctionsByCollection[0] ||
      // Poll only when auction is active. When timer hits the start or end of auction, it'll be refreshed.
      new Date(auctionsData.getAuctionsByCollection[0].end) <= new Date() ||
      new Date(auctionsData.getAuctionsByCollection[0].start) >= new Date()
    ) {
      stopPollingAuction();
    } else {
      startPollingAuction(AUCTION_POLL_INTERVAL_MS);
    }
  }, [startPollingAuction, stopPollingAuction, skipAuction, auctionsData]);

  const isNotFound = [
    ...(collectionError?.graphQLErrors ?? []),
    ...(collectionDetailsError?.graphQLErrors ?? []),
  ].find((x) => x.extensions?.status === 404);
  const isServerError =
    !isNotFound &&
    (collectionError?.graphQLErrors || collectionDetailsError?.graphQLErrors);

  const collection: Collection | undefined = useMemo(() => {
    // Temporal code to maintain optimized and not optimized versions
    if (isOptimizedLoading && collectionDetailsData) {
      const { editions, ...rest } =
        collectionDetailsData.getPublicCollectionDetails;
      const collectionProperties =
        collectionPropertiesData?.getPublicCollectionDetails;
      const creatorDetails =
        collectionCreatorDetailsData?.getPublicCollectionDetails;
      const saleDetails = collectionSaleDetailsData?.getPublicCollectionDetails;
      const saleEdition = saleDetails?.edition;
      const editionProperties = collectionProperties?.edition;

      return {
        ...rest,
        editions: editions
          ? editions.map((edition) => ({
              ...edition,
              size: saleEdition?.size || 0,
              remainingSupply: saleEdition?.remainingSupply || 0,
              onChainMetadata: saleEdition?.onChainMetadata || "",
              nonTransferable: editionProperties?.nonTransferable || false,
              royalty: editionProperties?.royalty || null,
            }))
          : null,
        supply: null,
        openSeaCollectionUrl: null,
        usingOpenseaBlocklist: null,
        tokens: null,
        royalty: collectionProperties?.royalty || null,
        creatorAddresses: creatorDetails?.creatorAddresses || null,
        creatorEns: creatorDetails?.creatorEns || null,
        creatorAccountSettings: creatorDetails?.creatorAccountSettings || null,
      };
    }
    return collectionData?.getPublicCollection;
  }, [
    isOptimizedLoading,
    collectionData,
    collectionDetailsData,
    collectionPropertiesData,
    collectionCreatorDetailsData,
    collectionSaleDetailsData,
  ]);
  const collectionId = collection?.id;

  const marketplaceId =
    collection?.marketplaceId ??
    (collection?.chainId && collection.address
      ? `${collection?.chainId}:${collection?.address}`
      : null);
  const reservoirCollectionId =
    marketplaceId?.split(/:/).slice(1).join(":") || collection?.address;

  const auction: Auction = auctionsData?.getAuctionsByCollection[0];

  const mintVectors: MintVector[] = useMemo(() => {
    if (isOptimizedLoading)
      return (
        collectionSaleDetailsData?.getPublicCollectionDetails.mintVectors || []
      );
    return mintVectorsData?.getMintVectorsByCollection || [];
  }, [isOptimizedLoading, mintVectorsData, collectionSaleDetailsData]);

  const saleDetails = collectionSaleDetailsData?.getPublicCollectionDetails;

  const size = useMemo(() => {
    if (isOptimizedLoading) return saleDetails?.size;
    return collection?.size;
  }, [isOptimizedLoading, collection, saleDetails]);

  const supply = useMemo(() => {
    if (isOptimizedLoading) return saleDetails?.supply;
    return collection?.supply;
  }, [isOptimizedLoading, collection, saleDetails]);

  const totalMintEarned = useMemo(() => {
    return (
      Math.round(
        mintVectors.reduce((p, vector) => {
          let vectorEarned = vector?.mintVectorStats?.earned
            ? parseFloat(vector.mintVectorStats?.earned ?? "0") *
              parseFloat(vector.ethRate)
            : 0;
          if (
            vector?.priceType === PriceType.DutchAuction &&
            !vectorEarned &&
            vector.mintVectorStats?.status == MintVectorStatus.Ended
          ) {
            const priceDrops = vector.priceDrops ?? [];
            if (priceDrops[priceDrops.length - 1].active) {
              vectorEarned =
                parseFloat(priceDrops[priceDrops.length - 1].price) *
                (vector.mintVectorStats?.sold || 0);
            }
          }
          return p + vectorEarned;
        }, 0) * 10000
      ) / 10000
    );
  }, [mintVectors]);

  useEffect(() => {
    if (collection?.chainId && settings) {
      settings.setNetworkId(collection.chainId);
    }
  }, [collection, settings]);

  /**
   * If not signed in show public mint or gated mint
   * If signed in
   * If no sales have been created show this card
   * Has to be active
   * Find cheapest that they are eligible for
   * - Hasn’t exceeded mints per wallet
   * - Pick current Dutch auction price
   * Then sort by alphabetical
   */
  const mintVector: MintVector = useMemo(() => {
    const now = new Date().toISOString();
    const isMintVectorActive = (vector: SalePageMintVector) => {
      if (vector == null) return false;
      if (vector.paused === true) return false;
      if (now < vector.start) return false;

      if (vector.sponsored) {
        // no mints sponsored yet
        if (!vector.maxPerVector) return false;
        // even if the public vector end date is passed, if there are sponsored mints left, they should be claimable
        if (
          vector.mintVectorStats &&
          vector.mintVectorStats.sold < vector.maxPerVector
        )
          return true;
      }
      if (vector.end != null && now > vector.end) return false;

      if (vector.mintVectorStats != null) {
        if (vector.maxPerVector !== 0) {
          if (vector.mintVectorStats.sold >= vector.maxPerVector) return false;
        }
      }
      return true;
    };

    const isUserLocked = (vector: SalePageMintVector) => {
      return vector.userGateAccess != null && !vector.userGateAccess.passed;
    };

    const isSponsoredSale = (vector: SalePageMintVector) => {
      return vector.sponsored;
    };

    const isLatestSponsorErrorRecent = (vector: SalePageMintVector) => {
      return (
        vector.sponsoredLatestErrorTimestamp &&
        Math.floor(Date.now() / 1000) - vector.sponsoredLatestErrorTimestamp <
          LATEST_SPONSOR_ERROR_BUFFER_SECONDS
      );
    };

    const hasUserExceedLimit = (vector: SalePageMintVector) => {
      if (vector.mintVectorStats != null) {
        if (vector.maxPerUser !== 0) {
          if (
            (vector.mintVectorStats.claimedByCurrentUser ?? 0) >=
            vector.maxPerUser
          )
            return true;
        }
      }
      return false;
    };

    const getPriceNumber = (mintVector: SalePageMintVector) => {
      const price = getPrice(mintVector) ?? "0";
      const ethRate = Number(mintVector.ethRate ?? "1").toFixed(20);
      return FixedNumber.from(price).mulUnsafe(
        FixedNumber.from(ethRate.toString())
      );
    };

    const sorted = [...mintVectors].sort((a, b) => {
      if (a == null || b == null) return 0;
      try {
        const isActiveSaleA = isMintVectorActive(a);
        const isActiveSaleB = isMintVectorActive(b);

        if (isActiveSaleA && !isActiveSaleB) return -1;
        if (!isActiveSaleA && isActiveSaleB) return 1;

        const isUserLockedSaleA = isUserLocked(a);
        const isUserLockedSaleB = isUserLocked(b);

        if (isUserLockedSaleA && !isUserLockedSaleB) return 1;
        if (!isUserLockedSaleA && isUserLockedSaleB) return -1;

        const hasUserExceedLimitSaleA = hasUserExceedLimit(a);
        const hasUserExceedLimitSaleB = hasUserExceedLimit(b);

        if (hasUserExceedLimitSaleA && !hasUserExceedLimitSaleB) return 1;
        if (!hasUserExceedLimitSaleA && hasUserExceedLimitSaleB) return -1;

        const isSponsoredSaleA = isSponsoredSale(a);
        const isSponsoredSaleB = isSponsoredSale(b);

        if (isSponsoredSaleA && !isSponsoredSaleB) {
          if (isLatestSponsorErrorRecent(a)) return 1;
          return isActiveSaleA ? -1 : 1;
        }
        if (!isSponsoredSaleA && isSponsoredSaleB) {
          if (isLatestSponsorErrorRecent(b)) return -1;
          return isActiveSaleB ? 1 : -1;
        }

        const priceA = getPriceNumber(a);
        const priceB = getPriceNumber(b);

        if (priceA === priceB) {
          return a.name?.localeCompare(b.name ?? "") || 0;
        } else {
          if (priceA < priceB) {
            return -1;
          }
          return 1;
        }
      } catch (error) {
        console.error(error);
        return 0;
      }
    });
    return sorted.at(0);
  }, [mintVectors]);

  useEffect(() => {
    if (
      mintVector?.priceType === PriceType.DutchAuction &&
      mintVector.mintVectorStats?.status === MintVectorStatus.InProgress
    ) {
      startPollingSale(SALE_POLL_INTERVAL_MS);
    } else {
      stopPollingSale();
    }
  }, [startPollingSale, stopPollingSale, mintVector]);

  const [mintingSeriesTokenId, setMintingSeriesTokenId] = useState<string>();

  const tokens = useMemo(() => {
    let tokens: BaseToken[] = [
      {
        name: emptyToken.name,
        description: emptyToken.description,
        image: emptyToken.image,
        onChainImage: emptyToken.image,
        animation_url: emptyToken.animation_url,
        size: 0,
        nonTransferable: false,
      },
    ];
    if (collection?.editions) {
      tokens = collection.editions.map((t) => ({
        name: t.name,
        description: t.description,
        image: t.image,
        onChainImage: t.onChainImage,
        animation_url: t.animation,
        size: t.size,
        nonTransferable: t.nonTransferable ?? false,
      }));
    }
    if (collection?.tokens?.length) {
      tokens = collection.tokens.map((t) => ({
        name: t.name,
        description: t.description,
        image: t.image,
        onChainImage: t.image,
        animation_url: t.animation_url,
        size: 0,
        nonTransferable: t.nonTransferable ?? false,
      }));
    }
    return tokens;
  }, [collection]);

  const creatorAddresses = collection?.creatorAddresses;

  const [
    claim,
    { data: claimMintData, error: claimMintError, loading: loadingClaimMint },
  ] = useClaimMintMutation();
  if (claimMintError) {
    // TODO: https://linear.app/highlight-xyz/issue/ENG-4118/[frontend]-use-new-alerts-on-mint-page-for-claim-mint-errors
    console.error(claimMintError);
  }

  const [
    crosschainBurnFn,
    {
      data: crosschainBurnData,
      loading: loadingCrosschain,
      error: crosschainError,
    },
  ] = useCrosschainBurnMutation();
  if (crosschainError) {
    // TODO: https://linear.app/highlight-xyz/issue/ENG-4118/[frontend]-use-new-alerts-on-mint-page-for-claim-mint-errors
    console.error(crosschainError);
  }
  const [
    seriesClaim,
    {
      data: seriesClaimMintData,
      error: seriesClaimMintError,
      loading: loadingSeriesClaimMint,
    },
  ] = useSeriesClaimMintMutation();
  if (seriesClaimMintError) {
    // TODO: https://linear.app/highlight-xyz/issue/ENG-4118/[frontend]-use-new-alerts-on-mint-page-for-claim-mint-errors
    console.error(claimMintError);
  }

  const [
    sponsoredClaim,
    {
      data: sponsoredClaimMintData,
      error: sponsoredClaimMintError,
      loading: loadingSponsoredClaimMint,
    },
  ] = useSponsoredClaimMintMutation();
  if (sponsoredClaimMintError) {
    // TODO: https://linear.app/highlight-xyz/issue/ENG-4118/[frontend]-use-new-alerts-on-mint-page-for-claim-mint-errors
    console.error(sponsoredClaimMintError);
  }

  const [
    creditCardClaim,
    { data: ccClaimData, error: ccClaimError, loading: loadingCCClaim },
  ] = useClaimMintMutation();
  if (ccClaimError) {
    console.error();
  }

  const [
    bid,
    {
      data: auctionBidData,
      error: auctionBidError,
      loading: loadingAuctionBid,
    },
  ] = useAuctionBidMutation();
  if (auctionBidError) {
    console.error(auctionBidError);
  }

  const getNewTxnId = () => `txn-${Date.now()}`;
  const [txnId, setTxnId] = useState<string>(getNewTxnId());
  const transactionState = useTransactionState(txnId);
  const transactionDispatch = useTransactionsDispatch();

  const [ccId, setCCId] = useState<string>(getNewTxnId());
  useRedeemRelayerTaskTx(txnId, transactionState);

  const isCodeGenMint =
    collection?.collectionType === _CollectionType.GenerativeSeries;
  const collectionType = collection?.collectionType ?? _CollectionType.Series;
  const isSeriesMint = collection?.collectionType === _CollectionType.Series;
  const isSingleContractEdition =
    collection?.collectionType === _CollectionType.LimitedEdition ||
    collection?.collectionType === _CollectionType.OneOfOne;
  const isAnySeriesMint = isCodeGenMint || isSeriesMint;
  const isImported = collection?.type === CollectionContractType.Imported;
  const isCollectorChoiceMint =
    isSeriesMint && !collection?.reveal && !isImported;
  const isSponsoredMint = mintVector && mintVector.sponsored;
  const edition: EditionToken = collection?.editions?.[0] ?? emptyEdition;
  const enableBurnAndRedeem =
    !!mintVector?.customProjectData?.burnRedeem?.burn1155Address;
  const enableMintGenSeriesControls =
    collection?.flagVariations.enableMintGenSeriesControls ||
    enableBurnAndRedeem;

  const sponsorVector = useMemo(() => {
    return mintVectors.find(
      (vector) => vector?.sponsored === true && !vector?.gateId
    );
  }, [mintVectors]);

  const totalSoldCount = useMemo(() => {
    return (
      supply ??
      (isAnySeriesMint
        ? mintVectors.reduce((p, v) => {
            return p + (v?.mintVectorStats?.sold ?? 0);
          }, 0)
        : edition.remainingSupply != null
        ? edition.size - edition.remainingSupply
        : 0)
    );
  }, [mintVectors, supply, edition, isAnySeriesMint]);
  const totalSize = useMemo(() => {
    return (
      size ??
      (isAnySeriesMint
        ? mintVectors.reduce((p, v) => {
            return p + (v?.mintVectorStats?.total ?? 0);
          }, 0)
        : edition.size)
    );
  }, [mintVectors, size, edition, isAnySeriesMint]);

  const totalRemainingSupply = useMemo(() => {
    return totalSize - totalSoldCount;
  }, [totalSoldCount, totalSize]);

  // capture rebate information for first vector with eligible rebates
  const eligibleRebate: EligibleRebate | null = useMemo(() => {
    for (const rebatePotentialVector of mintVectors) {
      if (
        rebatePotentialVector?.id &&
        rebatePotentialVector?.isDirectMint &&
        rebatePotentialVector?.priceType === PriceType.DutchAuction &&
        rebatePotentialVector?.mintVectorStats?.dutchAuctionRebateWei &&
        ethers.BigNumber.from(
          rebatePotentialVector?.mintVectorStats?.dutchAuctionRebateWei
        ).gt(0) &&
        (rebatePotentialVector?.mintVectorStats?.onchainDutchAuctionStats
          ?.inFPP ||
          rebatePotentialVector?.mintVectorStats?.onchainDutchAuctionStats
            ?.exhausted)
      ) {
        return {
          vectorId: rebatePotentialVector.id,
          rebateAmount: ethers.utils.formatEther(
            rebatePotentialVector.mintVectorStats.dutchAuctionRebateWei
          ),
        };
      }
    }
    return null;
  }, [mintVectors]);
  const isPossibleRebate = useMemo(() => {
    if (eligibleRebate) {
      return false;
    }
    const now = Date.now();
    for (const vector of mintVectors) {
      if (!vector || !vector.isDirectMint) {
        continue;
      }
      if (
        vector.priceType === PriceType.DutchAuction &&
        vector.mintVectorStats?.claimedByCurrentUser &&
        vector.mintVectorStats?.status == MintVectorStatus.InProgress
      ) {
        const priceDrops = vector.priceDrops ?? [];
        const nextPriceIndex = priceDrops.findIndex(
          (x) => new Date(x.end).getTime() > now
        );
        if (nextPriceIndex !== priceDrops.length - 1) {
          // user may be eligible for rebate, but resting price is not reached yet
          return true;
        }
      }
    }
    return false;
  }, [mintVectors, eligibleRebate]);

  const nonTransferable =
    tokens[0].nonTransferable ?? collection?.nonTransferable ?? null;

  const isFreeMint = useMemo(
    () =>
      mintVector?.price && enableCreatorRewards
        ? +mintVector.price === 0
        : false,
    [enableCreatorRewards, mintVector?.price]
  );

  const isNativeCurrencyMint =
    mintVector?.paymentCurrency?.type === CurrencyType.NATIVE;

  const isErc20CurrencyMint =
    mintVector?.paymentCurrency?.type === CurrencyType.ERC20;

  const {
    currencyAmountToAllow,
    hasSufficientAllowance,
    loading: loadingUserCurrencyAllowance,
    refetch: refetchAllowance,
  } = useAllowanceErc20(numTokensToMint, mintVector);
  const { userBalance: userBalanceDegen } = useBalanceErc20(mintVector);
  const { userAllowed1155, loading: loadingUserCurrencyAllowance1155 } =
    useAllowance1155(enableBurnAndRedeem, mintVector);
  const { userBurnBalance1155, hasEnoughToBurnAndRedeem } = useBalance1155(
    enableBurnAndRedeem,
    mintVector
  );
  const burnChainId =
    mintVector?.customProjectData?.burnRedeem?.crosschain?.burnChainId;
  const isCrossChainBurn = !!burnChainId;

  const handleMint = useCallback(
    async (
      fromChain: number,
      tokenIdNum?: string | null,
      imageUrl?: string
    ) => {
      if (!mintVector?.id) {
        throw new Error("MintVector without id");
      }
      let relayerTaskId;
      if (isSponsoredMint) {
        let claimInput: SponsoredMintClaimInput;
        if (isCollectorChoiceMint) {
          if (!tokenIdNum || !imageUrl) {
            throw Error("wrong state, tokenId or image are not provided");
          }
          setMintingSeriesToken({ id: tokenIdNum, imageUrl });
          claimInput = { tokenIds: [parseInt(tokenIdNum)] };
        } else {
          claimInput = { numTokensToMint };
        }

        const claimDataResult = await sponsoredClaim({
          variables: {
            vectorId: mintVector?.id,
            data: claimInput,
          },
        });
        relayerTaskId =
          claimDataResult?.data?.sponsoredClaimMint?.claim?.relayerTaskId;
      } else if (isCrossChainBurn) {
        if (!mintData) {
          throw Error("wrong state, mintData must be present");
        }
        await crosschainBurnFn({
          variables: {
            vectorId: mintVector?.id,
            data: { mintData, numTokensToMint, referrer },
          },
        });
      } else if (isCollectorChoiceMint) {
        if (!tokenIdNum || !imageUrl) {
          throw Error("wrong state, tokenId or image are not provided");
        }
        setMintingSeriesToken({ id: tokenIdNum, imageUrl });
        await seriesClaim({
          variables: {
            vectorId: mintVector?.id,
            data: { tokenIds: [parseInt(tokenIdNum)], referrer },
          },
        });
      } else {
        if (enableMintGenSeriesControls && !mintData) {
          throw new Error("Mint data should be present");
        }

        await claim({
          variables: {
            vectorId: mintVector?.id,
            data: { numTokensToMint, mintData, referrer },
          },
        });
      }
      const txnId = getNewTxnId();

      transactionDispatch?.({
        type: "START_TRANSACTION",
        payload: {
          id: txnId,
          entityId: mintVector.id,
          transactionType: isCrossChainBurn
            ? TransactionType.EVM_CROSSCHAIN_BURN
            : TransactionType.EVM_721_MINT,
          collectionType,
          fromChain: burnChainId || fromChain,
          relayerTaskId,
        },
      });
      setTxnId(txnId);
    },
    [
      claim,
      mintVector?.id,
      numTokensToMint,
      isCollectorChoiceMint,
      seriesClaim,
      collectionType,
      transactionDispatch,
      mintKey,
      isSponsoredMint,
      mintData,
      enableMintGenSeriesControls,
      referrer,
    ]
  );

  const handleCreditCardClaim = useCallback(
    async (claimId: string) => {
      if (!mintVector?.id) {
        throw new Error("MintVector without id");
      }
      await creditCardClaim({
        variables: {
          vectorId: mintVector?.id,
          data: { numTokensToMint, mintData },
        },
      });
      setCCId(claimId);
    },
    [creditCardClaim, mintVector?.id, numTokensToMint, mintData]
  );

  const handleBid = useCallback(
    async (amount: string) => {
      if (!auction?.id) {
        throw new Error("Auction without id");
      }

      await bid({
        variables: { auctionId: auction.id, data: { amount } },
      });
      const txnId = getNewTxnId();
      transactionDispatch?.({
        type: "START_TRANSACTION",
        payload: {
          id: txnId,
          entityId: auction.id,
          transactionType: TransactionType.EVM_721_BID,
          collectionType,
        },
      });
      setTxnId(txnId);
    },
    [bid, auction?.id, collectionType, transactionDispatch]
  );

  const claimMint =
    claimMintData?.claimMint ?? seriesClaimMintData?.seriesClaimMint;

  const crosschainBurn = crosschainBurnData?.crosschainBurn;

  const ccClaim = ccClaimData
    ? {
        ...ccClaimData.claimMint.creditCard,
        claimId: ccId,
      }
    : undefined;
  const sponsoredClaimMint = sponsoredClaimMintData?.sponsoredClaimMint;

  useEffect(() => {
    if (claimMint && mintVector?.id && collectionId && walletAddress) {
      setMintingSeriesTokenId(mintingSeriesToken?.id);
      transactionDispatch?.({
        type: "UPDATE_TX_ARGS",
        payload: {
          id: txnId,
          args: claimMint.contract,
          metadata: {
            claimId: claimMint.claim.id,
            mintVectorId: mintVector.id,
            editionId: Number(collection?.editionId ?? "0"),
            collectionId,
            nftContractAddress: walletAddress,
            numTokens: numTokensToMint,
          },
        },
      });
    }
  }, [claimMint, mintVector?.id, walletAddress, mintingSeriesToken]);

  useEffect(() => {
    if (
      crosschainBurn &&
      mintVector?.id &&
      collectionId &&
      walletAddress &&
      burnChainId
    ) {
      const burnId = JSON.parse(crosschainBurn.contract.args)[1];
      transactionDispatch?.({
        type: "UPDATE_TX_ARGS",
        payload: {
          id: txnId,
          args: crosschainBurn.contract,
          metadata: {
            burnId,
            claimId: crosschainBurn.claim.id,
            mintVectorId: mintVector.id,
            editionId: Number(collection?.editionId ?? "0"),
            collectionId,
            nftContractAddress: walletAddress,
            numTokens: numTokensToMint,
            chainId: burnChainId.toString(),
          },
        },
      });
    }
  }, [crosschainBurn, mintVector?.id, walletAddress, mintVector]);

  useEffect(() => {
    if (sponsoredClaimMint && mintVector?.id && collectionId && walletAddress) {
      transactionDispatch?.({
        type: "UPDATE_TX_ARGS",
        payload: {
          id: txnId,
          args: sponsoredClaimMint.contract,
          relayerTaskId: sponsoredClaimMint.claim.relayerTaskId,
          metadata: {
            claimId: sponsoredClaimMint.claim.id,
            mintVectorId: mintVector.id,
            editionId: Number(collection?.editionId ?? "0"),
            collectionId,
            nftContractAddress: walletAddress,
            numTokens: numTokensToMint,
          },
        },
      });
    }
  }, [sponsoredClaimMint, mintVector?.id, walletAddress]);

  const handleRefetchMintVector = useCallback(() => {
    if (mintVectorsData && !mintVectorsLoading) {
      refetchMintVector();
    } else if (collectionSaleDetailsData && !collectionSaleDetailsLoading) {
      refetchCollectionSaleDetails();
    }
  }, [
    mintVectorsData,
    mintVectorsLoading,
    refetchMintVector,
    collectionSaleDetailsData,
    collectionSaleDetailsLoading,
    refetchCollectionSaleDetails,
  ]);
  // refetch when authenticated state changes
  useAuthChangedCallback(handleRefetchMintVector);

  useEffect(() => {
    if (auctionBidData && auction?.id && collectionId && walletAddress) {
      transactionDispatch?.({
        type: "UPDATE_TX_ARGS",
        payload: {
          id: txnId,
          args: auctionBidData.auctionBid.contract,
          metadata: {
            bidId: auctionBidData.auctionBid.bid.id,
            auctionId: auction.id,
            collectionId,
            nftContractAddress: walletAddress,
            tokenId: 1,
          },
        },
      });
    }
  }, [auctionBidData, auction?.id]);

  const collectionStatus = collection?.status ?? CollectionStatus.IN_PROGRESS;
  const bidError = auctionBidError?.graphQLErrors[0];
  const address = collection?.address || "";

  const refetch = useCallback(async () => {
    // Refetch in parallel (without await)
    if (isOptimizedLoading) {
      refetchCollectionDetails();
      refetchCollectionSaleDetails();
    } else {
      refetchCollection();
      refetchMintVector();
    }
    refetchAuction();
  }, [
    refetchCollection,
    refetchMintVector,
    refetchAuction,
    refetchCollectionDetails,
    refetchCollectionSaleDetails,
    isOptimizedLoading,
  ]);

  const animationUrl = tokens[0].animation_url;
  useEffect(() => {
    async function fetchContentType() {
      if (animationUrl) {
        setContentTypeInfo(await getContentTypeInfo(animationUrl));
      }
    }

    fetchContentType();
  }, [animationUrl]);

  const isActiveMint =
    !!transactionState &&
    transactionState.transactionType === TransactionType.EVM_721_MINT &&
    ![TransactionStateType.Done, TransactionStateType.SignTxRejected].includes(
      transactionState.type
    );

  const totalMintsLeft =
    size != null && size > 0 ? size - totalSoldCount : Infinity;
  const vectorMintsLeft =
    mintVector?.mintVectorStats != null
      ? mintVector.mintVectorStats.total === 0
        ? totalMintsLeft // Infinity -> but can't have more than totalMintsLeft
        : mintVector.mintVectorStats.total - mintVector.mintVectorStats.sold
      : 0; // If there is no vector you can't mint

  const numSeriesTotalMintsLeft = Math.min(totalMintsLeft, vectorMintsLeft);
  const isSoldOut = numSeriesTotalMintsLeft === 0;

  const numberOfMintOnCurrentWallet =
    mintVector?.mintVectorStats?.claimedByCurrentUser || 0;
  const userStatus = getUserStatus(
    numberOfMintOnCurrentWallet,
    mintVector?.maxPerUser || 0
  );
  const saleDateTo = mintVector?.end ? new Date(mintVector.end) : null;
  const maxPerVector = mintVector?.maxPerVector || 0;
  const maxPerUser = mintVector?.maxPerUser || 0;
  const maxPerTransaction =
    collection?.flagVariations.mintPageLimitMintsPerTransaction ?? 0;

  const numMintsLeftPerUser = Math.min(
    numSeriesTotalMintsLeft,
    maxPerTransaction === 0 ? Infinity : maxPerTransaction,
    maxPerVector === 0 ? Infinity : maxPerVector,
    maxPerUser > 0
      ? maxPerUser - numberOfMintOnCurrentWallet
      : 1000 /* default to this number for max mints allowed per tx if unlimited */
  );

  const saleStatus = getSaleStatus(
    edition,
    mintVector,
    numSeriesTotalMintsLeft,
    isAnySeriesMint
  );
  const disabledCardText = getDisabledCardText(saleStatus, saleDateTo);
  const isMarketplaceEnabledForCollectionChain = checkIfMarketplaceEnabled(
    collection?.chainId
  );
  const isOpenSeaSupportedForCollectionChain =
    !!collection?.chainId &&
    isOpenSeaSupported(networkLookup(collection.chainId).type);

  const endedOrSoldOut =
    saleStatus === SaleStatus.ENDED ||
    saleStatus === SaleStatus.SOLD_OUT ||
    isImported;
  const isMarketplaceNoticeShown =
    (saleStatus === SaleStatus.ENDED || saleStatus === SaleStatus.SOLD_OUT) &&
    isAnySeriesMint &&
    isMarketplaceEnabledForCollectionChain;
  const auctionStatus = getAuctionStatus(walletAddress, auction);
  const auctionDateTo = auction?.end ? new Date(auction.end) : null;
  const isAuctionLocked =
    !!auction?.gateId &&
    (!auction?.userGateAccess || !auction?.userGateAccess?.passed);

  const openSeaCollectionUrl = !!collection
    ? collection?.openSeaCollectionUrl ??
      viewCollectionOpenSeaUrl(collection.address, collection.chainId)
    : "";
  const generateArweaveLink = (tokenOnChainImage?: string) =>
    createArweaveUrl({
      collectionType: collection?.collectionType,
      genSeriesMirroredBasedUri:
        collection?.generativeDetails?.mirroredBaseUri ||
        collection?.generativeDetails?.generativeCodeUri ||
        "",
      isReveal: !!collection?.reveal,
      seriesMirroredBaseUri: collection?.seriesDetails?.mirroredBaseUri || "",
      onChainBaseUri: collection?.onChainBaseUri || "",
      onChainImage: tokenOnChainImage,
    }) || "";

  const arweaveLink = useMemo(() => generateArweaveLink(), [collection]);
  const price = mintVector ? getPrice(mintVector) : undefined;

  const isRebatesDisabled = false;
  // TODO: implement this logic once private DA with rebates feature is done
  // const isRebatesDisabled =
  //   !isPossibleRebate &&
  //   !eligibleRebate &&
  //   mintVector?.priceType === PriceType.DutchAuction &&
  //   !mintVector?.isDirectMint;
  const importedPlatformInfo = importedCollectionPlatform(
    collection?.importData?.importedFromPlatform
  );

  const isEnglishAuction = !!auction;
  const isCollectionInactive = endedOrSoldOut || !!mintVector?.paused;

  return {
    collection,
    mintVector,
    mintVectors,
    auction,
    txnId,
    setMintKey,
    mintVectorsLoading:
      isOptimizedLoading == null ||
      collectionSaleDetailsLoading ||
      mintVectorsLoading,
    transactionState,
    auctionsLoading,
    loadingClaimMint:
      loadingClaimMint ||
      loadingSeriesClaimMint ||
      loadingSponsoredClaimMint ||
      loadingCCClaim ||
      loadingCrosschain,
    loadingAuctionBid,
    collectionLoading:
      isOptimizedLoading == null ||
      collectionLoading ||
      collectionDetailsLoading,
    creatorAddresses,
    handleMint,
    handleBid,
    handleCreditCardClaim,
    auctionBidError,
    auctionBidData,
    ccClaim,
    tokens,
    refetchAuction,
    refetch,
    contentTypeInfo,
    setContentTypeInfo,
    animationUrl,
    walletAddress,
    numTokensToMint,
    setNumTokensToMint,
    isSeriesMint,
    isCodeGenMint,
    isAnySeriesMint,
    isCollectorChoiceMint,
    isActiveMint,
    seriesTokenSelected,
    setSeriesTokenSelected,
    collectionStatus,
    bidError,
    address,
    numSeriesTotalMintsLeft,
    totalSoldCount,
    totalMintEarned, //TODO check ENG-7406
    totalSize,
    remainingSupply: totalRemainingSupply,
    isSoldOut,
    edition,
    numberOfMintOnCurrentWallet,
    userStatus,
    saleDateTo,
    numMintsLeft: numMintsLeftPerUser,
    maxPerTransaction,
    saleStatus,
    disabledCardText,
    auctionStatus,
    auctionDateTo,
    isAuctionLocked,
    mintingSeriesToken,
    price,
    mintingTokenId: mintingSeriesToken?.id,
    bid: auctionBidData?.auctionBid.bid,
    openSeaCollectionUrl,
    chainId: collection?.chainId ?? 0,
    chain: mintVector?.chain,
    mintingSeriesTokenId,
    eligibleRebate,
    isPossibleRebate,
    isRebatesDisabled,
    generateArweaveLink,
    arweaveLink,
    isNotFound,
    isServerError,
    isMarketplaceNoticeShown,
    isMarketplaceEnabledForCollectionChain,
    isOptimizedLoading,
    genSeriesIframeElementRef,
    mintSaleContentRef,
    appHeaderRef,
    setMintData,
    mintDataLoading,
    setMintDataLoading,
    isImported,
    importedPlatformInfo,
    marketplaceId,
    reservoirCollectionId,
    isFreeMint,
    currencyAmountToAllow,
    hasSufficientAllowance,
    userAllowed1155,
    isNativeCurrencyMint,
    isErc20CurrencyMint,
    userBalanceDegen,
    userBurnBalance1155,
    loadingUserCurrencyAllowance,
    loadingUserCurrencyAllowance1155,
    enableBurnAndRedeem,
    hasEnoughToBurnAndRedeem,
    endedOrSoldOut,
    mintFeeWaived:
      mintVector &&
      (mintVector.priceType != PriceType.Fixed || mintVector.sponsored),
    refetchAllowance,
    refetchMintVector: isOptimizedLoading
      ? refetchCollectionSaleDetails
      : refetchMintVector,
    isEnglishAuction,
    referrer,
    isCollectionInactive,
    collectionType,
    nonTransferable,
    isSponsoredMint,
    sponsorVector,
    isOpenSeaSupportedForCollectionChain,
    isSingleContractEdition,
    isEmbed,
    isCrossChainBurn,
  };
};
