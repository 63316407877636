import { useState } from "react";
import { encodeFunctionData } from "viem";
import { getMintingAbiFromMethodName } from "../evm-tx";
import { getCrossChainTxClient } from "./client";
export const useCrossChainTransaction = () => {
    const [txError, setTxError] = useState();
    const [txStatus, setTxStatus] = useState();
    const [crossChainId, setCrossChainId] = useState();
    const [destinationTx, setDestinationTx] = useState();
    const startCrossChainMintTransaction = async (fromChainId, args, walletClient) => {
        const abi = getMintingAbiFromMethodName(args.method);
        const value = args.value && args.value !== "0" ? args.value : undefined;
        const encodedData = encodeFunctionData({
            abi: abi,
            functionName: args.method,
            args: JSON.parse(args.args),
        });
        const data = await getCrossChainTxClient(fromChainId).actions.call({
            chainId: fromChainId,
            toChainId: args.chainId,
            txs: [{ to: args.address, value, data: encodedData }],
            // @ts-ignore
            wallet: walletClient,
            onProgress: (steps) => {
                var _a, _b;
                console.log("Steps are", { steps });
                if (steps &&
                    steps[0] &&
                    steps[0].items &&
                    steps[0].items[0].check &&
                    steps[0].items[0].check.endpoint) {
                    setCrossChainId(steps[0].items[0].check.endpoint.split("=")[1]);
                }
                if (steps && steps[0] && steps[0].items && steps[0].items[0].status) {
                    setTxStatus(steps[0].items[0].status);
                }
                if (steps &&
                    steps[0] &&
                    steps[0].items &&
                    steps[0].items[0].status === "complete" &&
                    steps[0].items[0].txHashes) {
                    setDestinationTx(steps[0].items[0].txHashes[0].txHash);
                }
                setTxError(((_a = steps === null || steps === void 0 ? void 0 : steps[0]) === null || _a === void 0 ? void 0 : _a.error) ? (_b = steps === null || steps === void 0 ? void 0 : steps[0]) === null || _b === void 0 ? void 0 : _b.error : undefined);
            },
        });
        console.log({ data });
    };
    return {
        crossChainId,
        txStatus,
        destinationTx,
        txError,
        startCrossChainMintTransaction,
    };
};
