import { useMemo } from "react";
import { useGetSupportedChainsQuery, } from "./configuration.graphql.generated";
export const useSupportedChains = (skip = false) => {
    const { data, loading, error } = useGetSupportedChainsQuery({ skip });
    const supportedChains = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.getSupportedChains) !== null && _a !== void 0 ? _a : []; }, [data === null || data === void 0 ? void 0 : data.getSupportedChains]);
    const getChainOptions = () => {
        const options = supportedChains.map((chain) => {
            return {
                label: chain.name,
                value: chain.chainId.toString(),
            };
        });
        options.unshift({ label: "Select Chain", value: "0" });
        return options;
    };
    return {
        loading,
        error,
        supportedChains,
        getChainOptions,
    };
};
export default useSupportedChains;
