import { ReactNode } from "react";

import MultilineText from "@hl/base-components/lib/MultilineText";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Video } from "@hl/base-components/lib/video";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import { MarketplaceToken } from "@hl/shared-features/lib/features/marketplace/utils";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { Box, Center, createStyles, Flex, Stack, Text } from "@mantine/core";

import { getDetailPageUrl } from "~config";
import { InternalLink } from "~features/MintPage/components/InternalLink";
import useMintState, { MintStateRequired } from "~hooks/useMintState";

import MarketplaceTokenButton from "./MarketplaceTokenButton";

const IMAGE_FIT_SIZE = 284;

const useTokenCardStyles = createStyles((theme) => ({
  mediaItem: {
    flex: "1 1",
    border: `20px solid ${theme.colors.tableRowBackground[0]}`,
    aspectRatio: "1",
    background: theme.colors.tableRowBackground[0],
  },
  video: {
    display: "block",
    objectFit: "contain",
    width: "100%",
  },
  link: {
    textDecoration: "none",
  },
}));

const TokenCard = ({
  token,
  onCompleted = () => null,
  imagePlaceholder,
  onImageError,
}: {
  token: MarketplaceToken;
  onCompleted?: () => void;
  imagePlaceholder?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
}) => {
  const { cx, classes } = useTokenCardStyles();
  const {
    isCodeGenMint,
    isSeriesMint,
    isCollectorChoiceMint,
    collection,
    isImported,
  } = useMintState() as MintStateRequired;
  const { popModal } = useModalStack();

  return (
    <Box pos="relative">
      {(isCodeGenMint || (isSeriesMint && !isCollectorChoiceMint)) &&
      !token.imageUrl ? (
        <PlaceholderMetadataPreview isCodeGenMint={isCodeGenMint} />
      ) : (
        <InternalLink
          className={classes.link}
          to={getDetailPageUrl(collection, token.tokenId)}
          onClick={popModal}
        >
          {token.animationUrl && !isCodeGenMint && !isImported ? (
            <Video
              className={cx([classes.video, classes.mediaItem])}
              autoPlay
              loop
              muted
              controls={false}
              playsInline
            >
              <source src={token.animationUrl} />
            </Video>
          ) : (
            <ResizedImage
              className={classes.mediaItem}
              src={token.imageUrl}
              fit="contain"
              aspectRatio="1"
              fitSize={IMAGE_FIT_SIZE}
              placeholder={imagePlaceholder}
              withPlaceholder={!!imagePlaceholder}
              onError={onImageError}
            />
          )}
        </InternalLink>
      )}
      <Flex
        mt={16}
        justify="space-between"
        align="center"
        columnGap={8}
        rowGap={4}
        wrap="nowrap"
      >
        <Stack spacing={6}>
          <InternalLink
            style={{ textDecoration: "none" }}
            to={getDetailPageUrl(collection, token.tokenId)}
            onClick={popModal}
          >
            <MultilineText size="sm" weight={WEIGHT_BOLD} numLines="2">
              {token.name}
            </MultilineText>
          </InternalLink>
        </Stack>
        <MarketplaceTokenButton token={token} onCompleted={onCompleted} />
      </Flex>
    </Box>
  );
};

const PlaceholderMetadataPreview = ({
  isCodeGenMint,
}: {
  isCodeGenMint: boolean;
}) => (
  <Center
    sx={(theme) => ({
      padding: 20,
      minHeight: 220,
      aspectRatio: "1",
      height: "auto",
      border: `0.5px solid ${theme.colors.divider[0]}`,
    })}
  >
    <Stack spacing={8} align="center">
      <Text size="sm" fw={WEIGHT_BOLD} align="center">
        {isCodeGenMint ? "Capturing metadata..." : "Processing metadata..."}
      </Text>
      <Text size="xs" color={TEXT_COLOR.SECONDARY} align="center">
        Metadata for this token will be available soon
      </Text>
    </Stack>
  </Center>
);

export default TokenCard;
