import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import BoxContainer from "@hl/base-components/lib/BoxContainer";
import SimpleIntersectionObserver from "@hl/base-components/lib/SimpleIntersectionObserver";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { TokenSortableField } from "@hl/shared-features/lib/apollo/graphql.generated";
import { NoResults } from "@hl/shared-features/lib/features/collections/NoResults";
import MarketplaceFilterSortBar from "@hl/shared-features/lib/features/marketplace/components/MarketplaceFilterSortBar";
import { useGetCollectorsChoiceTokensQuery } from "@hl/shared-features/lib/features/marketplace/queries/marketplace.graphql.generated";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import {
  sortingOptionsDefault,
  sortingOptionsWithMarketplace,
} from "@hl/shared-features/lib/utils/sortingOptions";
import {
  Box,
  Flex,
  Group,
  SimpleGrid,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useDebouncedValue, useMediaQuery } from "@mantine/hooks";
import { Property } from "csstype";
import InfiniteScroll from "react-infinite-scroll-component";

import { shloms404OnImageError } from "~features/MintPage/custom/Shloms404Carousel";
import { BrowseCollection } from "~features/marketplace/MarketplacePage";
import MarketplaceTokenListSkeleton from "~features/marketplace/skeleton/MarketplaceTokenListSkeleton";
import useMintState from "~hooks/useMintState";

import { MarketplaceCollectionAttributesFilter } from "../../apollo/graphql.generated";

import TokenCard from "./TokenCard";
import FilterSidebarSeries, {
  FilterSidebarProps,
} from "./filter/FilterSidebarSeries";

export enum MintStatus {
  All = "all",
  Minted = "minted",
  Available = "available",
}

type MarketplaceTokensProps = {
  collectionId: string;
  browseCollection: BrowseCollection;
  scrollBodyOnFilterChange?: boolean;
  containerConfig?: {
    top: Property.Top;
    zIndex: Property.ZIndex;
    borderBottom?: Property.BorderBottom;
    paddingTop?: Property.PaddingTop;
    marginBottom?: Property.MarginBottom;
  };
  enableMint?: boolean;
  filterScrollContainerConfig?: FilterSidebarProps["scrollContainerConfig"];
};

export const MarketplaceTokens = ({
  browseCollection,
  scrollBodyOnFilterChange,
  containerConfig,
  filterScrollContainerConfig,
  enableMint,
}: MarketplaceTokensProps) => {
  const { isCodeGenMint, isMarketplaceEnabledForCollectionChain, collection } =
    useMintState();

  const { bodyId } = useModalStack();

  const isMetadataHidden =
    browseCollection?.reveal &&
    browseCollection?.seriesDetails?.showAllMetadata === false;

  const mintedOnly = !enableMint || isMetadataHidden || isCodeGenMint;
  const defaultMintStatus = mintedOnly ? MintStatus.Minted : MintStatus.All;

  const [attributeFilters, setAttributeFilters] = useState<
    Record<string, string[]>
  >({});
  const [mintStatus, setMintStatus] = useState<string>(defaultMintStatus);
  const [showFilters, setShowFilters] = useState(false);
  const [searchString, setSearchString] = useState<string>("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 250);
  const sortingOptions = useMemo(
    () =>
      isMarketplaceEnabledForCollectionChain && !enableMint
        ? sortingOptionsWithMarketplace
        : sortingOptionsDefault,
    [isMarketplaceEnabledForCollectionChain]
  );
  const [sorting, setSorting] = useState<string | null>(
    sortingOptions[0].value
  );
  const selectedSort = useMemo(
    () =>
      sortingOptions.find((sortingOption) => sortingOption.value === sorting),
    [sorting]
  );

  useEffect(() => {
    setSorting(sortingOptions[0].value);
  }, [sortingOptions]);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
  const bodyRef = useRef(null);

  const handleClearFilters = useCallback(() => {
    setMintStatus(mintedOnly ? MintStatus.Minted : MintStatus.All);
    setAttributeFilters({});
    setShowFilters(false);
  }, [setAttributeFilters, setMintStatus, setShowFilters]);

  const attributesVar = useMemo(
    () =>
      Object.entries(attributeFilters).map(([k, v]) => ({
        name: k,
        values: v,
      })) as MarketplaceCollectionAttributesFilter[],
    [attributeFilters]
  );

  const { data, loading, fetchMore, refetch } =
    useGetCollectorsChoiceTokensQuery({
      variables: {
        after: null,
        first: 12,
        tokenNameOrId: debouncedSearchString,
        sortDirection: selectedSort!.sortDirection,
        minted: mintStatusToBool(mintStatus),
        attributes: attributesVar,
        sortBy: selectedSort?.sortBy as unknown as TokenSortableField,
        collectionId: collection?.id ?? "",
      },
      fetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
    });

  const tokensData = data?.getPublicCollection.collectorsChoiceTokens;
  const pageInfo = tokensData?.pageInfo;
  const nextCursor = pageInfo?.endCursor;

  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        after: nextCursor,
      },
    });
  }, [fetchMore, nextCursor]);

  const tokens = tokensData?.edges;

  useEffect(() => {
    const elem = scrollBodyOnFilterChange ? bodyRef.current : window;
    // Scroll to top when filters change
    elem?.scrollTo(0, 0);
  }, [attributesVar]);

  const mintStatusText =
    mintStatus === MintStatus.All || defaultMintStatus !== MintStatus.All
      ? ""
      : mintStatus.toLowerCase();

  const isFiltering =
    mintStatus !== defaultMintStatus || attributesVar.length !== 0;

  return (
    <Stack ref={bodyRef} spacing="xl">
      <MarketplaceFilterSortBar
        setSorting={setSorting}
        sorting={sorting}
        hasFilterToggle
        sortingOptions={sortingOptions}
        setSearchString={setSearchString}
        searchString={searchString}
        setShowFilters={setShowFilters}
        containerConfig={containerConfig}
        isCollectorsChoice={enableMint}
        attributesVar={attributesVar}
      />
      <Group spacing={40} noWrap align="flex-start">
        {/*TODO: Improve transitions*/}
        {showFilters && (
          <FilterSidebarSeries
            handleClearFilters={handleClearFilters}
            mintedOnly={mintedOnly}
            browseCollection={browseCollection}
            showFilters={showFilters}
            closeFilters={() => setShowFilters(false)}
            defaultMintStatus={defaultMintStatus}
            mintStatus={mintStatus}
            setMintStatus={setMintStatus}
            attributeFilters={attributeFilters}
            setAttributeFilters={setAttributeFilters}
            scrollContainerConfig={filterScrollContainerConfig}
          />
        )}
        <Stack w="100%">
          {pageInfo && isFiltering && !(isMobile && showFilters) && (
            <BoxContainer
              radius={10}
              p="12px 12px 12px 16px"
              mb={isMobile ? 20 : 40}
            >
              <Flex justify="space-between" align="center" gap={16}>
                <Text size="sm" color={TEXT_COLOR.SECONDARY}>
                  Showing{" "}
                  <Text component="span" size="sm" fw={WEIGHT_BOLD}>
                    {mintStatus === MintStatus.All && pageInfo.totalCount !== 0
                      ? "all "
                      : ""}
                    {pageInfo.totalCount} {mintStatusText} token
                    {pageInfo.totalCount !== 1 ? "s" : ""}
                  </Text>{" "}
                  {attributesVar.length !== 0 && <> with applied filters </>}
                </Text>

                {isFiltering && (
                  <UnstyledButton onClick={handleClearFilters}>
                    <Text weight={500} size={14}>
                      Clear filters
                    </Text>
                  </UnstyledButton>
                )}
              </Flex>
            </BoxContainer>
          )}
          {!tokens?.length ? (
            loading ? (
              <Loading />
            ) : (
              <NoResults
                description={
                  debouncedSearchString
                    ? "Try adjusting your filter settings"
                    : "Once you do, they'll appear here."
                }
                title={
                  debouncedSearchString
                    ? "No tokens found"
                    : "No tokens created yet"
                }
              />
            )
          ) : (
            <InfiniteScroll
              style={{
                display: isMobile && showFilters ? "none" : "block",
              }}
              scrollThreshold={1}
              dataLength={tokens?.length ?? 0}
              next={() => ({})}
              hasMore={!!nextCursor}
              loader={null}
              scrollableTarget={bodyId}
            >
              <SimpleGrid
                mb={isMobile ? 40 : 60}
                px={scrollBodyOnFilterChange && !isMobile ? 24 : 0}
                cols={4}
                spacing={40}
                verticalSpacing={56}
                breakpoints={[
                  { maxWidth: "xl", cols: showFilters ? 3 : 4 },
                  { maxWidth: "lg", cols: showFilters ? 2 : 3 },
                  { maxWidth: "md", cols: showFilters ? 1 : 2 },
                  { maxWidth: "sm", cols: 1, verticalSpacing: 40 },
                ]}
              >
                {tokens?.map((token) => (
                  <TokenCard
                    key={token.id}
                    token={token}
                    onCompleted={() => {
                      setTimeout(refetch, 1000);
                    }}
                    onImageError={
                      collection?.flagVariations.shloms404UI
                        ? shloms404OnImageError
                        : undefined
                    }
                  />
                ))}
              </SimpleGrid>

              {!loading && !!nextCursor && (
                <SimpleIntersectionObserver
                  rootMargin={0}
                  onIntersection={(e) => {
                    if (e.isIntersecting && pageInfo?.hasNextPage) {
                      loadMore();
                    }
                  }}
                >
                  <pre>
                    <Loading />
                  </pre>
                </SimpleIntersectionObserver>
              )}
            </InfiniteScroll>
          )}
        </Stack>
      </Group>
    </Stack>
  );
};

const Loading = () => (
  <Box
    sx={{
      flexGrow: 1,
      width: "100%",
    }}
  >
    <MarketplaceTokenListSkeleton />
  </Box>
);

export const mintStatusToBool = (status: MintStatus | string) => {
  if (status === MintStatus.All) {
    return null;
  }
  return status === MintStatus.Minted;
};
