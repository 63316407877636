import { _CollectionType } from "../apollo/graphql.generated";
export const createArweaveUrl = ({ collectionType, genSeriesMirroredBasedUri, isReveal, seriesMirroredBaseUri, onChainBaseUri, onChainImage, }) => {
    var _a;
    return collectionType === _CollectionType.GenerativeSeries
        ? genSeriesMirroredBasedUri
        : collectionType === _CollectionType.Series
            ? isReveal
                ? (_a = seriesMirroredBaseUri !== null && seriesMirroredBaseUri !== void 0 ? seriesMirroredBaseUri : onChainBaseUri) !== null && _a !== void 0 ? _a : ""
                : onChainBaseUri !== null && onChainBaseUri !== void 0 ? onChainBaseUri : ""
            : onChainImage;
};
